import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { productList as orgConfig } from 'org/configs/modules.config';
import { PRODUCT_LIST_GET_FAILURE, PRODUCT_LIST_GET_SUCCESS } from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      params: {
        sort_by: 'random',
        fields: [
          'id',
          'clickUrl',
          'images',
          'name',
          'merchantName',
          'price',
          'merchantRebates',
          'showRebate',
          'offers',
          'description',
          'message',
          'rebate',
          'hasTiered',
          'merchantCount',
          'salePrice',
        ].join(','),
      },
      options: {
        success: PRODUCT_LIST_GET_SUCCESS,
        failure: PRODUCT_LIST_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    carousel: {
      align: 'center',
      isClipped: true,
      hasDots: false,
      loop: true,
      breakpoints: {
        m: {
          isClipped: false,
        },
      },
    },
    rebateOptions: {
      showRebateInline: true,
      hideRebatePrefix: true,
      hideElevationCurrency: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
