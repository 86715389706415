import dayjs from 'core/utils/dayjs';

import { selectPreviewDateTime } from 'core/selectors/env';

// Return current datetime based on customDate and take into account the preview datetime if set.
// If no preview date is set then return the valid customDate or the default datetime.
// The default datetime is not based on the store's currentDate which is hardcoded to the page source on load.
// It is based on a current JS Date including seconds in order to reflect the precise time (used in a countdown clocks).
export const selectCountdownStartDateTime = (state, customDate) => {
  const previewDateTime = selectPreviewDateTime(state);
  const isPreviewDateValid = previewDateTime && previewDateTime !== 'Invalid Date';
  if (isPreviewDateValid) {
    return previewDateTime;
  }
  return customDate && dayjs(customDate).isValid() ? dayjs(customDate).format() : new Date();
};
