import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectSiteUrl } from 'core/selectors/app';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';
import MerchantClickUrlLink from 'core/modules/MerchantClickUrlLink/MerchantClickUrlLink';
import InstoreMerchantRebate from 'core/modules/InstoreMerchantRebate/InstoreMerchantRebate';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

import './HybridStoresOffer.scss';

function HybridStoresOffer({ merchant, config, inStoreOffers }) {
  const isMobileMQ = useSelector(selectIsMobileMQ);
  const siteUrl = useSelector(selectSiteUrl);

  const {
    name, rebate, instoreMerchant, logoUrls,
  } = merchant;

  const { rebateOptions } = config;
  const { offers } = instoreMerchant;

  const getInStoreOffer = () => {
    const filteredOffers = inStoreOffers.filter((offer) => offer.offerId === offers[0].offerId);
    return filteredOffers.length > 0 ? filteredOffers[0] : {};
  };

  const inStoreOffer = getInStoreOffer();
  const isLinkedCards = inStoreOffer?.linked;

  // CanJs and React use different url params to show the overlay
  const redirectUrl = isMobileMQ
    ? `instore#modalType=mt_instoreOffer&offerId=${inStoreOffer.offerId}`
    : `instore#lightboxType=instoreLinkCard&checkForFirstTimeEnrollCard=false&offerId=${inStoreOffer.offerId}`;

  const renderLinkedButton = () => <button className="mn_button mn_linked" aria-disabled="true">Offer linked</button>;

  const renderLinkOfferInfo = () =>
    <a href={`${siteUrl}/${redirectUrl}`} className="mn_button" target="_blank">Link offer</a>;

  const renderLoggedInOfferButton = () =>
    (isLinkedCards ? renderLinkedButton() : renderLinkOfferInfo());

  return (
    <div className="mn_hybridStoresOfferOverlayContent">
      <div className="mn_topSection">
        <h2 id="mn_dialogTitle">Shop online or in-store.</h2>
        <img src={logoUrls._120x60} alt={name} />
      </div>
      <hr />
      <div className="mn_bottomSection" id="mn_dialogDescription">
        <div className="mn_onlineOffer">
          <div className="mn_offerDetails">
            Online offer:&nbsp;
            <PremierMerchantRebate {...rebate} {...rebateOptions} />
          </div>
          <MerchantClickUrlLink merchant={merchant}>
            <button className="mn_button">Shop now</button>
          </MerchantClickUrlLink>
        </div>
        {isMobileMQ && <hr />}
        <div className="mn_instoreOffer">
          <div className="mn_offerDetails">
            In-store offer:&nbsp;
            <div className="mn_inStoreRebate">
              <InstoreMerchantRebate offer={instoreMerchant.offers[0]} /> in-store
            </div>
          </div>
          {renderLoggedInOfferButton()}
        </div>
      </div>
      {isMobileMQ && <hr />}
    </div>
  );
}

HybridStoresOffer.propTypes = {
  isUserIdentified: PropTypes.bool.isRequired,
  merchant: PropTypes.shape({}).isRequired,
};

export default (props) => <ComponentErrorBoundary><HybridStoresOffer {...props} /></ComponentErrorBoundary>;
