import {
  BONUS_ROLLING_MODAL_VIEW, BONUS_ROLLING_MODAL_ON_OVERLAY_CLICK_CLOSE,
} from './types';

export const triggerBonusRollingModalIsViewed = bonusItem => ({
  type: BONUS_ROLLING_MODAL_VIEW,
  bonusItem,
});

export const triggerOnOverlayClickCloseGAEvent = () => ({
  type: BONUS_ROLLING_MODAL_ON_OVERLAY_CLICK_CLOSE,
});
