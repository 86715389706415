import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { recentStores as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    api: {
      options: {
        sectionId: getId('recommended'),
      },
    },
    viewData: {
      sectionTitle: 'Recent stores',
      footerCTATitle: 'View all',
      footerCTAUrl: '/b____.htm',
    },
    tagHelper: () => {},
    rebateOptions: {},
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
