import React, { useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';

import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import SearchForm from 'core/modules/SearchForm/SearchForm';
import HybridSearchForm from 'core/modules/HybridSearchForm/HybridSearchForm';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';
import Modal from 'core/modules/Modal/Modal';

import config from './configs';

import './HybridSearchModal.scss';
import './SearchModal.scss';

function SearchModal() {
  const searchModalObserver = useRef();
  const searchModalElement = useRef();

  const isMobileMQ = useSelector(selectIsMobileMQ);

  const { placeholderLanguage, shouldUseHybridSearch } = config;

  const updateSearchModalHeight = (event) => {
    searchModalElement.current.style.top = `${event.target.offsetTop}px`;
    searchModalElement.current.style.height = `${event.target.height}px`;
  };

  // Calculate search modal height considering mobile keyboard size
  useEffect(() => {
    window.visualViewport.addEventListener('resize', updateSearchModalHeight);
    window.visualViewport.addEventListener('scroll', updateSearchModalHeight);

    return () => {
      window.visualViewport.removeEventListener('resize', updateSearchModalHeight);
      window.visualViewport.removeEventListener('scroll', updateSearchModalHeight);
      searchModalObserver.current && searchModalObserver.current.disconnect();
    };
  }, []);

  // Pin scrolling to bottom, when quick search suggestion is updated
  const searchFormRef = useCallback(node => {
    if (node === null) {
      searchModalObserver.current && searchModalObserver.current.disconnect();
      return;
    }

    searchModalObserver.current = new ResizeObserver(() => {
      searchModalElement.current.scrollTop = searchModalElement.current.scrollHeight;
    });

    searchModalObserver.current.observe(node);
  }, []);

  const searchModalRef = useCallback(node => {
    searchModalElement.current = node;
    if (node) {
      node.style.top = `${window.visualViewport.offsetTop}px`;
      node.style.height = `${window.visualViewport.height}px`;
    }
  }, []);

  if (!isMobileMQ) {
    return null;
  }

  return (
    <Modal
      className={shouldUseHybridSearch ? 'mn_hybridSearchModal' : 'mn_searchModal'}
      modalRef={searchModalRef}
    >
      <div id="mn_dialogTitle" className="mn_sr-only">Dialog with search form</div>
      {shouldUseHybridSearch ?
        <HybridSearchForm
          isPopUpView
          hasFocusOnInit
          placeholder={placeholderLanguage}
          searchFormRef={searchFormRef}
        /> :
        <SearchForm
          isPopUpView
          hasFocusOnInit
          placeholder={placeholderLanguage}
          searchFormRef={searchFormRef}
        />
      }
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><SearchModal {...props} /></ComponentErrorBoundary>;
