import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { extraRewardsDeals as orgConfig } from 'org/configs/modules.config';
import {
  EXTRA_REWARDS_DEALS_GET_SUCCESS,
  EXTRA_REWARDS_DEALS_GET_FAILURE,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: EXTRA_REWARDS_DEALS_GET_SUCCESS,
        failure: EXTRA_REWARDS_DEALS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: getId('featured-extra-rewards-deals'),
      },
      params: {
        sort_by: 'random',
        fields: [
          'id',
          'stopDatetime',
          'couponCode',
          'merchant.id',
          'merchant.name',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.logoUrls',
          'assets.rolloverCopy',
        ],
        content_group_id: 5936,
      },
    },
    rebateOptions: {
      hideElevationCurrency: true,
      additionalRebatePrefix: 'Shop and ',
      tiered: {
        hideElevationCurrency: true,
        additionalRebatePrefix: 'Shop and earn ',
      },
    },
    carousel: {
      hasDots: false,
      hasArrows: true,
    },
    secTitle: 'Featured extra miles deals',
    category: 'Premiere Elevations',
    type: 'Offer',
    filterFavorited: false,
    couponCodeLabel: 'Use code:',
    maxOffersDisplayOnMobileRowsView: 5,
  },
  favorites: {
    filterFavorited: true,
    useFeaturedExtraRewardsFavoritesEnabledFlag: true,
  },
  foryou: {
    filterFavorited: true,
  },
  h: {
    useFeaturedExtraRewardsEnabledFlag: true,
  },
  es: {
    useFeaturedExtraRewardsEnabledFlag: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
