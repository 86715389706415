import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { fetchPlacements } from 'core/actions/ocapi';
import keyCodes from 'core/utils/helpers/keyCodes';
import { useModal } from 'core/modules/Modal/ModalProvider';

import { selectMerchantOfferModalConfig, selectAssociatedDealOfferIds } from './selectors';

import './OfferOverlayLink.scss';

function OfferOverlayLink({
  merchantId, dealId, sectionId, offerId, preventOverlayShowing,
  merchantName, children, className, shouldTrackInitialParams,
}) {
  const dispatch = useDispatch();
  const offerIdRef = useRef();
  const isLoading = useRef();

  const { openModal } = useModal();

  const config = useSelector(selectMerchantOfferModalConfig);
  const associatedDealOfferIds = useSelector(selectAssociatedDealOfferIds);

  useEffect(() => {
    if (!offerIdRef.current && !isEmpty(associatedDealOfferIds) && associatedDealOfferIds[dealId]) {
      offerIdRef.current = associatedDealOfferIds[dealId];
      openModal({
        name: 'mt_merchantOffer',
        params: {
          merchantId,
          offerId: offerIdRef.current,
          sectionId,
          track: shouldTrackInitialParams ? 1 : 0,
        },
      });
    }
  });

  const handleOfferClick = () => {
    if (preventOverlayShowing) {
      return;
    }

    if (offerId !== undefined || offerIdRef.current !== undefined) {
      openModal({
        name: 'mt_merchantOffer',
        params: {
          merchantId,
          offerId: offerId === undefined ? offerIdRef.current : offerId,
          sectionId,
          track: shouldTrackInitialParams ? 1 : 0,
        },
      });
    } else if (dealId !== undefined && !isLoading.current) {
      isLoading.current = true;
      const { api: apiConfig } = config;
      const { params, options } = apiConfig;
      params.deal_id = dealId;
      dispatch(fetchPlacements(params, options));
    }
  };

  const handleKeyPress = (event) => {
    const pressedKey = event.keyCode;
    if ((pressedKey === keyCodes.ENTER) || (pressedKey === keyCodes.SPACEBAR)) {
      event.target.click();
    }
  };

  const overlayClassName = `mn_offerOverlayLink ${className || ''}`;

  return (
    <div
      className={overlayClassName}
      data-merchant-name={merchantName}
      data-merchant-id={merchantId}
      data-offer-id={offerId}
      role="button"
      tabIndex="0"
      onClick={handleOfferClick}
      onKeyDown={handleKeyPress}
      data-testid="offer-overlay-link"
      title={`Opens a dialog with offers and details for ${merchantName}`}
    >
      {children}
    </div>
  );
}

export default OfferOverlayLink;
