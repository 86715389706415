import {
  MEMBER_GET_FAVORITES_SUCCESS, MEMBER_POST_FAVORITES, MEMBER_POST_FAVORITES_SUCCESS,
  MEMBER_POST_FAVORITES_FAILURE, MEMBER_DELETE_FAVORITES, MEMBER_DELETE_FAVORITES_FAILURE,
} from 'core/actions/member';

const initialState = {
  isProcessed: false,
  hasFavorited: false,
  isFirstFavorite: false,
  merchantIndex: {}, // overall state of favorited merchants
  addFavoritesOrderQueue: [], // dynamically added favorites after page load with keeping order
  allFavoritesOrderQueue: [], // initiall favorites + dynamically added after page load with keeping order
};

/**
 * The 'first favorite' popup experience is triggered after the MEMBER_POST_FAVORITES_SUCCESS action fires
 * for the first time. It triggers only after the favoriting API call responce is returned.
 * At this moment we should decide if the selected merchant was the first favorited.
 * If user already had favorited merchants (the 'hasFavorited' was 'true' based on the initial 'favorites/' API call),
 * then the 'first favorite' experience is ignored.
 * Othewise the 'isFirstFavorite' flag is set to 'true' for the first manually favorited merchant
 * and then it will be set to 'false' after the second MEMBER_POST_FAVORITES_SUCCESS action
 * which turns off the 'first favorite' popup experience for all subsequent favoriting API calls.
 */
const saveFirstFavoriteState = (state, action) => {
  const { hasFavorited } = state;
  const forceSkipFirstFavoritesPopup = action.context?.data?.forceSkipFirstFavoritesPopup;

  return {
    ...state,
    hasFavorited: true,
    isFirstFavorite: !hasFavorited,
    forceSkipFirstFavoritesPopup,
  };
};

const addFavoriteToState = (state, action) => {
  const { merchantIndex, addFavoritesOrderQueue, allFavoritesOrderQueue } = state;
  const { merchantId } = action.data || action.context.data;

  if (!merchantId || merchantIndex[merchantId]) {
    return state;
  }

  return {
    ...state,
    merchantIndex: {
      ...merchantIndex,
      [merchantId]: true,
    },
    addFavoritesOrderQueue: [...addFavoritesOrderQueue, merchantId],
    allFavoritesOrderQueue: [...allFavoritesOrderQueue, merchantId],
  };
};

const deleteFavoriteFromState = (state, action) => {
  const { merchantIndex, addFavoritesOrderQueue, allFavoritesOrderQueue } = state;
  const { merchantId } = action.data || action.context.data;

  if (!merchantId || !merchantIndex[merchantId]) {
    return state;
  }

  return {
    ...state,
    merchantIndex: {
      ...merchantIndex,
      [merchantId]: false,
    },
    addFavoritesOrderQueue: addFavoritesOrderQueue.filter(id => id !== merchantId),
    allFavoritesOrderQueue: allFavoritesOrderQueue.filter(id => id !== merchantId),
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBER_GET_FAVORITES_SUCCESS: {
      const { payload: { response = {} } } = action;
      const { hasFavorited = false, favorites: merchantIndex = {} } = response;

      return {
        ...state,
        isProcessed: true,
        hasFavorited,
        merchantIndex,
        allFavoritesOrderQueue: Object.keys(merchantIndex).filter(key => merchantIndex[key]).map(id => Number(id)),
      };
    }

    case MEMBER_POST_FAVORITES_SUCCESS:
      return saveFirstFavoriteState(state, action);

    case MEMBER_POST_FAVORITES:
    case MEMBER_DELETE_FAVORITES_FAILURE:
      return addFavoriteToState(state, action);

    case MEMBER_DELETE_FAVORITES:
    case MEMBER_POST_FAVORITES_FAILURE:
      return deleteFavoriteFromState(state, action);

    default:
      return state;
  }
};
