import {
  HYBRID_STORES_OFFER_MODAL_CLEAR, HYBRID_STORES_OFFER_MODAL_VIEW,
} from './types';

export const clearHybridStoresOfferData = () => ({
  type: HYBRID_STORES_OFFER_MODAL_CLEAR,
});

export const triggerHybridStoresOfferOverlayIsViewed = (merchantName) => ({
  type: HYBRID_STORES_OFFER_MODAL_VIEW,
  merchantName,
});
