import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPlacements } from 'core/actions/ocapi';

import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { selectStoreCount, selectToolbarConfig } from 'core/selectors/app';
import { hideModal } from 'core/modules/OldModal/actions';

import { triggerButtonScrollingModalIsViewed } from './actions';
import {
  selectButtonScrollingModalConfig, selectButtonScrollingModalLogosIsLoading,
  selectButtonScrollingModalLogosIsLoaded, selectButtonScrollingModalLogosItems,
} from './selectors';

import config from './configs';
import './ButtonScrollingModal.scss';

const { components: { ButtonScrollingModalContent } } = config;

function ButtonScrollingModal() {
  const dispatch = useDispatch();
  const toolbarConfig = useSelector(selectToolbarConfig);
  const storeCount = useSelector(selectStoreCount);
  const config = useSelector(selectButtonScrollingModalConfig);
  const isLogosLoading = useSelector(selectButtonScrollingModalLogosIsLoading);
  const isLogosLoaded = useSelector(selectButtonScrollingModalLogosIsLoaded);
  const logos = useSelector(selectButtonScrollingModalLogosItems);
  const [lightBoxContentElement, setLightBoxContentElement] = useState(null);
  const [buttonContentElement, setbuttonContentElement] = useState(null);
  const lightBoxContentRef = useCallback((ref) => { setLightBoxContentElement(ref); }, []);
  const buttonContentRef = useCallback((ref) => { setbuttonContentElement(ref); }, []);

  const { chromeDownloadUrl } = toolbarConfig || {};

  useEffect(() => {
    if (!lightBoxContentElement && !buttonContentElement) { return () => {}; }

    const options = { root: lightBoxContentElement, threshold: 1 };
    const observer = new IntersectionObserver(observerCallback, options);
    const target = lightBoxContentElement.querySelectorAll('.mn_buttonBlock .mn_button');

    [...target].forEach((item) => { observer.observe(item); });

    return () => observer.disconnect();

    // Trigger GA event only once per button, when it is vissible in the overlay
    function observerCallback(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const buttonNumber = entry.target.dataset.number;
          const buttonText = entry.target.textContent;
          dispatch(triggerButtonScrollingModalIsViewed(buttonNumber, buttonText));
          observer.unobserve(entry.target);
        }
      });
    }
  }, [lightBoxContentElement, buttonContentElement]);

  useEffect(() => {
    if (!isLogosLoading && !isLogosLoaded) {
      const { logos: logosConfig } = config;
      const { api: { params, options } } = logosConfig;
      dispatch(fetchPlacements(params, options));
    }
  }, []);

  const hideSearchModal = () => {
    dispatch(hideModal());
  };

  const redirectToChromeStore = () => {
    window.open(chromeDownloadUrl, '_blank');
    hideSearchModal();
  };

  const renderLogos = (logos) => (
    logos.map(({ assets, merchant, id }) => (
      <div className="mn_merchantLogo" key={id}>
        <div className="mn_logoWrapper">
          <img src={assets._460x250} alt={merchant.name} />
        </div>
      </div>
    ))
  );

  const renderedLogos = renderLogos(logos);

  return (
    <Modal className="mn_buttonScrollingModal">
      <div className="mn_lightBoxContent" ref={lightBoxContentRef}>
        <ButtonScrollingModalContent
          redirectToChromeStore={redirectToChromeStore}
          renderedLogos={renderedLogos}
          storeCount={storeCount}
          buttonContentRef={buttonContentRef}
        />
      </div>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><ButtonScrollingModal {...props} /></ComponentErrorBoundary>;
