import { createSelector } from 'reselect';

export const selectButtonScrollingModal = state => state.buttonScrollingModal;

export const selectButtonScrollingModalConfig =
  createSelector(selectButtonScrollingModal, buttonScrollingModal => buttonScrollingModal.config);

export const selectButtonScrollingModalLogos =
  createSelector(selectButtonScrollingModal, buttonScrollingModal => buttonScrollingModal.buttonScrollingModalLogos);
export const selectButtonScrollingModalLogosIsLoading =
  createSelector(selectButtonScrollingModalLogos, buttonScrollingModalLogos => buttonScrollingModalLogos.isLoading);
export const selectButtonScrollingModalLogosIsLoaded =
  createSelector(selectButtonScrollingModalLogos, buttonScrollingModalLogos => buttonScrollingModalLogos.isLoaded);
export const selectButtonScrollingModalLogosItems =
  createSelector(selectButtonScrollingModalLogos, buttonScrollingModalLogos => buttonScrollingModalLogos.logos);
