import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { LETTERBOX_TOF_VIEWED } from '../actions/types';

export const letterboxTOFGaActionEvents = {
  [LETTERBOX_TOF_VIEWED]: ({ placement }) => {
    const {
      id, clickUrl, assets = {}, merchant, isInfoBarMarketing,
    } = placement || {};

    const arrangeInfoBarMarketingGAData = ({ assets = {}, clickUrl }) => {
      const { shortSummaryText, rolloverCopy, callToActionUrl } = assets;
      const hasCountdown = !!assets.countdownClockPrefixText;

      return ({
        category: `Marketing Elevator Homepage Banner${hasCountdown ? ' - With Countdown Clock' : ''}`,
        action: 'View',
        label: `${shortSummaryText} ${rolloverCopy} | ${callToActionUrl || clickUrl}`,
      });
    };

    if (isInfoBarMarketing) {
      return arrangeInfoBarMarketingGAData(placement);
    }

    return {
      category: `${merchant ? 'Merchant' : 'Marketing'} Letterbox`,
      action: 'View',
      label: merchant
        ? `${assets.rolloverCopy}|${merchant.name}|${merchant.id}`
        : `${assets.imageAlternateText}|${id}|${clickUrl}`,
    };
  },
};

export const letterboxTOFClickAndHoverEvents = [
  {
    selector: '.mn_letterboxTOF .mn_merchantLetterboxTile[data-has-toggle-menu="true"] .mn_button',
    click: {
      category: 'Merchant Letterbox',
      action: 'Click',
    },
    label: (element) => {
      const letterboxTile = findAncestorByClassName(element, 'mn_merchantLetterboxTile');
      const rolloverCopyEl = letterboxTile.getElementsByClassName('mn_rolloverCopy');
      const rolloverCopyText = rolloverCopyEl.length > 0 ? rolloverCopyEl[0].innerText || '' : '';
      const { merchantName, merchantId } = letterboxTile.dataset;

      return `${rolloverCopyText} | ${merchantName} | ${merchantId}`;
    },
  },
  {
    selector: '.mn_letterboxTOF .mn_merchantLetterboxTile[data-has-toggle-menu="false"]',
    click: {
      category: 'Merchant Letterbox',
      action: 'Click',
    },
    label: (element) => {
      const rolloverCopyEl = element.getElementsByClassName('mn_rolloverCopy');
      const rolloverCopyText = rolloverCopyEl.length > 0 ? rolloverCopyEl[0].innerText || '' : '';
      const { merchantName, merchantId } = element.dataset;

      return `${rolloverCopyText} | ${merchantName} | ${merchantId}`;
    },
  },
  {
    selector: '.mn_letterboxTOF .mn_marketingLetterboxTile',
    click: {
      category: 'Marketing Letterbox',
      action: 'Click',
    },
    label: (element) => {
      const imgEl = element.getElementsByTagName('img');
      const imgAltText = imgEl.length > 0 ? imgEl[0].alt || '' : '';
      const { placementId } = element.dataset;
      const clickUrl = element.href || '';

      return `${imgAltText} | ${placementId} | ${clickUrl}`;
    },
  },
  {
    selector: '.mn_letterboxTOF .mn_infoBarMarketingLetterboxTile',
    click: {
      category: element => (
        `Marketing Elevator Homepage Banner${element.dataset.hasCountdown === 'true' ? ' - With Countdown Clock' : ''}`
      ),
      action: 'Click',
    },
    label: (element) => {
      const shortSummaryEl = element.getElementsByClassName('mn_shortSummaryText');
      const shortSummaryText = shortSummaryEl.length > 0 ? shortSummaryEl[0].innerText || '' : '';

      const rolloverCopyEl = element.getElementsByClassName('mn_rolloverCopy');
      const rolloverCopyText = rolloverCopyEl.length > 0 ? rolloverCopyEl[0].innerText || '' : '';

      const clickUrl = element.href || '';

      return `${shortSummaryText} ${rolloverCopyText} | ${clickUrl}`;
    },
  },
];
