import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { sponsoredOffers as orgConfig } from 'org/configs/modules.config';
import { SPONSORED_OFFERS_GET_SUCCESS, SPONSORED_OFFERS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    stopDateTimeFormat: 'M/DD/YY',
    shouldUseMobileRoundedView: true,
    api: {
      options: {
        success: SPONSORED_OFFERS_GET_SUCCESS,
        failure: SPONSORED_OFFERS_GET_FAILURE,
        sectionId: 10426,
      },
      params: {
        sort_by: 'random',
        group_by_merchant: 0,
        fields: [
          'assets',
          'clickUrl',
          'code',
          'couponCode',
          'stopDatetime',
          'id',
          'disclaimer',
          'merchant',
        ],
      },
    },
  },
  sweeps: {
    shouldUseMobileRoundedView: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
