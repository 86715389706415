import { TOP_STORES_VIEW } from '../actions/types';

export const topStoresGaActionEvents = {
  [TOP_STORES_VIEW]: ({ merchants }) => {
    const merchantNames = merchants.map(merchant => merchant.name);

    return {
      category: 'Top Stores',
      action: 'View',
      label: merchantNames.join(', '),
      value: merchants.length,
    };
  },
};

export const topStoresClickAndHoverEvents = [
  {
    selector: '.mn_topStores .mn_merchantTile, .mn_topStoresExpandable .mn_merchantTile',
    click: {
      category: 'Top stores',
      action: 'Click ',
    },
    label: (element) => {
      const { merchantName, merchantId } = element.dataset;

      return `${merchantId} | ${merchantName}`;
    },
  },
  {
    selector: '.mn_topStoresExpandable .mn_seeMoreButton',
    click: {
      category: 'Mobile MHP: Top Stores',
      action: 'Top Stores See More Click',
    },
    label: '',
    value: 1,
  },
  {
    selector: '.mn_topStoresExpandable .mn_seeAllButton',
    click: {
      category: 'Mobile MHP: Top Stores',
      action: 'Top Stores See All Click',
    },
    label: '',
    value: 1,
  },
];
