import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { topEarn as orgConfig } from 'org/configs/modules.config';
import { TOP_EARN_GET_SUCCESS, TOP_EARN_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    maxMerchants: 8,
    api: {
      params: {
        sort_by: 'random',
      },
      options: {
        success: TOP_EARN_GET_SUCCESS,
        failure: TOP_EARN_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: 10442,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
