export const reduceMatchesBasedOnDntMobile = (matches) => {
  if (matches.length <= 3) {
    return matches;
  }

  const nonTrackingMerchnatsCount =
    matches.filter(({ merchant }) => !!merchant.flags && !merchant.flags.tracksMobile).length;

  let countToRemove = 0;
  if (nonTrackingMerchnatsCount >= 2) {
    countToRemove = 2;
  } else if (nonTrackingMerchnatsCount > 0) {
    countToRemove = 1;
  }

  return matches.slice(0, matches.length - countToRemove);
};
