import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getNameById } from 'core/utils/analytics/sectionInfo';
import { MERCHANT_OFFER_VIEW } from '../actions/types';

export const merchantOfferModalGaActionEvents = {
  [MERCHANT_OFFER_VIEW]: (action) => {
    const { merchantName, userIsIdentified, pageName } = action;
    const getPageName = () => {
      let displayedPageName = '';
      if (pageName === 'h') {
        displayedPageName = userIsIdentified ? 'mhp_mobile' : 'vhp_mobile';
      } else {
        displayedPageName = 'offeroverlay';
      }

      return displayedPageName;
    };

    return {
      type: 'pageView',
      custom: {
        page: getPageName(),
        title: 'offeroverlay',
        merchant: merchantName,
        page_name: getPageName(), // eslint-disable-line camelcase
      },
    };
  },
};

export const merchantOfferModalClickAndHoverEvents = [
  {
    selector: '.mn_merchantOfferModal .mn_olayShopNow',
    click: {
      category: 'Offer Overlay',
      action: 'Shop now click',
    },
    label: (element) => {
      const { sectionId, merchantName } = element.dataset;
      const sectionName = getNameById(sectionId);
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shortSummaryText = merchantOffer.getElementsByClassName('mn_offerTitle')[0].innerText;

      return `${sectionName}|${merchantName}|${shortSummaryText}`;
    },
    custom: element => ({
      merchant: element.dataset.merchantName,
    }),
  },
  {
    selector: '.mn_merchantOfferModal .mn_olayAllOffers > a',
    click: {
      category: 'Offer Overlay',
      action: 'All offers click',
    },
    label: (element) => {
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shortSummaryText = merchantOffer.getElementsByClassName('mn_offerTitle')[0].innerText || '';
      const shopNowCTA = merchantOffer.getElementsByClassName('mn_olayShopNow')[0] || {};
      const { sectionId, merchantName } = shopNowCTA.dataset;
      const sectionName = getNameById(sectionId);

      return `${sectionName}|${merchantName}|${shortSummaryText}`;
    },
    custom: (element) => {
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shopNowCTA = merchantOffer.getElementsByClassName('mn_olayShopNow')[0] || {};
      const { merchantName } = shopNowCTA.dataset;

      return {
        merchant: merchantName,
      };
    },
  },
  {
    selector: '.mn_merchantOfferModal .mn_copyCouponCode',
    click: {
      category: 'Offer Overlay',
      action: 'Coupon copy click',
    },
    label: (element) => {
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shortSummaryText = merchantOffer.getElementsByClassName('mn_offerTitle')[0].innerText || '';
      const shopNowCTA = merchantOffer.getElementsByClassName('mn_olayShopNow')[0] || {};
      const { sectionId, merchantName } = shopNowCTA.dataset;
      const sectionName = getNameById(sectionId);

      return `${sectionName}|${merchantName}|${shortSummaryText}`;
    },
  },
  {
    selector: '.mn_merchantOfferModal .mn_olayMerchantTerms',
    click: {
      category: 'Offer Overlay',
      action: 'Terms and conditions click',
    },
    label: (element) => {
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shortSummaryText = merchantOffer.getElementsByClassName('mn_offerTitle')[0].innerText || '';
      const shopNowCTA = merchantOffer.getElementsByClassName('mn_olayShopNow')[0] || {};
      const { sectionId, merchantName } = shopNowCTA.dataset;
      const sectionName = getNameById(sectionId);

      return `${sectionName}|${merchantName}|${shortSummaryText}`;
    },
    custom: (element) => {
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shopNowCTA = merchantOffer.getElementsByClassName('mn_olayShopNow')[0] || {};
      const { merchantName } = shopNowCTA.dataset;

      return {
        merchant: merchantName,
      };
    },
  },
  {
    selector: '.mn_merchantOfferModal .mn_closeModal',
    click: {
      category: 'Offer Overlay',
      action: 'Dismiss click',
    },
    label: (element) => {
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shortSummaryText = merchantOffer.getElementsByClassName('mn_offerTitle')[0].innerText || '';
      const shopNowCTA = merchantOffer.getElementsByClassName('mn_olayShopNow')[0] || {};
      const { sectionId, merchantName } = shopNowCTA.dataset;
      const sectionName = getNameById(sectionId);

      return `${sectionName}|${merchantName}|${shortSummaryText}`;
    },
    custom: (element) => {
      const merchantOffer = findAncestorByClassName(element, 'mn_merchantOfferModal');
      const shopNowCTA = merchantOffer.getElementsByClassName('mn_olayShopNow')[0] || {};
      const { merchantName } = shopNowCTA.dataset;

      return {
        merchant: merchantName,
      };
    },
  },
];
