import React, { useEffect, useState } from 'react';

import './WistiaVideo.scss';

export default function WistiaVideo({
  videoId, autoPlay, handleVideoOnLoad, handleClose,
}) {
  const [videoImgLoaded, setVideoImgLoaded] = useState(false);
  const footageSrc = `https://fast.wistia.com/embed/medias/${videoId}/swatch?wvideo=hashedid`;

  const loadScript = (url, id) => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.id = id;

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!document.getElementById(`mn_wistiaVideo${videoId}`)) {
      loadScript(`https://fast.wistia.com/embed/medias/${videoId}.jsonp`, `mn_wistiaVideo${videoId}`);
    }
    if (!document.getElementById('mn_wistiaVideoScript')) {
      loadScript('https://fast.wistia.com/assets/external/E-v1.js', 'mn_wistiaVideoScript');
    }

    setVideoImgLoaded(true);
  }, [videoId]);

  return (
    <div className="mn_youtubeVideo">
      <div className="mn_youtubeVideoContent">
        { handleClose &&
          <button className="mn_exitYoutubeContent" aria-label="Close dialog" onClick={handleClose} />
        }
        <div className="mn_wistiaContent">
          <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
              <div className={`wistia_embed wistia_async_${videoId} videoFoam=true ${autoPlay}`}>
                <div className={`wistia_swatch ${videoImgLoaded ? 'wistia_swatch_opacity' : ''}`}>
                  <img
                    src={footageSrc}
                    alt=""
                    onLoad={handleVideoOnLoad}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
