import React from 'react';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { firstFavoriteModal as orgConfig } from 'org/configs/modules.config';

export const defaultConfig = {
  defaults: {
    titleText: 'You\'ve picked a favorite store',
    getBodyText: (siteUrl) => (
      <>
        Keep picking your favorites and we'll email you right away when they offer extra miles.
        To manage your alert preferences, please visit
        {' '}
        <a className="mn_myAccountLink" href={`${siteUrl}/ai____.htm`}>My account&nbsp;</a><span>.</span>
      </>
    ),
    buttonText: 'Ok',
    modalOptions: {
      shouldCloseOnClickOutside: false,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
