import { createSelector } from 'reselect';
import { selectFormSecurityToken } from 'core/selectors/app';

const selectEmailOptInSubscribeModal = state => state.emailOptInSubscribeModal;

export const selectEmailOptInSubscribeModalConfig = ({ emailOptInSubscribeModal, ...state }) => ({
  ...emailOptInSubscribeModal.config,
  securityToken: selectFormSecurityToken(state),
});

export const selectEmailOptInSubscribeModalUserChoice = createSelector(
  selectEmailOptInSubscribeModal,
  emailOptInSubscribeModal => emailOptInSubscribeModal.userChoice,
);

export const selectEmailOptInSubscribeModalUserChoiceSubmitted = createSelector(
  selectEmailOptInSubscribeModal,
  emailOptInSubscribeModal => emailOptInSubscribeModal.userChoiceSubmitted,
);
