import { getParamsFromString } from './getParamsFromString';

export const getParamsFromHash = (hash = window.location.hash) => {
  let hashString = hash;

  if (hash.indexOf('?') !== -1) {
    hashString = hash.substr(0, hash.indexOf('?'));
  }

  const result = (hashString.charAt(0) === '#') ? hashString.substr(1) : hash;

  return getParamsFromString(result);
};
