import React from 'react';
import { connect } from 'react-redux';
import { selectUserIsIdentified } from 'core/selectors/user';
import { isClickLink } from 'core/utils/helpers/url';

function ClickLink({
  url,
  children,
  isUserIdentified,
  dispatch, // exclude from auto-passed props
  ...props
}) {
  const isForcedLogin = isClickLink(url) && !isUserIdentified;

  return (
    <a href={url} data-is-forced-login={isForcedLogin} {...props} data-test="click-link">{children}</a>
  );
}

const mapStateToProps = state => ({
  isUserIdentified: selectUserIsIdentified(state),
});

export default connect(mapStateToProps)(ClickLink);
