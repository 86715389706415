import {
  CURRENT_BONUS_OFFERS_VIEW,
  PENDING_BONUS_OFFERS_VIEW,
} from 'core/modules/ForYou/actions/types';

export const forYouOffersTabActionEvents = {
  [CURRENT_BONUS_OFFERS_VIEW]: (validCurrentOffers) => {
    const { data } = validCurrentOffers;
    let info = {
      ids: [],
      names: [],
    };
    data && data.forEach(offer => {
      const { offers, id } = offer;
      const { qualifyingMerchants: { merchants } } = offers[0];
      const { name } = merchants[0];
      info = {
        ids: [...info.ids, id],
        names: [...info.names, name],
      };
    });
    return ({
      category: 'Offers for you page',
      action: 'Current bonus offers view',
      label: `${info.ids.toString()}|${info.names.toString()}`,
      value: info.ids.length,
    });
  },
  [PENDING_BONUS_OFFERS_VIEW]: (eligiblePendingOffersToBeRendered) => {
    const { data } = eligiblePendingOffersToBeRendered;
    let info = {
      ids: [],
      names: [],
    };
    data && data.forEach(offer => {
      const { offers, id } = offer;
      const { qualifyingMerchants } = offers[0];
      let merchantName;
      if (qualifyingMerchants) {
        const { name } = qualifyingMerchants.merchants[0];
        merchantName = name;
      }
      info = {
        ids: [...info.ids, id],
        names: [...info.names, merchantName].filter(name => name !== undefined),
      };
    });
    return ({
      category: 'Offers for you page',
      action: 'Pending bonus offers view',
      label: `${info.ids.toString()}|${info.names.toString()}`,
      value: info.ids.length,
    });
  },
};

export const forYouOffersTabClickAndHoverEvents = [
  {
    selector: '.mn_forYouOffersTab .mn_button',
    click: {
      category: 'Offers for you page',
      action: 'Current bonus offer click',
    },
    label: element => element.dataset.bonusClickLabel,
  },
  {
    selector: '.mn_forYouOffersTab .mn_termAndConditions.mn_current',
    click: {
      category: 'Offers for you page',
      action: 'Current bonus offer terms click',
    },
    label: element => element.dataset.termsClickLabel,
  },
  {
    selector: '.mn_forYouOffersTab .mn_termAndConditions.mn_pending',
    click: {
      category: 'Offers for you page',
      action: 'Pending bonus offer terms click',
    },
    label: element => element.dataset.termsClickLabel,
  },
  {
    selector: '.mn_forYouOffersTab .mn_reportMissing',
    click: {
      category: 'Offers for you page',
      action: 'Pending bonus offer missing transactions click',
    },
    label: element => element.dataset.reportMissing,
  },
];
