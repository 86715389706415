import { useEffect } from 'react';

const selectorsToHide = [
  '#app .mn_pageWrapper',
  '#mn_fullScreen',
  '#mn_bottomSticky',
];

export const useHandleHiddenAttribute = (modalRef) => {
  useEffect(() => {
    const elementsToHide = document.querySelectorAll(selectorsToHide.join(', '));

    elementsToHide.forEach(element => {
      element.setAttribute('aria-hidden', 'true');
    });

    return () => {
      elementsToHide.forEach(element => {
        element.removeAttribute('aria-hidden');
      });
    };
  }, [modalRef]);
};
