/* eslint-disable class-methods-use-this */

import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { fetchMerchants } from 'core/actions/ocapi';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

import FlipTile from 'core/modules/FlipTile/FlipTile';

import { selectSiteUrl } from 'core/selectors/app';

import {
  selectRecommendedStoresConfig,
  selectRecommendedStoresIsLoading,
  selectRecommendedStoresIsLoaded,
  selectRecommendedStoresMerchants,
} from 'core/modules/RecommendedStores/selectors';

import {
  selectFavoritedStoresConfig,
  selectFavoritedStoresIsLoading,
  selectFavoritedStoresIsLoaded,
  selectFavoritedStoresMerchants,
} from 'core/modules/FavoritedStores/selectors';

import {
  selectRecentStoresConfig,
  selectRecentStoresMerchants,
} from './selectors';

import { triggerRecentStoresFromSearchViewed } from './actions';

import './RecentStores.scss';

function RecentStores({ isPopUpView }) {
  const siteUrl = useSelector(selectSiteUrl);
  const config = useSelector(selectRecentStoresConfig);
  const merchants = useSelector(state => selectRecentStoresMerchants(state, isPopUpView));
  const recommendedStoresConfig = useSelector(selectRecommendedStoresConfig);
  const recommendedStoresIsLoading = useSelector(selectRecommendedStoresIsLoading);
  const recommendedStoresIsLoaded = useSelector(selectRecommendedStoresIsLoaded);
  const recommendedStoresMerchants = useSelector(selectRecommendedStoresMerchants);
  const favoritedStoresConfig = useSelector(selectFavoritedStoresConfig);
  const favoritedStoresIsLoading = useSelector(selectFavoritedStoresIsLoading);
  const favoritedStoresIsLoaded = useSelector(selectFavoritedStoresIsLoaded);
  const favoritedStoresMerchants = useSelector(selectFavoritedStoresMerchants);

  const [isRecommended, setIsRecommended] = useState(false);

  const { api: favoritedStoresApiConfig } = favoritedStoresConfig;
  const { params: favoritedStoresParams, options: favoritedStoresOptions } = favoritedStoresApiConfig;
  const { api: apiConfig, tagHelper, rebateOptions } = config;
  const { options } = apiConfig;
  const { sectionTitle, footerCTATitle, footerCTAUrl } = config.viewData;
  const dispatch = useDispatch();

  const updateIsRecommendedState = () => {
    const isRecommendedLocal = !!(!favoritedStoresMerchants.length && recommendedStoresMerchants.length);
    setIsRecommended(isRecommendedLocal);

    return isRecommendedLocal;
  };

  useEffect(() => {
    if (!favoritedStoresIsLoaded && !favoritedStoresIsLoading) {
      dispatch(fetchMerchants(favoritedStoresParams, { ...favoritedStoresOptions, ...options }));
    }
  }, []);

  useEffect(() => {
    if (merchants.length) {
      const isRecommended = updateIsRecommendedState();

      dispatch(triggerRecentStoresFromSearchViewed({ merchants, isRecommended }));
    }
  }, [merchants.length]);

  useEffect(() => {
    /**
     * we need to call recommended stores if the favorites stores were loaded but the response was empty
     */
    if (
      favoritedStoresIsLoaded
      && !favoritedStoresMerchants.length
      && !recommendedStoresMerchants.length
      && !recommendedStoresIsLoaded
      && !recommendedStoresIsLoading
    ) {
      const { api: recommendedStoresApiConfig } = recommendedStoresConfig;
      const { params: recommendedStoresParams, options: recommendedStoresOptions } = recommendedStoresApiConfig;
      const { api: apiConfig } = config;
      const { params, options } = apiConfig;

      dispatch(fetchMerchants({ ...recommendedStoresParams, ...params }, { ...recommendedStoresOptions, ...options }));
    }
  }, [
    favoritedStoresIsLoaded,
    favoritedStoresMerchants.length,
    recommendedStoresMerchants.length,
    recommendedStoresIsLoaded,
    recommendedStoresIsLoading,
  ]);

  const handleRef = useCallback((ref) => {
    tagHelper(ref); // handle Chase tagging implementation
  }, []);

  if (isEmpty(merchants)) {
    return (null);
  }

  return (
    <ComponentErrorBoundary>
      <div
        className="mn_recentStores"
        data-is_recommended={isRecommended}
        ref={handleRef}
      >
        <div className="mn_sectHead">
          <h3>{sectionTitle}</h3>
        </div>
        <div className="mn_sectContainer">
          {merchants.map((merchant) => (
            <FlipTile key={merchant.id} merchant={merchant} customRebateOptions={rebateOptions} />
          ))}
        </div>
        {
          footerCTAUrl && footerCTATitle &&
          <a href={`${siteUrl}${footerCTAUrl}`} className="mn_viewAllRecentStoresLinkBottom">{footerCTATitle}{' '}</a>
        }
      </div>
    </ComponentErrorBoundary>
  );
}

export default RecentStores;
