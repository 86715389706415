import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { infoBar as orgConfig } from 'org/configs/modules.config';
import {
  INFOBAR_DOTW_GET_SUCCESS, INFOBAR_DOTW_GET_FAILURE, INFOBAR_SALES_GET_SUCCESS, INFOBAR_SALES_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    shouldUseSliderView: true,
    useAddToFavorite: true,
    tagHelper: () => {},
    shouldTrackInitialParams: false,
    carousel: {
      hasDots: false,
      isClipped: true,
      breakpoints: {
        m: { isClipped: false },
      },
    },
    infoBarAccordion: {
      couponCodeLanguage: 'Use code:',
      shopNowCTALinkLabel: '',
      dateFormat: 'MMM DD, YYYY',
      rebateOptions: {
        showAdditionalElevationPrefix: true,
        showRebateInline: true,
        hideElevationCurrency: true,
      },
    },
    dealOfTheWeek: {
      api: {
        params: {
          offer_tag: 'deal-of-the-week',
          limit: 1,
        },
        options: {
          success: INFOBAR_DOTW_GET_SUCCESS,
          failure: INFOBAR_DOTW_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
          sectionId: 10441, // mn.sectionInfo.getId('dotw-v2'),
        },
      },
      dateFormat: 'MM/DD/YY',
      dealOfTheWeekText: 'Deal of the week',
      getLabelText: (isDealOfTheWeek) => (isDealOfTheWeek ? 'Deal of the week' : 'Deal of the day'),
    },
    salesInfoBar: {
      api: {
        params: {
          content_group_id: 1068,
          content_type_id: 59,
        },
        options: {
          success: INFOBAR_SALES_GET_SUCCESS,
          failure: INFOBAR_SALES_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
          sectionId: 10433, // mn.sectionInfo.getId('info-bar-v2'),
        },
      },
      dateFormat: 'MM/DD/YY',
      getLabelText: (isDealOfTheWeek) => (isDealOfTheWeek ? 'Deal of the week' : 'Deal of the day'),
    },
  },
  h: {
    shouldTrackInitialParams: true,
  },
  sp: {
    shouldUseSliderView: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
