import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { editEmailPreferencesModal as orgConfig } from 'org/configs/modules.config';

export const defaultConfig = {
  defaults: {
    modalOptions: {
      shouldUpdateUrl: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
