import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectRootDomain, selectUrlPrefix, selectUrlSuffix, selectSiteProtocol,
} from 'core/selectors/env';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import EmailOptInSubscribeModalView from './components/EmailOptInSubscribeModalView';
import {
  selectEmailOptInSubscribeModalConfig,
  selectEmailOptInSubscribeModalUserChoice,
  selectEmailOptInSubscribeModalUserChoiceSubmitted,
} from './selectors';
import { triggerEmailOptInModalSubscribeIsViewed } from './actions';
import './EmailOptInSubscribeModal.scss';

function EmailOptInSubscribeModal() {
  const location = useLocation();
  const dispatch = useDispatch();

  const config = useSelector(selectEmailOptInSubscribeModalConfig);
  const userChoice = useSelector(selectEmailOptInSubscribeModalUserChoice);
  const isUserChoiceSubmitted = useSelector(selectEmailOptInSubscribeModalUserChoiceSubmitted);
  const rootDomain = useSelector(selectRootDomain);
  const urlPrefix = useSelector(selectUrlPrefix);
  const urlSuffix = useSelector(selectUrlSuffix);
  const siteProtocol = useSelector(selectSiteProtocol);

  const { pathname, search, hash } = location;

  const siteUrl = `${siteProtocol}//${urlPrefix || ''}${rootDomain}${urlSuffix || ''}`;

  const getCallbackUrl = () => (
    `${siteUrl}${pathname}${search}${search.indexOf('?') !== -1 ? '&' : '?'}act=email${hash}`
  );

  const getFailureCallbackUrl = () => (
    `${siteUrl}${pathname}${search}${hash}`
  );

  useEffect(() => {
    dispatch(triggerEmailOptInModalSubscribeIsViewed());
  }, []);

  return (
    <EmailOptInSubscribeModalView
      siteUrl={siteUrl}
      cbUrl={getCallbackUrl()}
      fcbUrl={getFailureCallbackUrl()}
      userChoice={userChoice}
      isUserChoiceSubmitted={isUserChoiceSubmitted}
      {...config}
    />
  );
}

export default (props) =>
  <ComponentErrorBoundary><EmailOptInSubscribeModal {...props} /></ComponentErrorBoundary>;
