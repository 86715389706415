import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { trendingAndSeasonalOffers as orgConfig } from 'org/configs/modules.config';
import { TRENDING_AND_SEASONAL_OFFERS_GET_SUCCESS, TRENDING_AND_SEASONAL_OFFERS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 2,
        sort_by: 'random',
        content_group_id: 1030,
        fields: [
          'id',
          'assets',
          'clickUrl',
          'merchant.name',
        ],
      },
      options: {
        success: TRENDING_AND_SEASONAL_OFFERS_GET_SUCCESS,
        failure: TRENDING_AND_SEASONAL_OFFERS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: 10443,
      },
    },
    contentWrap: {},
    getTitle: (isMobileMQ) => (
      isMobileMQ ? 'More ways to earn' : 'More ways to earn miles'
    ),
    getMotto: () => '',
  },
  h: {
    contentWrap: {
      mobile: true, tablet: true, l: true, xl: true, xxl: false,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
