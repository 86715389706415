import { createSelector } from 'reselect';

export const selectProductDetailsModal = state => state.productDetailsModal;

export const selectProductDetailsModalConfig = createSelector(
  selectProductDetailsModal,
  productDetailsModal => productDetailsModal.config,
);

export const selectProductDetailsModalProduct = createSelector(
  selectProductDetailsModal,
  productDetailsModal => productDetailsModal.product,
);
