import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'core/modules/Modal/Modal';
import ReactDOM from 'react-dom';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';
import { useModal } from 'core/modules/Modal/ModalProvider';

const appDOMNode = document.getElementById('app');
const portalContainer = document.createElement('div');
portalContainer.classList.add('mn_emailOptInSuccessMessagePlaceholder');

function EmailOptInSuccessModalView({ earnType }) {
  const isMobileMQ = useSelector(selectIsMobileMQ);

  return (
    isMobileMQ ?
      <EmailOptInSuccessModalMobileView earnType={earnType} /> :
      <EmailOptInSuccessModalDesktopView earnType={earnType} />
  );
}

function EmailOptInSuccessModalMobileView({ earnType }) {
  return (
    <Modal className="mn_emailOptInSuccessModal">
      <h2 id="mn_dialogTitle">Success!</h2>
      <p id="mn_dialogDescription">Make sure to check your email for ways to earn {earnType}, plus special deals.</p>
    </Modal>
  );
}

function EmailOptInSuccessModalDesktopView({ earnType }) {
  const { closeModal } = useModal();

  const removePortalContainer = () => {
    appDOMNode.contains(portalContainer) && appDOMNode.removeChild(portalContainer);
  };

  useEffect(() => {
    appDOMNode.prepend(portalContainer);

    return () => removePortalContainer();
  }, []);

  const hideSuccessMessage = () => closeModal();

  return ReactDOM.createPortal(
    <div className="mn_emailOptInSuccessMessageWrapper">
      <div className="mn_emailOptInSuccessMessage">
        <button className="mn_closeModal" onClick={hideSuccessMessage} aria-label="Close" />
        <h2 id="mn_dialogTitle">Success!</h2>
        <div className="mn_emailOlayDesc" id="mn_dialogDescription">
          Make sure to check your email for ways to earn {earnType}, plus special deals.
        </div>
      </div>
    </div>,
    portalContainer,
  );
}

export default EmailOptInSuccessModalView;
