import { createSelector } from 'reselect';

export const selectMerchantOfferModal = state => state.merchantOfferModal;

export const selectMerchantOfferModalConfig = createSelector(
  selectMerchantOfferModal,
  MerchantOfferModal => MerchantOfferModal.config,
);

export const selectMerchantOfferModalMerchant = createSelector(
  selectMerchantOfferModal,
  MerchantOfferModal => MerchantOfferModal.merchant,
);

export const selectMerchantOfferModalSelectedOffer = createSelector(
  selectMerchantOfferModal,
  MerchantOfferModal => MerchantOfferModal.selectedOffer,
);
