import React from 'react';
import Modal from 'core/modules/Modal/Modal';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { useModal } from 'core/modules/Modal/ModalProvider';

import './OrderInquiryModal.scss';

function OrderInquiryModal({ modal }) {
  const { closeModal } = useModal();
  const {
    nextStep, merchantName, isVisitedMerchant, isTravelMerchant, specialConsiderationText,
  } = modal.params;
  const handleContinueClick = () => {
    nextStep();
    closeModal();
  };

  const isTravelMerchantOrSpecialConsiderationText = isTravelMerchant || specialConsiderationText;
  const shouldRenderMultipleIssues = (isVisitedMerchant && isTravelMerchantOrSpecialConsiderationText)
  || (isTravelMerchant && specialConsiderationText);
  const customModalTitle = shouldRenderMultipleIssues ? 'There are a couple of issues' : 'There may be an issue';

  const title = isTravelMerchantOrSpecialConsiderationText ? customModalTitle : "We couldn't find your store visit";

  const renderMultipleIssues = () => (
    <ul id="mn_dialogDescription">
      {isVisitedMerchant &&
        <li>
          <p>
            Unfortunately we couldn't find a store visit for {merchantName} on this date associated with your account.
          </p>
        </li>
      }
      {isTravelMerchant &&
        <li>
          <p>
            {merchantName} only issues rewards after travel has been completed or a reserved event has taken place.
            If this travel or event has not been completed yet, we will not be able to help at this time.
          </p>
        </li>
      }
      {specialConsiderationText && <li><p dangerouslySetInnerHTML={{ __html: specialConsiderationText }} /></li>}
      <p>Do you still need help with this order?</p>
    </ul>
  );

  const renderSingleIssues = () => {
    if (isTravelMerchant) {
      return (
        <div>
          <p id="mn_dialogDescription">
            {merchantName} only issues rewards after travel has been completed or a reserved event has taken place.
            If this travel or event has not been completed yet, we will not be able to help at this time.
          </p>
          <p>Do you still need help with this order?</p>
        </div>
      );
    }
    if (specialConsiderationText) {
      return (
        <div>
          <p dangerouslySetInnerHTML={{ __html: specialConsiderationText }} />
          <p>Do you still need help with this order?</p>
        </div>
      );
    }
    return (
      <div>
        <p id="mn_dialogDescription">
          Unfortunately we couldn't find a store visit for {merchantName} on this date associated with your account.
        </p>
        <p>Do you still need help with this order?</p>
      </div>
    );
  };

  return (
    <Modal className="mn_orderInquiryModal">
      <div id="mn_dialogTitle"><SectionTitle title={title} /></div>
      {shouldRenderMultipleIssues ? renderMultipleIssues() : renderSingleIssues()}
      <div className="mn_confirmationButtons">
        <button className="mn_button" onClick={closeModal}>Never mind</button>
        <button className="mn_button mn_continue" onClick={handleContinueClick}>
          Yes please
        </button>
      </div>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><OrderInquiryModal {...props} /></ComponentErrorBoundary>;
