import React from 'react';
import { useTable, usePagination } from 'react-table';

function DataTablePagination({
  columns, data, paginationOptions, metadata, fetchPlacementsMethod,
}) {
  const { pageSizeList } = paginationOptions;
  const { limit, offset, total } = metadata;
  const noOfPage = Math.ceil(total / limit);
  const activePage = Math.ceil(offset / limit);
  const showPaginationDots = noOfPage > 1;

  const {
    setPageSize,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: activePage, pageSize: limit },
      manualPagination: true,
      pageCount: noOfPage,
    },
    usePagination,
  );

  const onPaginationButtonClick = updatedIndex => () => {
    const additionalParameters = {
      limit,
      offset: updatedIndex * limit,
    };

    const pageNumber = updatedIndex + 1;

    gotoPage(updatedIndex);
    fetchPlacementsMethod(additionalParameters, pageNumber);
  };

  const onPaginationSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    gotoPage(0);

    const pageNumber = 1;
    const additionalParameters = {
      limit: Number(event.target.value),
    };

    fetchPlacementsMethod(additionalParameters, pageNumber);
  };

  const computePageListData = () => {
    const maxVisiblePages = 9;
    const noOfPageList = [];

    let lStart = pageIndex > (maxVisiblePages - 3) ? pageIndex - (maxVisiblePages - 3) : 0;
    let lStop = noOfPage > maxVisiblePages ? lStart + (maxVisiblePages - 0) : noOfPage;
    lStart = lStop > noOfPage ? noOfPage - (maxVisiblePages - 1) : lStart;
    lStop = lStop > noOfPage ? noOfPage : lStop;

    for (let i = lStart; i < lStop; i += 1) {
      noOfPageList.push({
        pageNumber: i,
        activePage,
        noOfPage,
        className: i === activePage ? 'mn_activePageDot' : 'mn_inactivePageDot',
        offset: i * limit,
      });
    }

    return noOfPageList;
  };

  return (
    <div className="mn_pagination">
      {showPaginationDots &&
      <div className="mn_paginationDots">
        {canPreviousPage &&
          <button className="mn_prev" onClick={onPaginationButtonClick(pageIndex - 1)}>prev</button>
        }
        {computePageListData().map(pageDot => (
          <button
            key={pageDot.pageNumber}
            value={pageDot.pageNumber}
            data-active-page={pageDot.activePage}
            offset={pageDot.offset}
            className={`mn_pageDot ${pageDot.className}`}
            onClick={onPaginationButtonClick(pageDot.pageNumber)}
          >
            {pageDot.pageNumber}
          </button>
        ))}
        {canNextPage &&
          <button className="mn_next" onClick={onPaginationButtonClick(pageIndex + 1)}>next</button>
        }
      </div>
      }
      { pageSizeList && pageSizeList.length > 1 &&
        <>
          <p className="mn_paginationPageSizeListLabel">Showing:</p>
          <select
            className="mn_paginationPageSizeList"
            data-test="pagination-limit-list"
            value={pageSize}
            onChange={onPaginationSizeChange}
          >
            {pageSizeList.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} per page
              </option>
            ))}
          </select>
        </>
      }
    </div>
  );
}

export default DataTablePagination;
