import React from 'react';
import { useTable } from 'react-table';

function DataTableContent({ columns, data, children }) {
  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, page,
  } = useTable({ columns, data });

  return (
    <table {...getTableProps()} data-test="data-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className={column.className}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {children ?
          children.map((child) =>
            (
              <tr
                key={child.key}
                className="mn_dataTableRow"
              >
                <td>
                  { child }
                </td>
              </tr>
            ))
          : page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

export default DataTableContent;
