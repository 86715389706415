import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { storeRelatedMerchants as orgConfig } from 'org/configs/modules.config';
import { getId } from 'core/utils/analytics/sectionInfo';
import { RELATED_STORES_GET_SUCCESS, RELATED_STORES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        with_offers: 1,
        limit: 12,
      },
      options: {
        success: RELATED_STORES_GET_SUCCESS,
        failure: RELATED_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: getId('store-related-merchants'),
      },
    },
    useAddToFavorite: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
