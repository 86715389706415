import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dySPA } from 'core/utils/analytics/dyAnalytics';
import { selectUserIsIdentified } from 'core/selectors/user';
import { selectPageName } from 'core/selectors/app';
import { fetchMerchantById, fetchPlacementsById } from 'core/actions/ocapi';

import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import MerchantOffer from './components/MerchantOffer';
import { clearMerchantOfferData, triggerMerchantOfferOverlayIsViewed } from './actions';

import {
  selectMerchantOfferModalConfig,
  selectMerchantOfferModalMerchant,
  selectMerchantOfferModalSelectedOffer,
} from './selectors';

function MerchantOfferModal({ modal }) {
  const {
    offerId, sectionId, merchantId, track,
  } = modal.params;
  const customClass = 'mn_merchantOfferModal';
  const dispatch = useDispatch();

  const isType6OfferFetched = useRef(false);
  const historyUpdated = useRef();
  const previousHash = useRef();

  const config = useSelector(selectMerchantOfferModalConfig);
  const merchant = useSelector(selectMerchantOfferModalMerchant);
  const selectedOffer = useSelector(selectMerchantOfferModalSelectedOffer);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const pageName = useSelector(selectPageName);
  const { api: { params, options } } = config;

  useEffect(() => {
    const apiOptions = { ...options, sectionId };
    apiOptions.clickData = modal.params;
    dispatch(fetchMerchantById(merchantId, params, apiOptions));

    dySPA.sendEvent();

    return () => {
      dispatch(clearMerchantOfferData());
    };
  }, []);

  useEffect(() => {
    const apiOptions = { ...options, sectionId };

    // if we have merchant and offer id but don't have selected offer then call placement API as this is type6 offer
    if (merchant && !selectedOffer && offerId && !isType6OfferFetched.current) {
      /**
       * type 6 offers doesnt come back in merchants offers list:
       * additional call to placements to get offer info
       * to display info to end user.
       */
      apiOptions.clickData = modal.params;
      dispatch(fetchPlacementsById(offerId, params, apiOptions));
      isType6OfferFetched.current = true;
    }
  });

  useEffect(() => {
    if (merchant) {
      dispatch(triggerMerchantOfferOverlayIsViewed(merchant.name, userIsIdentified, pageName));
    }
  }, [merchant]);

  if (merchant) {
    return (
      <MerchantOffer
        merchant={merchant}
        sectionId={sectionId}
        selectedOffer={selectedOffer}
        isUserIdentified={userIsIdentified}
        historyUpdated={historyUpdated.current}
        previousHash={previousHash.current}
        customClass={customClass}
        config={config}
        shouldTrackInitialParams={track}
      />
    );
  }

  return null;
}

export default (props) => <ComponentErrorBoundary><MerchantOfferModal {...props} /></ComponentErrorBoundary>;
