import { getFormValues } from 'redux-form';
import { select, takeEvery, put } from 'redux-saga/effects';
import dayjs from 'core/utils/dayjs';
import { fetchMemberTransactions, fetchMemberOrders } from 'core/actions/ocapi';

import {
  INQUIRY_ORDER_TRANSACTIONS_GET, INQUIRY_ORDER_CLICK_HISTORY_GET,
} from '../actions/types';

import {
  selectTransactionsRecordsByMerchantId, selectTransactionsConfig, selectClickHistoryConfig,
} from '../selectors';

function* getTransactions() {
  const transactionsConfig = yield select(selectTransactionsConfig);
  const formValues = yield select(getFormValues('contactUsOrderInquiryForm'));
  const { purchaseDate = '' } = formValues;
  const { api: { params = {}, options } } = transactionsConfig;

  const apiParams = {
    ...params,
    transaction_start_date: dayjs(purchaseDate).format('YYYY-MM-DD'),
    transaction_end_date: dayjs(purchaseDate).format('YYYY-MM-DD'),
  };

  yield put(fetchMemberTransactions(apiParams, options));
}

function* getClickHistory() {
  const transactionsBySelectedMerchantId = yield select(selectTransactionsRecordsByMerchantId);

  if (transactionsBySelectedMerchantId.length) {
    return;
  }

  const clickHistoryConfig = yield select(selectClickHistoryConfig);
  const formValues = yield select(getFormValues('contactUsOrderInquiryForm'));
  const { purchaseDate = '' } = formValues;
  const { api: { params = {}, options } } = clickHistoryConfig;

  const apiParams = {
    ...params,
    start_date: dayjs(purchaseDate).subtract(3, 'days').format('YYYY-MM-DD'),
    end_date: dayjs(purchaseDate).add(2, 'days').format('YYYY-MM-DD'),
  };

  yield put(fetchMemberOrders(apiParams, options));
}

export default function* inquiryOrderSaga() {
  yield takeEvery(INQUIRY_ORDER_TRANSACTIONS_GET, getTransactions);
  yield takeEvery(INQUIRY_ORDER_CLICK_HISTORY_GET, getClickHistory);
}
