import { useEffect } from 'react';
import keyCodes from 'core/utils/helpers/keyCodes';

const focusableSelectors = [
  'a[href]',
  'button:not([disabled])',
  'textarea:not([disabled])',
  'input:not([disabled])',
  'select:not([disabled])',
  '[tabindex]:not([tabindex="-1"])',
];

export const useTrapFocus = (modalRef) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!keyCodes.TAB) {
        return;
      }

      const focusableModalElements = modalRef.current.querySelectorAll(focusableSelectors.join(', '));
      const firstElement = focusableModalElements[0];
      const lastElement = focusableModalElements[focusableModalElements.length - 1];

      if (event.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        event.preventDefault();
        return;
      }

      if (!event.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        event.preventDefault();
      }
    };

    const modalElement = modalRef.current;
    modalElement.addEventListener('keydown', handleKeyDown);

    return () => {
      modalElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalRef]);
};
