import { createSelector } from 'reselect';

const selectDeletedMemberModal = state => state.deletedMemberModal;

export const selectDeletedMemberModalConfig = createSelector(
  selectDeletedMemberModal,
  deletedMemberModal => deletedMemberModal.config,
);

export const selectDeletedMemberModalIsLoading = createSelector(
  selectDeletedMemberModal,
  deletedMemberModal => deletedMemberModal.isLoading,
);

export const selectDeletedMemberModalIsLoaded = createSelector(
  selectDeletedMemberModal,
  deletedMemberModal => deletedMemberModal.isLoaded,
);

export const selectDeletedMemberModalInfo = createSelector(
  selectDeletedMemberModal,
  deletedMemberModal => deletedMemberModal.memberDeleteInfo,
);
