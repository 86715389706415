import { createSelector } from 'reselect';
import { selectConfig } from './app';

export const selectSubscriptions =
  createSelector(selectConfig, config => config.subscriptions);
export const selectEmailNewsOptin =
  createSelector(selectSubscriptions, subscriptions => subscriptions.email_news_optin || null);
export const selectEmailOdotwOptin =
  createSelector(selectSubscriptions, subscriptions => subscriptions.email_odotw_optin || null);
export const selectEmailSoloOptin =
  createSelector(selectSubscriptions, subscriptions => subscriptions.email_solo_optin || null);
export const selectEmailDiscoveryOptin =
  createSelector(selectSubscriptions, subscriptions => subscriptions.email_discovery_optin || null);
export const selectEmailGlobalOptout =
  createSelector(selectSubscriptions, subscriptions => subscriptions.email_global_optout || null);
export const selectMarketingEmailInd =
  createSelector(selectSubscriptions, subscriptions => subscriptions.marketing_email_ind || '');
export const selectFavoritesOptin =
  createSelector(selectSubscriptions, subscriptions => subscriptions.email_favorites_optin || null);
