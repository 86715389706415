import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSiteUrl } from 'core/selectors/app';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { useModal } from 'core/modules/Modal/ModalProvider';

import config from './configs';

import { triggerFirstFavoriteModalIsViewed } from './actions';

import './FirstFavoriteModal.scss';

function FirstFavoriteModal() {
  const siteUrl = useSelector(selectSiteUrl);
  const dispatch = useDispatch();
  const {
    getBodyText, titleText, buttonText, modalOptions,
  } = config;
  const bodyText = getBodyText(siteUrl);
  const { closeModal } = useModal();

  useEffect(() => {
    dispatch(triggerFirstFavoriteModalIsViewed());
  }, []);

  const handleButtonClick = () => closeModal();

  return (
    <Modal className="mn_firstFavoriteModal" options={modalOptions}>
      <h2 id="mn_dialogTitle">{titleText}</h2>
      <p id="mn_dialogDescription">{bodyText}</p>
      <button className="mn_button mn_okButton" onClick={handleButtonClick}>{buttonText}</button>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><FirstFavoriteModal {...props} /></ComponentErrorBoundary>;
