import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import latinize from 'latinize';
import parseClickUrl from './commonParsers/clickUrlParser';
import parseRebate from './commonParsers/rebateParser';
import parseOfferDateProperties from './commonParsers/offerDateParser';
import encodeQueryObject from './commonParsers/encodeQueryObject';
import getQueryParam from './commonParsers/getQueryParam';

const generateInstoreMerchantHref = (merchant) => {
  if (isEmpty(merchant.offers)) {
    return '';
  }
  const params = {
    lightboxType: 'instoreLinkCard',
    checkForFirstTimeEnrollCard: false,
    offerId: merchant.offers[0].offerId,
  };
  return `/instore#${encodeQueryObject(params)}`;
};

const parseOffer = ({ merchant, offer }, state, action) =>
  merge({}, parseOfferDateProperties(offer, state), {
    clickUrl: parseClickUrl(offer.clickUrl, state, action),
    offerId: getQueryParam(offer.clickUrl, 'c'),
    merchant: {
      name: merchant.name,
      id: merchant.id,
    },
  });

export const merchantsInstoreParser = response =>
  // eslint-disable-next-line no-use-before-define
  response.map(merchant => merchantInstoreParser(merchant));

export const merchantsOnlineParser = (response, state, action) =>
  // eslint-disable-next-line no-use-before-define
  response.map(merchant => merchantOnlineParser(merchant, state, action));

function merchantOnlineParser(merchant, state, action) {
  const isDeactivated = merchant.type === 'Deactivated Merchant';

  return merge({}, merchant, {
    clickUrl: parseClickUrl(merchant.clickUrl, state, action),
    rebate: parseRebate(merchant.rebate, merchant.tiers, state, isDeactivated),
    href: `/me____.htm?gmid=${merchant.id}`,
    nameLatinised: latinize(merchant.name),
    isInstore: false,
    instoreMerchant: merchant.instoreMerchant
      ? merchantsInstoreParser([merchant.instoreMerchant])[0]
      : null,
    isSuspended: merchant.type === 'Suspended Merchant',
    isDeactivated,
    relatedActiveMerchants: merchant.relatedActiveMerchants
      ? merchantsOnlineParser(merchant.relatedActiveMerchants, state, action)
      : null,
    offers: !merchant.offers || merchant.offers.length === 0
      ? []
      : merchant.offers.map(offer => parseOffer({ merchant, offer }, state, action)),
  });
}

function merchantInstoreParser(merchant) {
  const href = generateInstoreMerchantHref(merchant);

  return merge({}, merchant, {
    isInstore: true,
    hasActiveOffer: !isEmpty(merchant.offers),
    href,
    clickUrl: href,
    nameLatinised: latinize(merchant.name),
  });
}

export const merchantsAllParser = (response, state, action) => (
  response.map((merchant) => {
    const isInstore = merchant.type === 'instore';
    return isInstore ? merchantInstoreParser(merchant) : merchantOnlineParser(merchant, state, action);
  })
);
