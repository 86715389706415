import { getDisplayPageName } from 'core/utils/analytics/pageInfo';
import { BONUS_ROLLING_MODAL_VIEW, BONUS_ROLLING_MODAL_ON_OVERLAY_CLICK_CLOSE } from '../actions/types';
import { BONUS_ROLLING_DISPLAY_HIW } from '../utils/keys';

export const bonusRollingModalGaActionEvents = {
  [BONUS_ROLLING_MODAL_VIEW]: ({ bonusItem }) => {
    const { rollingInfo: { messagingType }, id } = bonusItem;
    const hiwStatus = messagingType === BONUS_ROLLING_DISPLAY_HIW ? 'With HIW' : 'Without HIW';
    const pageName = getDisplayPageName();

    return {
      category: 'Rolling Bonus: Lightbox',
      action: 'Lightbox View',
      label: `${hiwStatus} | ${pageName} | ${id}`,
      value: 1,
    };
  },
  [BONUS_ROLLING_MODAL_ON_OVERLAY_CLICK_CLOSE]: () => ({
    category: 'Rolling Bonus: Lightbox',
    action: 'Lightbox Close',
    label: 'Clicked outside lightbox',
    value: 1,
  }),
};

export const bonusRollingModalClickAndHoverEvents = [
  {
    selector: '.mn_bonusRollingModal .mn_termsButton',
    click: {
      category: 'Rolling Bonus: Lightbox',
      action: 'Lightbox terms click',
      value: 1,
    },
  },
  {
    selector: '.mn_bonusRollingModal .mn_info .mn_button',
    click: {
      category: 'Rolling Bonus: Lightbox',
      action: 'Lightbox Close',
      value: 1,
    },
    label: 'Start Shopping',
  },
  {
    selector: '.mn_bonusRollingModal .mn_closeModal',
    click: {
      category: 'Rolling Bonus: Lightbox',
      action: 'Lightbox Close',
      value: 1,
    },
    label: 'X',
  },
];
