import { createSelector } from 'reselect';

export const selectHybridStoresOfferModal = state => state.hybridStoresOfferModal;

export const selectHybridStoresOfferModalConfig = createSelector(
  selectHybridStoresOfferModal,
  hybridStoresOfferModal => hybridStoresOfferModal.config,
);

export const selectHybridStoresOfferModalIsLoading = createSelector(
  selectHybridStoresOfferModal,
  hybridStoresOfferModal => hybridStoresOfferModal.isLoading,
);

export const selectHybridStoresOfferModalIsLoaded = createSelector(
  selectHybridStoresOfferModal,
  hybridStoresOfferModal => hybridStoresOfferModal.isLoaded,
);

export const selectHybridStoresOfferModalPlacements = createSelector(
  selectHybridStoresOfferModal,
  hybridStoresOfferModal => hybridStoresOfferModal.offers,
);
