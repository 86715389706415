// This hook appends tracking parameters for links leading to the merchant page,
// enabling the identification of the originating section from which the user navigated.

import { useSelector } from 'react-redux';
import { selectPageId } from 'core/selectors/app';

export const useDefineInitialParams = (shouldPassInitialParams, params) => {
  const pageId = useSelector(selectPageId);

  if (!shouldPassInitialParams) {
    return {};
  }

  return {
    pageId,
    ...params,
  };
};
