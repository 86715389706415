import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import { selectEnableAdBlockWarning, selectBrandDisplayName } from 'core/selectors/app';
import { selectDisplayableUserId, selectUserIsIdentified } from 'core/selectors/user';
import { selectCookieDomain, selectIsHttps } from 'core/selectors/env';
import { selectAdblockIsInstalled, selectAdblockConfig } from './selectors';
import { confirmAdblockIsInstalled, sendAnalyticsAdblockIsInstalled } from './actions';

import './AdBlockWarning.scss';

function AdBlockWarning() {
  const dispatch = useDispatch();
  const adBlockDetectorRef = useRef(null);
  const cookieDomain = useSelector(selectCookieDomain);
  const isHttps = useSelector(selectIsHttps);
  const displayableUserId = useSelector(selectDisplayableUserId);
  const enableAdBlockWarning = useSelector(selectEnableAdBlockWarning);
  const adblockIsInstalled = useSelector(selectAdblockIsInstalled);
  const config = useSelector(selectAdblockConfig);
  const brandDisplayName = useSelector(selectBrandDisplayName);
  const userIsIdentified = useSelector(selectUserIsIdentified);

  const { getWarningMessage } = config;

  const [shouldRenderAdBlockDetector, setShouldRenderAdBlockDetector] = useState(true);

  // Render AdBlockDetector element first time and check whether its CSS display property is changed to 'none'
  // by adblock extension. If yes we show AdBlock Warning on next render, otherwise nothing will be rendered.
  useEffect(() => {
    if (window.getComputedStyle(adBlockDetectorRef.current).display === 'none') {
      dispatch(confirmAdblockIsInstalled());
    }
    setShouldRenderAdBlockDetector(false);
  }, []);

  useEffect(() => {
    if (Cookies.get('mn_browserSession') !== '1' && adblockIsInstalled) {
      dispatch(sendAnalyticsAdblockIsInstalled(displayableUserId));
      Cookies.set('mn_browserSession', '1', { path: '/', domain: cookieDomain, secure: isHttps });
    }
  }, [adblockIsInstalled]);

  const warningMessage = getWarningMessage(brandDisplayName);

  const renderAdBlockWarning = () => (
    <div className="mn_adBlockWarning" data-test="ad-block-warning">
      <div className="mn_contentWrap">
        <div className="mn_adBlockWarningMessage">
          <div className="mn_adBlockWarningMessageInner" dangerouslySetInnerHTML={{ __html: warningMessage }} />
        </div>
      </div>
    </div>
  );

  const renderAdBlockDetector = () => (
    <div
      ref={adBlockDetectorRef}
      className="ad ads adsbox ad-placement doubleclick ad-placeholder ad-badge"
    />
  );

  if (shouldRenderAdBlockDetector) {
    return renderAdBlockDetector();
  }

  if (enableAdBlockWarning && adblockIsInstalled && userIsIdentified) {
    return renderAdBlockWarning();
  }

  return null;
}

export default AdBlockWarning;
