import React from 'react';
import './PremierMerchantRebate.scss';

function rebatePrefix({
  hideRebatePrefix, prefix, earnPrefix, additionalRebatePrefix,
  elevation, isTiered, tiered: tieredConfig,
}) {
  const { rebateAdditionalPrefix: tieredRebateAdditionalPrefix } = tieredConfig || {};
  if (hideRebatePrefix || (!prefix && !additionalRebatePrefix && !tieredRebateAdditionalPrefix) || elevation) {
    return (null);
  }

  if (isTiered) {
    if (tieredRebateAdditionalPrefix) {
      return (
        <span className="mn_tieredAdditionalPrefix">
          {`${tieredRebateAdditionalPrefix} `}
        </span>
      );
    }

    if (!earnPrefix && !tieredRebateAdditionalPrefix) {
      return (
        <span className="mn_rebatePrefix">
          Earn&nbsp;
        </span>
      );
    }

    return (
      <span className="mn_rebatePrefix">
        {`${earnPrefix} `}
      </span>
    );
  }

  if (additionalRebatePrefix) {
    return (
      <span className="mn_rebatePrefix">
        {`${additionalRebatePrefix} ${prefix} `}
      </span>
    );
  }

  return (
    <span className="mn_rebatePrefix">
      {`${prefix} `}
    </span>
  );
}

function buildSuffixTierNameRebate(suffixTierName, showRebateTierName) {
  return (showRebateTierName && suffixTierName) ? <span className="mn_tierName"> on {suffixTierName}</span> : null;
}

function rebateSuffix({
  hideRebateSuffix, suffix, suffixTierName, showRebateTierName,
}) {
  if (hideRebateSuffix || !suffix) {
    return buildSuffixTierNameRebate(suffixTierName, showRebateTierName);
  }

  return (
    <span className="mn_rebateSuffix">
      {` ${suffix}`} {buildSuffixTierNameRebate(suffixTierName, showRebateTierName)}
    </span>
  );
}

function rebateValue(props) {
  const {
    formattedValueWithCurrency, isTiered, suffixTierName, tieredPrefix,
  } = props;
  let tieredPrefixResult = !suffixTierName ? tieredPrefix : '';
  let tieredRebateClass = '';
  if (isTiered && !suffixTierName) {
    tieredPrefixResult = <span className="mn_tieredPrefix">{tieredPrefix}&nbsp;</span>;
    tieredRebateClass = 'mn_tiered';
  }

  return (
    <span className={`mn_rebateValue ${tieredRebateClass}`}>
      {tieredPrefixResult}{formattedValueWithCurrency}{rebateSuffix(props)}
    </span>
  );
}

function rebateValueWithElevation(props) {
  const {
    formattedOriginalValue, formattedValueWithCurrency, tieredPrefix,
    suffixTierName, isTiered, showAdditionalElevationPrefix,
  } = props;

  let tieredPrefixResult = !suffixTierName ? tieredPrefix : '';
  let tieredRebateClass = '';

  if (isTiered && !suffixTierName) {
    tieredPrefixResult = <span className="mn_tieredPrefix">{tieredPrefix}&nbsp;</span>;
    tieredRebateClass = 'mn_tieredElevation';
  }

  const buildOriginalValueToShow = ({
    formattedOriginalValueWithCurrency, suffix, isTiered, tiered = {},
    hideRebateCurrencyPrefixInWas, hideElevationCurrency,
  }) => {
    if (hideElevationCurrency) {
      return formattedOriginalValue;
    }

    if ((isTiered && tiered.hideRebateCurrencyPrefixInWas) || (!isTiered && hideRebateCurrencyPrefixInWas)) {
      return formattedOriginalValueWithCurrency;
    }

    if (suffix) {
      return `${formattedOriginalValueWithCurrency} ${suffix}`;
    }

    return formattedOriginalValueWithCurrency;
  };

  const originalValueToShow = buildOriginalValueToShow(props);
  const additionalElevationPrefix =
    showAdditionalElevationPrefix && !isTiered ? <span className="mn_additionalElevationPrefix">earn</span> : '';

  return (
    <span className={`mn_rebateValue mn_rebateElevated ${tieredRebateClass}`}>
      <div className="mn_elevationOldValue">
        <span className="mn_elevationWasPrefix">Was</span> {tieredPrefixResult}{originalValueToShow}
      </div>
      {' '}
      <div className="mn_elevationNewValue">
        <span className="mn_elevationNowPrefix">Now</span>{' '}
        {additionalElevationPrefix} {tieredPrefixResult}{formattedValueWithCurrency}
        {rebateSuffix(props)}
      </div>
    </span>
  );
}

function rebateValueWithCurrency(props) {
  const { elevation } = props;

  return (
    <span className="mn_rebateValueWithCurrency">
      {elevation ? rebateValueWithElevation(props) : rebateValue(props)}
    </span>
  );
}

function rebateValueWithNoRebate(props) {
  return (
    <span className="mn_merchantRebate">
      <span className="mn_rebateValue">{props.value}</span>
    </span>
  );
}

function PremierMerchantRebate(props) {
  const { isDeactivated } = props;

  return (
    <div className="mn_premierMerchantRebate">
      <div className={props.showRebateInline ? 'mn_inlineRebate' : 'mn_blockRebate'}>
        {rebatePrefix(props)}
        <span className="mn_rebateValueAndSuffixWrap">
          {isDeactivated ? (rebateValueWithNoRebate(props)) : rebateValueWithCurrency(props)}
        </span>
      </div>
    </div>
  );
}

export default PremierMerchantRebate;
