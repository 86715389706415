import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { relatedCategoryFeaturedStores as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    useCarousel: true,
    maxCategories: 2,
    maxFeaturedMerchantsPerCategory: 6,
    currency: 'points',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
