import React, {
  createContext, useContext, useState, useMemo,
} from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

const ModalContext = createContext({});

function ModalProvider({ children }) {
  const [modals, setModals] = useState([]);

  const openModal = (modal) => setModals([modal]);
  const closeModal = () => setModals((old) => old.slice(0, old.length - 1));

  const modal = modals[modals.length - 1];

  const value = useMemo(() => ({
    modal, openModal, closeModal,
  }), [modal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => useContext(ModalContext);

export default (props) => <ComponentErrorBoundary><ModalProvider {...props} /></ComponentErrorBoundary>;
