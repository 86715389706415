import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { popularOffers as orgConfig } from 'org/configs/modules.config';
import { POPULAR_OFFERS_GET_SUCCESS, POPULAR_OFFERS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        sort_by: 'random',
        content_type_id: 19,
        fields: [
          'id',
          'assets',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.name',
          'clickUrl',
        ],
      },
      options: {
        success: POPULAR_OFFERS_GET_SUCCESS,
        failure: POPULAR_OFFERS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: getId('popular-offers-300x250'),
      },
    },
    sectionTitle: 'Popular offers',
    useAddToFavorite: false,
    shouldIncludeBonusOffers: true,
    includeBonusOffersOnMediaQueriesOnly: [],
    getShouldShowViewAllLink: (isMobileMQ) => (!isMobileMQ),
    checkShouldUseCarousel: () => (false),
    tagHelper: () => {},
    rebateOptions: {
      additionalRebatePrefix: 'Plus,',
      prefix: 'earn',
      tiered: {
        additionalRebatePrefix: 'Plus,',
        tieredPrefix: 'up to',
      },
    },
    carousel: {
      align: 'start',
      hasArrows: false,
      hasDots: false,
      hasCounter: true,
      isClipped: true,
      breakpoints: {
        m: { isClipped: false, hasCounter: false },
      },
    },
  },
  h: {
    sectionTitle: 'Trending offers',
    shouldIncludeBonusOffers: false,
    maxNumberOfBonusOffersInXXL: 2,
    includeBonusOffersOnMediaQueriesOnly: ['XS', 'S', 'M', 'L', 'XL'],
    checkShouldUseCarousel: (activeMQ) => (['XS', 'S', 'M', 'L', 'XL'].includes(activeMQ)),
    api: {
      params: {
        offer_tag: 'trending-offers',
      },
    },
  },
  sp: {
    bonusOffersLimit: 2,
    api: {
      params: {
        content_group_id: 11041,
      },
    },
  },
  fs: {
    bonusOffersLimit: 2,
    api: {
      params: {
        content_group_id: 11039,
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
