import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMerchantRelatedBonus, fetchBonus } from 'core/actions/bonus';
import { selectUserIsIdentified } from 'core/selectors/user';
import {
  selectBonusOffersConfig,
  selectBonusOffersIsLoading,
  selectBonusOffersIsLoaded,
  selectBonusOffersPlacement,
  selectBonusOffersNotQualifiedAndActiveFormatted,
  selectBonusTrackerOffer,
  selectBonusRollingOffersToShow,
  selectBonusRollingOfferToShow,
} from '../selectors';

/*
 There are multiple places where we  use `useGetBonusOffers` hook and it appears there is a race condition
 when `isLoaded` and `isLoading` flags in redux state are updated with a delay so the following
`useGetBonusOffers` hooks could start fetching data again leading to multiple API calls.
This is a temporal fix and we should return to resolving this issue after completing updating our libs.
*/
let isFetchingProceeded = false;

export const useGetBonusOffers = () => {
  const dispatch = useDispatch();
  const config = useSelector(selectBonusOffersConfig);
  const isLoading = useSelector(selectBonusOffersIsLoading);
  const isLoaded = useSelector(selectBonusOffersIsLoaded);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const bonusOffers = useSelector(selectBonusOffersPlacement);
  const bonusOffersNotQualifiedAndActiveFormatted = useSelector(selectBonusOffersNotQualifiedAndActiveFormatted);
  const bonusTrackerOffer = useSelector(selectBonusTrackerOffer);
  const bonusRollingOffersToShow = useSelector(selectBonusRollingOffersToShow);
  const bonusRollingOfferToShow = useSelector(selectBonusRollingOfferToShow);

  useEffect(() => {
    if (!isLoading && !isLoaded && !isFetchingProceeded) {
      const { api: { params, options } } = config;
      if (userIsIdentified) {
        dispatch(fetchMerchantRelatedBonus(params, options));
      } else {
        dispatch(fetchBonus(params, options));
      }

      isFetchingProceeded = true;
    }
  }, [isLoaded, isLoading]);

  return {
    bonusOffers,
    bonusOffersNotQualifiedAndActiveFormatted,
    bonusTrackerOffer,
    bonusRollingOffersToShow,
    bonusRollingOfferToShow,
  };
};
