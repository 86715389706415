import React from 'react';
import './MerchantRebate.scss';

function rebatePrefix({ hideRebatePrefix, prefix, additionalRebatePrefix }) {
  if (hideRebatePrefix || (!prefix && !additionalRebatePrefix)) {
    return (null);
  }

  if (additionalRebatePrefix) {
    return (
      <span className="mn_rebatePrefix">
        {`${additionalRebatePrefix} ${prefix}`}&nbsp;
      </span>
    );
  }

  return (
    <span className="mn_rebatePrefix">
      {`${prefix}`}&nbsp;
    </span>
  );
}

function buildSuffixTierNameRebate(suffixTierName, showRebateTierName) {
  return (showRebateTierName && suffixTierName) ? <span className="mn_tierName"> on {suffixTierName}</span> : null;
}

function rebateSuffix({
  hideRebateSuffix, suffix, suffixTierName, showRebateTierName,
}) {
  if (hideRebateSuffix || !suffix) {
    return buildSuffixTierNameRebate(suffixTierName, showRebateTierName);
  }

  return (
    <span className="mn_rebateSuffix">
      {` ${suffix}`} {buildSuffixTierNameRebate(suffixTierName, showRebateTierName)}
    </span>
  );
}

function rebateValue({ formattedValueWithCurrency, tieredPrefix, hideRebatePrefix }) {
  // prefix already includes tieredPrefix so if it's not hidden, don't show tieredPrefix
  let tieredPrefixResult = tieredPrefix;
  if (!hideRebatePrefix && !tieredPrefix) {
    tieredPrefixResult = '';
  } else if (tieredPrefix && tieredPrefix.length > 0) {
    tieredPrefixResult = `${tieredPrefix} `;
  }

  return (
    <span className="mn_rebateValue">
      {tieredPrefixResult}{formattedValueWithCurrency}
    </span>
  );
}

function rebateValueWithElevation({
  formattedValueWithCurrency, formattedOriginalValue, tieredPrefix, hideRebatePrefix,
}) {
  // prefix already includes tieredPrefix so if it's not hidden, don't show tieredPrefix
  let tieredPrefixResult = tieredPrefix;
  if (!hideRebatePrefix && !tieredPrefix) {
    tieredPrefixResult = '';
  } else if (tieredPrefix && tieredPrefix.length > 0) {
    tieredPrefixResult = `${tieredPrefix} `;
  }

  return (
    <span className="mn_rebateValue">
      {tieredPrefixResult}
      <span className="mn_elevationOldValue">{formattedOriginalValue}</span>
      {' '}
      <span className="mn_elevationNewValue">{formattedValueWithCurrency}</span>
    </span>
  );
}

function rebateValueWithCurrency(props) {
  const { elevation } = props;

  return (
    <span className="mn_rebateValueWithCurrency">
      {elevation ? rebateValueWithElevation(props) : rebateValue(props)}
    </span>
  );
}

function rebateValueWithNoRebate(props) {
  return (
    <span className="mn_merchantRebate">
      <span className="mn_rebateValue">{props.value}</span>
    </span>
  );
}

function MerchantRebate(props) {
  const { isDeactivated } = props;

  return (
    <span className="mn_merchantRebate">
      {rebatePrefix(props)}
      <span className="mn_rebateValueAndSuffixWrap">
        {isDeactivated ? (rebateValueWithNoRebate(props)) : rebateValueWithCurrency(props)}
      </span>
      {rebateSuffix(props)}
    </span>
  );
}

MerchantRebate.defaultProps = {
  formattedValue: '',
  currency: {
    prefix: { current: '' },
    suffix: '',
  },
  tieredPrefix: '',
  hideRebatePrefix: false,
};

export default MerchantRebate;
