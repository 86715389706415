import React from 'react';
import { useSelector } from 'react-redux';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';
import MerchantClickUrlLink from 'core/modules/MerchantClickUrlLink/MerchantClickUrlLink';
import ExtraRewardsPill from 'core/modules/ExtraRewardsPill/ExtraRewardsPill';
import Pill from 'core/modules/Pill/Pill';

import { selectProductDetailsModalConfig } from '../selectors';

function HybridProductOffer({ offer }) {
  const {
    merchant, price, clickUrl, onSale,
  } = offer;

  const { rebate, name } = merchant;
  const { isExtraRewards } = rebate;

  const shouldShowSaleFlag = !isExtraRewards && onSale;

  const config = useSelector(selectProductDetailsModalConfig);
  const { rebateOptions } = config;

  return (
    <div className="mn_productOffer">
      {shouldShowSaleFlag && <Pill label="Sale" className="mn_salePill" />}
      <ExtraRewardsPill rebate={rebate} label="Extra" />
      <div className="mn_productOfferInfo">
        <p className="mn_merchName">{name}</p>
        <p className="mn_buyPrice">{price}</p>
        <div className="mn_rebateWrap">
          <PremierMerchantRebate {...rebate} {...rebateOptions} />
        </div>
      </div>
      <div className="mn_actionLink">
        <MerchantClickUrlLink className="mn_button" merchant={merchant} offerClickUrl={clickUrl} />
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><HybridProductOffer {...props} /></ComponentErrorBoundary>;
