import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { personalizedTopOffers as orgConfig } from 'org/configs/modules.config';
import { PERSONALIZED_TOP_OFFERS_GET_SUCCESS, PERSONALIZED_TOP_OFFERS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    useAddToFavorite: true,
    getShouldUseFeaturedDealsRoundedView: () => true,
    sectionTitle: 'Earn with these great offers',
    getMotto: () => '',
    viewAllLinkText: 'See all deals',
    dateFormat: 'MMM DD, YYYY',
    api: {
      params: {
        limit: 12,
        sort_by: 'random',
        content_group_id: 15162,
        fields: [
          'assets',
          'clickUrl',
          'couponCode',
          'stopDatetime',
          'id',
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.id',
          'merchant.name',
          'merchant.tiers',
          'highlightedMerchantTierId',
        ],
      },
      options: {
        success: PERSONALIZED_TOP_OFFERS_GET_SUCCESS,
        failure: PERSONALIZED_TOP_OFFERS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    carousel: {
      hasDots: false,
      hasArrows: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
