import { getId } from 'core/utils/analytics/sectionInfo';
import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { merchantLogos as orgConfig } from 'org/configs/modules.config';
import { MERCHANT_LOGOS_GET_SUCCESS, MERCHANT_LOGOS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_group_id: 15954,
        content_type_id: 92,
        fields: [
          'assets._460x250',
          'merchant.name',
        ],
        limit: 8,
      },
      options: {
        success: MERCHANT_LOGOS_GET_SUCCESS,
        failure: MERCHANT_LOGOS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: getId('merchant-logos'),
      },
    },
    useCarouselOnMediaQueriesOnly: [],
    carousel: {
      hasDots: false,
      loop: true,
    },
  },
  h: {
    useCarouselOnMediaQueriesOnly: [],
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
