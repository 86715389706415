import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { modal as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    options: {
      shouldShowCloseButton: true,
      shouldCloseOnClickOutside: true,
      shouldCloseOnEscapeKeydown: true,
      shouldUpdateUrl: false,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
