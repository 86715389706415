import React from 'react';
import { useSelector } from 'react-redux';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { selectBrandDisplayName } from 'core/selectors/app';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import config from './configs';
import orgConfig from 'org/configs/org.config';

import './DntMobileWarning.scss';

export function DntMobileWarning({
  children, getText, shouldShowDntMobileWarning, name,
}) {
  const isMobileMQ = useSelector(selectIsMobileMQ);
  const brandDisplayName = useSelector(selectBrandDisplayName);
  const { shortProgramName } = orgConfig;
  const { iconClassName } = config;
  const programNameToShow = shortProgramName || brandDisplayName;
  const textToShow = getText(programNameToShow, name);

  if (!isMobileMQ || !shouldShowDntMobileWarning) {
    return children;
  }

  return (
    <div className="mn_dntMobileWarningWrap">
      {children}
      <div className="mn_dntMobileWarningContent">
        <i aria-hidden="true" className={iconClassName} />
        <div className="mn_dntMobileWarningText">{textToShow}</div>
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><DntMobileWarning {...props} /></ComponentErrorBoundary>;
