import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { fetchPlacements } from 'core/actions/ocapi';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';

import {
  selectDeletedMemberModalConfig, selectDeletedMemberModalIsLoaded,
  selectDeletedMemberModalIsLoading, selectDeletedMemberModalInfo,
} from './selectors';

import { clearDeletedMemberData } from './actions';
import './DeletedMemberModal.scss';

function DeletedMemberModal() {
  const dispatch = useDispatch();

  const config = useSelector(selectDeletedMemberModalConfig);
  const isLoading = useSelector(selectDeletedMemberModalIsLoaded);
  const isLoaded = useSelector(selectDeletedMemberModalIsLoading);
  const memberDeleteInfo = useSelector(selectDeletedMemberModalInfo);
  const { api: { params, options }, modalOptions } = config;

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      dispatch(fetchPlacements(params, options));
    }

    return () => clearDeletedMemberData();
  }, []);

  if (isEmpty(memberDeleteInfo)) {
    return null;
  }

  return (
    <Modal className="mn_deletedMemberModal" options={modalOptions}>
      <h2 id="mn_dialogTitle" className="mn_sr-only">Dialog with deleted member information</h2>
      <div
        className="mn_memberDeleteInfo"
        id="mn_dialogDescription"
        dangerouslySetInnerHTML={{ __html: memberDeleteInfo.termsAndConditions }}
      />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><DeletedMemberModal {...props} /></ComponentErrorBoundary>;
