import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from 'core/modules/Modal/ModalProvider';
import EmailOptInSuccessModalView from './components/EmailOptInSuccessModalView';
import { triggerEmailOptInModalSuccessIsViewed } from './actions';
import './EmailOptInSuccessModal.scss';
import config from './configs';

function EmailOptInSuccessModal() {
  const dispatch = useDispatch();
  const { closeModal } = useModal();
  const [autoCloseId, setAutoCloseId] = useState(null);

  const { autoCloseTimeout = 0 } = config;

  useLayoutEffect(() => {
    if (autoCloseTimeout > 0) {
      const autoCloseId = setTimeout(() => {
        closeModal();
      }, autoCloseTimeout);

      setAutoCloseId(autoCloseId);
    }

    return () => {
      clearTimeout(autoCloseId);
    };
  }, []);

  useEffect(() => {
    dispatch(triggerEmailOptInModalSuccessIsViewed());
  }, []);

  return <EmailOptInSuccessModalView {...config} />;
}

export default EmailOptInSuccessModal;
