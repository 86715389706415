import { createSelector } from 'reselect';
import { selectActiveCurrentDate } from 'core/selectors/env';
import dayjs from 'core/utils/dayjs';
import { isTimeEnded } from 'core/utils/helpers/date';
import { addCommas, convertDollarAmountToNumber } from 'core/utils/helpers/numbers';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const selectBonusOffers = state => state.bonusOffers;
export const selectBonusOffersConfig = createSelector(
  selectBonusOffers,
  bonusOffers => bonusOffers.config,
);

export const selectBonusOffersIsLoading = createSelector(
  selectBonusOffers,
  bonusOffers => bonusOffers.isLoading,
);

export const selectBonusOffersIsLoaded = createSelector(
  selectBonusOffers,
  bonusOffers => bonusOffers.isLoaded,
);

export const selectBonusOffersPlacement = createSelector(
  selectBonusOffers,
  bonusOffers => bonusOffers.bonus,
);

export const selectPendingBonusOffersPlacement = createSelector(
  selectBonusOffers,
  bonusOffers => bonusOffers.pendingBonus,
);

export const selectBonusOffersNotQualifiedAndActive = createSelector(
  [selectBonusOffersPlacement, selectActiveCurrentDate],
  (bonus, currentDate) => bonus.filter((bonusOfferItem) => {
    const qualifyingSpendTiers = get(bonusOfferItem, 'offers[0].qualifyingSpendTiers[0]') || {};
    const merchant = get(bonusOfferItem, 'offers[0].qualifyingMerchants.merchants[0]') || {};
    const merchantOffer = merchant.featuredOffers && merchant.featuredOffers[0];
    const bonusPackage = bonusOfferItem.package;

    return (
      !qualifyingSpendTiers.isQualified
      && !isTimeEnded(bonusOfferItem.endDate, currentDate)
      && (merchantOffer ? !isTimeEnded(merchantOffer.stopDatetime, currentDate) : merchant.rebate)
      && bonusPackage !== 'b'
    );
  }),
);

export const selectBonusOffersNotQualifiedAndActiveFormatted = createSelector(
  selectBonusOffersNotQualifiedAndActive,
  bonusOfferList => bonusOfferList.map((bonusOfferItem) => {
    const qualifyingSpendTiers = get(bonusOfferItem, 'offers[0].qualifyingSpendTiers[0]') || {};
    const { reward, brandCurrency } = qualifyingSpendTiers;
    const merchant = get(bonusOfferItem, 'offers[0].qualifyingMerchants.merchants[0]') || {};
    const offerTitle = get(merchant, 'featuredOffers[0].offerTitle');
    const shortEndDate = dayjs.parseZone(bonusOfferItem.endDate).tz('America/New_York').format('M/DD');
    const showDollarSign = brandCurrency === 'cash back' ? '$' : '';

    return {
      bonusId: bonusOfferItem.id,
      heading: `Earn ${showDollarSign}${addCommas(reward)} bonus ${brandCurrency}`,
      description: offerTitle !== '' && offerTitle,
      merchant,
      bonusTag: bonusOfferItem.type === 'public' ? 'Limited-time bonus' : 'Limited-time bonus for you',
      endDate: shortEndDate,
      rebate: merchant.rebate,
    };
  }),
);

const selectMerchantExperience = state => state.merchantExperience;

const selectMerchantId = createSelector(
  selectMerchantExperience,
  merchantExperience => get(merchantExperience, 'merchants[0].id', null),
);

export const selectActiveMerchantBonusOffer = createSelector(
  [selectBonusOffersPlacement, selectActiveCurrentDate, selectMerchantId],
  (bonusOffers, currentDate, gmid) => (
    bonusOffers && bonusOffers.filter((bonusOfferItem) => {
      const merchant = get(bonusOfferItem, 'offers[0].qualifyingMerchants.merchants[0]', {});
      return (
        !isEmpty(merchant)
        && parseInt(gmid, 10) === merchant.globalMerchantId
        && !isTimeEnded(bonusOfferItem.endDate, currentDate)
      );
    })[0]
  ),
);

export const selectMerchantBonusOffersToDisplay = createSelector(
  selectActiveMerchantBonusOffer,
  (bonusOffer) => {
    if (!bonusOffer) return null;

    const offer = get(bonusOffer, 'offers[0]', {});
    const qualifyingMerchant = get(bonusOffer, 'offers[0].qualifyingMerchants.merchants[0]', {});
    const spendTier = get(offer, 'qualifyingSpendTiers[0]', {});
    const featuredOffer = get(qualifyingMerchant, 'featuredOffers[0]', {});
    const endDate = dayjs.parseZone(bonusOffer.endDate).format('MMM DD, YYYY');

    const { id, type } = bonusOffer;
    const { minimumSpend: total = '$0', reward, brandCurrency } = spendTier;
    const { clickUrl, offerDetails } = featuredOffer;
    const { totalAmountSpent: spent = '$0', terms } = offer;
    const { name, clickUrl: merchantClickUrl } = qualifyingMerchant;
    const spentWithoutDollar = convertDollarAmountToNumber(spent);
    const totalWithoutDollar = convertDollarAmountToNumber(total);
    const rewardInfo = `${reward} bonus ${brandCurrency}`;
    const hasReachedTotal = spentWithoutDollar >= totalWithoutDollar;

    return {
      id,
      merchantName: name,
      bonusTag: type === 'public' ? 'Limited-time bonus' : 'Limited-time bonus for you',
      clickUrl: clickUrl || merchantClickUrl,
      offerDetails,
      bonusEndInfo: hasReachedTotal ? `Bonus ends ${endDate}` : `Hurry, bonus ends ${endDate}`,
      spent,
      total,
      reward,
      brandCurrency,
      type,
      terms,
      rewardInfo,
      heading: hasReachedTotal ?
        `You earned ${addCommas(rewardInfo)} by spending ${total} at ${name}` :
        `Earn ${addCommas(rewardInfo)} when you spend ${total} or more at ${name}`,
      hasReachedTotal,
      buttonTitle: spentWithoutDollar === 0 ? 'Shop the bonus' : 'Keep shopping',
    };
  },
);

export const selectMerchantFeaturedBonusOffer = createSelector(
  [selectActiveMerchantBonusOffer, selectActiveCurrentDate],
  (bonusOffer, currentDate) => {
    if (!bonusOffer) return null;

    const qualifyingMerchant = get(bonusOffer, 'offers[0].qualifyingMerchants.merchants[0]', {});
    const featuredOffer = get(qualifyingMerchant, 'featuredOffers[0]', {});

    if (isEmpty(featuredOffer)) return null;
    const endDate = dayjs.parseZone(featuredOffer.stopDatetime).format('MMM DD, YYYY');
    if (isTimeEnded(endDate, currentDate)) return null;

    const {
      clickUrl, couponCode: code, offerDetails, offerTitle, disclaimer,
    } = featuredOffer;

    // Shouldn't render offer in case both title and description are missed
    if (!offerDetails && !offerTitle) return null;

    return {
      id: bonusOffer.id,
      clickUrl,
      code,
      description: offerDetails || offerTitle,
      disclaimer,
      isFeatured: true,
      expires: endDate,
      merchant: qualifyingMerchant,
    };
  },
);

export const selectBonusTrackerOffer = createSelector(
  [selectBonusOffersPlacement],
  (bonusOffers) => (
    bonusOffers.find((item) => (
      !item?.offers?.[0]?.qualifyingMerchants?.merchants?.[0]
    ))
  ),
);

// all bonus rolling offers
export const selectBonusRollingOffers = createSelector(
  [selectBonusOffersPlacement], (bonusOffers) => (
    bonusOffers.filter(({ type }) => type === 'rolling')
  ),
);

// bonus rolling offers sorted based on max possible reward
// (are shown in MHP rolling bonus banner)
export const selectBonusRollingOffersToShow = createSelector(
  [selectBonusRollingOffers], (bonusOffers) => (
    bonusOffers.sort((aBonus, bBonus) => {
      const aBonusMaxReward = Number(aBonus.offers[0].maxRewardAvailable);
      const bBonusMaxReward = Number(bBonus.offers[0].maxRewardAvailable);

      return aBonusMaxReward > bBonusMaxReward ? -1 : 1;
    })
  ),
);

// bonus rolling offer with max possible reward but user doesn't reached spend threshold
// (is shown in bonus rolling overlay)
export const selectBonusRollingOfferToShow = createSelector(
  [selectBonusRollingOffersToShow], (bonusOffers) => (
    bonusOffers.filter((bonus) => {
      const offer = bonus.offers[0];
      const { minSpendToQualify, totalAmountSpent } = offer;
      const min = convertDollarAmountToNumber(minSpendToQualify);
      const total = convertDollarAmountToNumber(totalAmountSpent);

      return min > total;
    })[0] || {}
  ),
);
