import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCookieDomain, selectIsHttps } from 'core/selectors/env';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';
import dayjs from 'core/utils/dayjs';
import { getEmailPopupCookie, setEmailPopupCookie } from 'core/utils/helpers/emailPopupCookie';
import EmailOptInSubscribeForm from 'core/modules/Forms/EmailOptInSubscribeForm';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import Modal from 'core/modules/Modal/Modal';
import { useModal } from 'core/modules/Modal/ModalProvider';
import { postEmailOptInUserChoice } from '../actions';
import {
  EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED,
  EMAIL_OPT_IN_MODAL_USER_CHOICE_NO,
  EMAIL_OPT_IN_MODAL_USER_CHOICE_YES,
} from '../constants';

const emailPopupCookie = getEmailPopupCookie();

function EmailOptInSubscribeModalView({
  userChoice, earnType, showEmailInput, dialogText, imageBeforeHeader,
  cancelText, useImageView, isUserChoiceSubmitted, submitButtonText,
  useSeparateHeadingsView, ...props
}) {
  const cookieDomain = useSelector(selectCookieDomain);
  const isHttps = useSelector(selectIsHttps);
  const dispatch = useDispatch();
  const { closeModal } = useModal();

  const handleEmailOptinClick = (optedIn, isUndecided = false) => {
    emailPopupCookie.seenCount += 1;
    emailPopupCookie.showPopup = false;
    emailPopupCookie.lastSeen = dayjs();
    emailPopupCookie.hasSeen = true;

    if (!isUndecided) {
      emailPopupCookie.emailGlobalOptOut = optedIn ? '0' : '1';
    }

    setEmailPopupCookie({ emailPopupCookie, cookieDomain, isHttps });
  };

  const setUserChoiceLater = () => {
    // Prevent "changing your mind" after the initial choice
    if (userChoice === EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED) {
      dispatch(postEmailOptInUserChoice(EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED));
      handleEmailOptinClick(false, false);
    }

    closeModal();
  };

  const setUserChoiceNo = (e) => {
    e.preventDefault();
    // Prevent "changing your mind" after the initial choice
    if (userChoice === EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED) {
      dispatch(postEmailOptInUserChoice(EMAIL_OPT_IN_MODAL_USER_CHOICE_NO));
      handleEmailOptinClick(false);
    }

    closeModal();
  };

  const setUserChoiceYes = (e) => {
    e.preventDefault();
    dispatch(postEmailOptInUserChoice(EMAIL_OPT_IN_MODAL_USER_CHOICE_YES));
    handleEmailOptinClick(true);
  };

  // Check if we should render the form
  const shouldRenderForm = userChoice === EMAIL_OPT_IN_MODAL_USER_CHOICE_YES && isUserChoiceSubmitted;

  const renderImageView = () => (
    <>
      <ShowOnMQ mobile>
        <span id="mn_dialogTitle">Be in the know with weekly emails.</span>
      </ShowOnMQ>
      <ShowOnMQ tablet desktop>
        <div className="mn_onboardingOptin">
          <div className="mn_optInImage" />
          <h2 id="mn_dialogDescription">Get the best deals right in your inbox</h2>
          <p>Don't hunt for sales or extra miles offers. We'll email them to you.</p>
        </div>
      </ShowOnMQ>
    </>
  );

  const renderSeparateHeadingsView = () => (
    <>
      <h2><span className="mn_checkIcon" />Deals</h2>
      <h2><span className="mn_checkIcon" />Personalized offers</h2>
      <h2><span className="mn_checkIcon" />Extra rewards</h2>
      <div className="mn_comingSoon">Coming soon</div>
    </>
  );

  return (
    <Modal className="mn_emailOptInSubscribeModal" onCloseCallback={setUserChoiceLater}>
      {imageBeforeHeader && <div className="mn_optInImageBeforeHeader" /> }
      <div id="mn_dialogTitle"><SectionTitle title={`Deals. Sales. Extra ${earnType}.`} /></div>
      {useSeparateHeadingsView && renderSeparateHeadingsView()}
      {useImageView ? renderImageView() : <span>{dialogText}</span>}
      {shouldRenderForm && <EmailOptInSubscribeForm {...props} />}

      <div className="mn_controls">
        {showEmailInput && ''} {/* Show and validate email input here. Not required for United */}
        <button
          className="mn_button mn_emailOptInSubmit"
          onClick={setUserChoiceYes}
        >
          {submitButtonText}
        </button>

        <button className="mn_emailOptInCancel" onClick={setUserChoiceNo}>
          <span className="mn_buttonLabel">
            {cancelText}
            <span className="mn_buttonLabelIcon fa-angle-right" />
          </span>
        </button>
      </div>
    </Modal>
  );
}

export default (props) =>
  <ComponentErrorBoundary><EmailOptInSubscribeModalView {...props} /></ComponentErrorBoundary>;
