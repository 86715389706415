import { getEmailPopupCookie } from 'core/utils/helpers/emailPopupCookie';
import {
  EMAIL_OPT_IN_MODAL_SUBSCRIBE_VIEW,
} from 'core/modules/Modals/EmailOptInSubscribeModal/actions/types';

export const emailOptInSubscribeModalGaActionEvents = {
  [EMAIL_OPT_IN_MODAL_SUBSCRIBE_VIEW]: () => {
    const { seenCount = 0 } = getEmailPopupCookie();

    return {
      category: 'Email Opt In',
      action: 'Email Opt In Prompt Display',
      label: `Email Opt In Prompt Display|${seenCount + 1}`,
    };
  },
};

export const emailOptInSubscribeModalClickAndHoverEvents = [
  {
    selector: '.mn_emailOptInSubmit',
    click: {
      category: 'Email Opt In',
      action: 'Email Opt In Yes Click',
    },
    label: (element) => {
      const { seenCount = 0 } = getEmailPopupCookie();
      return `${element.innerText}|${seenCount + 1}`;
    },
  },
  {
    selector: '.mn_emailOptInCancel',
    click: {
      category: 'Email Opt In',
      action: 'Email Opt In No Thanks Click',
    },
    label: (element) => {
      const { seenCount = 0 } = getEmailPopupCookie();
      return `${element.innerText}|${seenCount + 1}`;
    },
  },
  {
    selector: '.mn_emailOptInSubscribeModal .mn_closeModal',
    click: {
      category: 'Email Opt In',
      action: 'Email Opt In Prompt Closed',
    },
    label: () => {
      const { seenCount = 0 } = getEmailPopupCookie();
      return `Email Opt In Prompt Closed|${seenCount + 1}`;
    },
  },
];
