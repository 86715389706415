import { SUCCESS_SUFFIX, FAILURE_SUFFIX } from 'core/actions/apis';

const rootActionPath = 'core/InquiryOrder';

export const INQUIRY_ORDER_SELECTED_MERCHANT_DATA_UPDATE = `${rootActionPath}/selectedMerchantData/UPDATE`;
export const INQUIRY_ORDER_SELECTED_MERCHANT_DATA_CLEAR = `${rootActionPath}/selectedMerchantData/CLEAR`;

export const INQUIRY_ORDER_TRANSACTIONS_GET = `${rootActionPath}/transactions/GET`;
export const INQUIRY_ORDER_TRANSACTIONS_GET_SUCCESS = `${rootActionPath}/transactions/GET_${SUCCESS_SUFFIX}`;
export const INQUIRY_ORDER_TRANSACTIONS_GET_FAILURE = `${rootActionPath}/transactions/GET_${FAILURE_SUFFIX}`;

export const INQUIRY_ORDER_CLICK_HISTORY_GET = `${rootActionPath}/clickHistory/GET`;
export const INQUIRY_ORDER_CLICK_HISTORY_GET_SUCCESS = `${rootActionPath}/clickHistory/GET_${SUCCESS_SUFFIX}`;
export const INQUIRY_ORDER_CLICK_HISTORY_GET_FAILURE = `${rootActionPath}/clickHistory/GET_${FAILURE_SUFFIX}`;
