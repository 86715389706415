import { getEmailPopupCookie } from 'core/utils/helpers/emailPopupCookie';
import { EMAIL_OPT_IN_MODAL_SUCCESS_VIEW } from 'core/modules/Modals/EmailOptInSuccessModal/actions/types';

export const emailOptInSuccessModalGaActionEvents = {
  [EMAIL_OPT_IN_MODAL_SUCCESS_VIEW]: () => {
    const { seenCount = 0 } = getEmailPopupCookie();

    return {
      category: 'Email Opt In',
      action: 'Email Success View',
      label: `Email Success View|${seenCount + 1}`,
    };
  },
};

export const emailOptInSuccessModalClickAndHoverEvents = [
  {
    selector: '.mn_emailOptInSuccessModal .mn_closeModal',
    click: {
      category: 'Email Opt In',
      action: 'Email Success Close',
    },
    label: () => {
      const { seenCount = 0 } = getEmailPopupCookie();

      return `Email Success Close|${seenCount + 1}`;
    },
  },
];
