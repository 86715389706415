/**
 * Cut string in case it longer then n characters and add ellipses instead
 * @param {string} str
 * @param {number} n
 * @return {string}
 */
export const cutStringToNCharacters = (str, n) =>
  (str && str.length > n ? `${str.substring(0, n)}&hellip;` : str);

/**
 * Replaces all character from a string
 * @param {string} value
 * @param {string} searchValue
 * @param {string} replaceValue
 * @returns {string}
 */
export const replaceAllCharacterFromString = (value, searchValue, replaceValue) =>
  value.replaceAll(searchValue, replaceValue);

/**
 * Capitalize first letter from a string
 * @param {string} value
 * @returns {string}
 */
export const capitalizeFirstLetter = value => value.charAt(0).toUpperCase() + value.slice(1);
