import React, { useState } from 'react';
import { cutStringToNCharacters } from 'core/utils/helpers/string';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

function HybridProductDescription({ description }) {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const shortDescription = cutStringToNCharacters(description, 200);
  const descriptionToShow = isDescriptionExpanded ? description : shortDescription;

  return (
    <div className="mn_productDesription" id="mn_dialogDescription">
      <p className="mn_descriptionTxtContainer">
        {description.length >= 200 ?
          <>
            <span
              className="mn_descriptTxt mn_togglableDescription"
              aria-live="assertive"
              dangerouslySetInnerHTML={{ __html: descriptionToShow }}
            />
            <button
              className="mn_moreDetails"
              onClick={toggleDescription}
            >
              {isDescriptionExpanded ? 'See less' : 'See more'}
            </button>
          </>
          : <span className="mn_descriptTxt">{description}</span>
        }
      </p>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><HybridProductDescription {...props} /></ComponentErrorBoundary>;
