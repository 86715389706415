import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import orgConfig from 'org/configs/org.config';
import { convertDateTimeToETTimezone } from 'core/utils/helpers/date';
import { isFirefox } from 'core/utils/helpers/browser';
import { addCommas } from 'core/utils/helpers/numbers';
import { selectCookieDomain, selectIsHttps } from 'core/selectors/env';
import { selectStoreCount } from 'core/selectors/app';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { useModal } from 'core/modules/Modal/ModalProvider';
import Countdown from 'core/modules/Countdown/Countdown';
import { useGetBonusOffers } from 'core/modules/BonusOffers/hooks';
import { useTriggerBonusRollingModalGAEvents } from './hooks';
import { BONUS_ROLLING_MODAL_WAS_SHOWN, BONUS_ROLLING_DISPLAY_HIW } from './utils/keys';
import config from './configs';

import './BonusRollingModal.scss';

function BonusRollingModal() {
  const { closeModal } = useModal();
  const [shouldShowTerms, setShouldShowTerms] = useState(false);
  const storeCount = useSelector(selectStoreCount);
  const toggleTerms = (isOpen) => setShouldShowTerms(isOpen);
  const { bonusRollingOfferToShow } = useGetBonusOffers();
  const cookieDomain = useSelector(selectCookieDomain);
  const isHttps = useSelector(selectIsHttps);
  const { earnType } = orgConfig;
  const { getClientName, dateFormat, shortDateFormat } = config;

  const { onOverlayClickCloseGAEvent } =
    useTriggerBonusRollingModalGAEvents(bonusRollingOfferToShow);

  useEffect(() => {
    Cookies.set(BONUS_ROLLING_MODAL_WAS_SHOWN, 'true', { path: '/', domain: cookieDomain, secure: isHttps });
  }, []);

  if (isEmpty(bonusRollingOfferToShow)) {
    return null;
  }

  const {
    endDate, offers, name, rollingInfo,
  } = bonusRollingOfferToShow;
  const { messagingType } = rollingInfo;
  const { terms, minSpendToQualify, maxRewardAvailable } = offers[0];
  const endDateETTimeZone = convertDateTimeToETTimezone(endDate);
  const formattedFullEndDate = endDateETTimeZone.format(dateFormat);
  const formattedShortEndDate = endDateETTimeZone.format(shortDateFormat);
  const clientName = getClientName();
  const shouldShowHiwSection = messagingType === BONUS_ROLLING_DISPLAY_HIW;

  const onClickOutsideCallback = (event) => {
    onOverlayClickCloseGAEvent(event);
  };

  return (
    <Modal
      className="mn_bonusRollingModal"
      onClickOutsideCallback={onClickOutsideCallback}
    >
      <div className="mn_titleWrap">
        <div className="mn_title" id="mn_dialogTitle">
          <span>{name} ends in</span> <Countdown endDate={endDate} />
        </div>
      </div>
      <div className="mn_info">
        <h2 id="mn_dialogDescription">
          Earn {addCommas(maxRewardAvailable)} {clientName} bonus {earnType} when
          you spend {addCommas(minSpendToQualify)} or more by {formattedFullEndDate}.
        </h2>
        <button className="mn_button" onClick={closeModal}>Start shopping</button>
        { shouldShowTerms ?
          <div className="mn_termsWrap">
            <button className="mn_closeTerms" aria-label="Close terms" onClick={() => toggleTerms(false)}>
              &#x2715;
            </button>
            <div className={`mn_termsInfo ${isFirefox ? 'mn_firefox' : ''}`}>
              <h3>Bonus terms and conditions</h3>
              <div className="mn_termsText" dangerouslySetInnerHTML={{ __html: terms }} />
            </div>
          </div> :
          <button className="mn_termsButton" onClick={() => toggleTerms(true)}>View bonus terms and conditions</button>
        }
      </div>
      {
        shouldShowHiwSection &&
        <div className="mn_hiw">
          <h3>How to earn the bonus:</h3>
          <div className="mn_hiwItems">
            <div className="mn_hiwItem">
              <div className="mn_number">1<span>.</span></div>
              <div className="mn_text">
                Explore {addCommas(storeCount)}+ stores, exclusive offers, and more through {formattedShortEndDate}.
              </div>
            </div>
            <div className="mn_hiwItem">
              <div className="mn_number">2<span>.</span></div>
              <div className="mn_text">
                Click on any store and you'll be taken to the store's website.
                Shop like you normally do and pay with any credit card.
              </div>
            </div>
            <div className="mn_hiwItem">
              <div className="mn_number">3<span>.</span></div>
              <div className="mn_text">
                Spend {addCommas(minSpendToQualify)}+ and {addCommas(maxRewardAvailable)} bonus {earnType} will be added
                to your {clientName} account after the bonus period ends.
              </div>
            </div>
          </div>
        </div>
      }
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><BonusRollingModal {...props} /></ComponentErrorBoundary>;
