// This hook returns value that changes from `false` to `true` after the `delay` is over
import { useEffect, useState } from 'react';

export const useTimeoutIsOver = (delay) => {
  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => { setIsOver(true); }, delay);
    return () => { clearTimeout(handler); };
  }, []);

  return isOver;
};
