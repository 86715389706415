import { PRODUCT_DETAILS_MODAL_CLEAR, PRODUCT_DETAILS_MODAL_VIEW } from './types';

export const clearProductDetailsData = () => ({
  type: PRODUCT_DETAILS_MODAL_CLEAR,
});

export const triggerProductDetailsOverlayIsViewed = (productName) => ({
  type: PRODUCT_DETAILS_MODAL_VIEW,
  productName,
});
