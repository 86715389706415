import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { bonusOffersFlexPage as orgConfig } from 'org/configs/modules.config';

/** @type {object} */
const defaultConfig = {
  defaults: {
    rebateOptions: {
      additionalRebatePrefix: 'Plus,',
      prefix: 'earn',
      tiered: {
        additionalRebatePrefix: 'Plus,',
      },
    },
    seeDetailPrefix: null,
    useAddToFavorite: true,
    usePeriod: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
