import { findAncestorByClassName } from 'core/utils/helpers/dom';
import { getPageName } from 'core/utils/analytics/pageInfo';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import { HYBRID_STORES_OFFER_MODAL_VIEW } from '../actions/types';

export const hybridStoresOfferModalGaActionEvents = {
  [HYBRID_STORES_OFFER_MODAL_VIEW]: (action) => {
    const { merchantName } = action;
    const { keywords } = getParamsFromSearch(window.location.search);
    const pageName = getPageName() === 'msr' ? 'MMS' : 'HSR';

    return {
      category: 'Online and In-Store Overlay',
      action: 'Online and In-Store Overlay view',
      label: `${merchantName} | ${keywords} | ${pageName}`,
      value: 1,
    };
  },
};

export const hybridStoresOfferModalClickAndHoverEvents = [
  {
    selector: '.mn_hybridStoresOfferModal .mn_merchantClickUrlLink',
    click: {
      category: 'Online and In-Store Overlay',
      action: 'Online click',
    },
    label: (element) => {
      const { merchantName } = element.dataset || {};
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${merchantName} | ${keywords}`;
    },
    value: 1,
  },
  {
    selector: '.mn_hybridStoresOfferModal .mn_instoreOffer',
    click: {
      category: 'Online and In-Store Overlay',
      action: 'In-store click',
    },
    label: (element) => {
      const hybridStoresOffer = findAncestorByClassName(element, 'mn_hybridStoresOfferModal');
      const onlineOfferCTA = hybridStoresOffer.getElementsByClassName('mn_merchantClickUrlLink')[0] || {};
      const { merchantName } = onlineOfferCTA.dataset || {};
      const { keywords } = getParamsFromSearch(window.location.search);

      return `${merchantName} | ${keywords}`;
    },
    value: 1,
  },
  {
    selector: '.mn_hybridStoresOfferModal .mn_closeModal',
    click: {
      category: 'Online and In-Store Overlay',
      action: 'Close',
    },
    label: (element) => {
      const hybridStoresOffer = findAncestorByClassName(element, 'mn_hybridStoresOfferModal');
      const onlineOfferCTA = hybridStoresOffer.getElementsByClassName('mn_merchantClickUrlLink')[0] || {};
      const { merchantName } = onlineOfferCTA.dataset || {};
      const { keywords } = getParamsFromSearch(window.location.search);
      return `${merchantName} | ${keywords}`;
    },
    value: 1,
  },
];
