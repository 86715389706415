import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { giftGuide as orgConfig } from 'org/configs/modules.config';
import {
  GIFT_GUIDE_HEADLINE_GET_SUCCESS,
  GIFT_GUIDE_HEADLINE_GET_FAILURE,
  GIFT_GUIDE_FEATURED_DEALS_GET_SUCCESS,
  GIFT_GUIDE_FEATURED_DEALS_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    moduleConfigName: 'giftGuide',
    giftGuideHeadline: {
      api: {
        params: {
          offer_tag: 'mhp-gift-guide-headline',
          limit: 1,
          fields: [
            'assets.shortSummaryText',
            'assets.callToActionText',
            'clickUrl',
          ],
        },
        options: {
          success: GIFT_GUIDE_HEADLINE_GET_SUCCESS,
          failure: GIFT_GUIDE_HEADLINE_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    giftGuideFeaturedDeals: {
      api: {
        params: {
          offer_tag: 'mhp-gift-guide-featured',
          limit: 8,
        },
        options: {
          success: GIFT_GUIDE_FEATURED_DEALS_GET_SUCCESS,
          failure: GIFT_GUIDE_FEATURED_DEALS_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
          sectionId: getId('featured-gift-guide'),
        },
      },
    },
    carousel: {
      hasDots: false,
      hasCounter: true,
      isClipped: true,
      breakpoints: {
        m: { isClipped: false },
      },
    },
    rebateOptions: {
      showRebateInline: true,
      hideElevationCurrency: true,
    },
    shouldTrackInitialParams: true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
