import React from 'react';
import { useSelector } from 'react-redux';
import FavoriteIcon from 'core/modules/FavoriteIcon/FavoriteIcon';
import MerchantRebate from 'core/modules/MerchantRebate/MerchantRebate';
import PremierMerchantRebate from 'core/modules/PremierMerchantRebate/PremierMerchantRebate';
import DntMobileWarning from 'core/modules/DntMobileWarning/DntMobileWarning';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import { reduceMatchesBasedOnDntMobile } from 'core/modules/HybridQuickSearch/utils/reduceMatchesBasedOnDntMobile';
import { highlightMatch } from '../../utils/highlightMatch';

export function OnlineMatches({
  localConfig, onlineMatches, keywords, localMerchantClickHandler, isPopUpView, instoreMatches, getTitle,
}) {
  const {
    showFavoriteIcon, directMerchantName, rebateOptions, isPremierRebate, getDntMobileWarningText,
  } = localConfig;

  const isMobileMQ = useSelector(selectIsMobileMQ);

  if (onlineMatches.length === 0) {
    return null;
  }

  // Pop up view: if in-store is included show a max of 2 stores
  const onlineMatchesToShow = (isPopUpView && instoreMatches.length) ? onlineMatches.slice(0, 2) : onlineMatches;
  const filteredMatchesToShow = isMobileMQ ? reduceMatchesBasedOnDntMobile(onlineMatchesToShow) : onlineMatchesToShow;

  return (
    <div className="mn_quickSearchItemsWrapper">
      {filteredMatchesToShow.map((match) => {
        const { merchant, matchText } = match;
        const {
          id, name, href, rebate, showRebate, isDeactivated, isDirect, flags = {},
        } = merchant;
        const highlighted = highlightMatch(name, matchText);
        const shouldShowDntMobileWarning = !(isDirect && directMerchantName) && !isDeactivated && !flags.tracksMobile;
        const deactivatedMerchantClassName = isDeactivated ? 'mn_deactivatedMatch' : '';
        return (
          <DntMobileWarning
            key={`${name}-online`}
            shouldShowDntMobileWarning={shouldShowDntMobileWarning}
            getText={getDntMobileWarningText}
          >
            <div className="mn_quickSearchItem">
              {showFavoriteIcon && <FavoriteIcon merchantId={id} merchantName={name} />}
              <a
                href={`${href}&keywords=${keywords}`}
                className={`mn_quickSearchMatch mn_onlineMatch ${deactivatedMerchantClassName}`}
                data-merchant-id={id}
                data-merchant-name={name}
                data-merchant-type="online"
                onClick={localMerchantClickHandler}
                title={getTitle(name)}
              >
                <div className="mn_quickSearchContent">
                  <span className="mn_matchText" key={id}>{highlighted}</span>
                  <span className="mn_dash">&nbsp;-&nbsp;</span>
                  {(showRebate || isDeactivated) &&
                    (isPremierRebate ?
                      <PremierMerchantRebate {...rebate} {...rebateOptions} /> :
                      <MerchantRebate {...rebate} {...rebateOptions} />
                    )
                  }
                </div>
                {isDirect && <span className="mn_directMerchantName">{directMerchantName}</span>}
              </a>
            </div>
          </DntMobileWarning>
        );
      })}
    </div>
  );
}

export default OnlineMatches;
