import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { trendingStores as orgConfig } from 'org/configs/modules.config';
import { TRENDING_STORES_GET_SUCCESS, TRENDING_STORES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    maxMerchants: 12,
    carousel: {
      hasCounter: true,
      hasDots: false,
      isClipped: true,
      breakpoints: {
        m: { hasCounter: false, hasDots: true, isClipped: false },
        l: { hasDots: false },
      },
    },
    carouselVhpOverwriting: {
    },
    getContentWrap: (isUserIdentified) => ({
      mobile: true,
      tablet: true,
      l: true,
      xl: true,
      xxl: !isUserIdentified,
    }),
    api: {
      params: {
        with_offers: 1,
        fields: [
          'logoUrls',
          'type',
          'name',
          'id',
          'clickUrl',
          'showRebate',
          'rebate',
          'offers',
        ],
      },
      options: {
        success: TRENDING_STORES_GET_SUCCESS,
        failure: TRENDING_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
