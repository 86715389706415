import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import DataTablePagination from './components/DataTablePagination';
import DataTableContent from './components/DataTableContent';

import './DataTable.scss';

function DataTable(props) {
  const { usePagination } = props;

  return (
    <ComponentErrorBoundary>
      <DataTableContent {...props} />
      {usePagination && <DataTablePagination {...props} />}
    </ComponentErrorBoundary>
  );
}

DataTable.defaultProps = {
  usePagination: true,
};

export default DataTable;
