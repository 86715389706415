import React from 'react';
import WistiaVideo from 'core/modules/WistiaVideo/WistiaVideo';
import Modal from 'core/modules/Modal/Modal';
import './HiwVideoModal.scss';

const YOUTUBE = 'youtube';
const VIDYARD = 'vidyard';

function HiwVideoModal({ modal }) {
  const {
    videoId, videoPlayer, videoTitle, videoAutoplay,
  } = modal.params;

  if (videoPlayer === YOUTUBE) {
    return (
      <Modal className="mn_hiwVideoModal">
        <h2 id="mn_dialogTitle" className="mn_sr-only">Dialog with how it works video</h2>
        <div className="mn_youtubeResponsivePadding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title={videoTitle}
            style={{
              height: '100%', left: '0', position: 'absolute', top: '0', width: '100%',
            }}
          />
        </div>
      </Modal>
    );
  }

  if (videoPlayer === VIDYARD) {
    return (
      <Modal className="mn_hiwVideoModal">
        <div className="mn_vidyardContainer">
          <div className="mn_vidyard">
            <div className="mn_vidyardInnerContainer">
              <iframe
                className="mn_vidyardPlayerEmbed"
                src={`https://play.vidyard.com/${videoId}`}
                data-uuid={videoId}
                data-v="4"
                data-type="inline"
                alt=""
                title={videoTitle}
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal className="mn_hiwVideoModal">
      <WistiaVideo videoId={videoId} autoPlay={`autoPlay=${videoAutoplay} silentAutoPlay=false`} />
    </Modal>
  );
}

export default HiwVideoModal;
