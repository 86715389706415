import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import HybridProductOfferList from './HybridProductOffersList';
import HybridProductDescription from './HybridProductDescription';

function ProductDetailsModalContent({ product }) {
  const {
    mainImage, name, merchantCount, id, description, offers, brandName, modelNo,
  } = product;

  return (
    <div className="mn_productDetailsContentWrapper">
      <div className="mn_mainContent">
        <img src={mainImage} alt={name} />
        <div className="mn_productInfo">
          <div id="mn_dialogTitle" className="mn_sr-only">
            Dialog with product details for {name}
          </div>
          <h2 className="mn_productName">{name}</h2>
          <p className="mn_compare">Compare at {merchantCount} stores</p>
          <ul>
            {id && <li>UPC: {id}</li>}
            {modelNo && <li>Model number: {modelNo}</li>}
            {brandName && <li>Brand: {brandName}</li>}
          </ul>
          <HybridProductDescription description={description} />
        </div>
      </div>
      <div className="mn_productDataTable">
        <HybridProductOfferList offers={offers} />
      </div>
    </div>
  );
}

export default (props) => <ComponentErrorBoundary><ProductDetailsModalContent {...props} /></ComponentErrorBoundary>;
