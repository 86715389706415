import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { emailOptInSubscribeModal as orgConfig } from 'org/configs/modules.config';

export const defaultConfig = {
  defaults: {
    cancelText: 'No thanks. I don\'t like earning extra points',
    earnType: 'points',
    dialogText: 'Be in the know with weekly emails.',
    form: 'EmailPopup',
    formAction: 'auth.php',
    submitButtonText: 'Sign up to receive emails',
    securityToken: null,
    showEmailInput: false,
    useImageView: false,
    imageBeforeHeader: false,
    useSeparateHeadingsView: false,
    emailOptions: {
      // 'undefined' properties just for highliting existing options which could be overridden in org config
      email_news_optin: undefined,
      email_odotw_optin: undefined,
      email_deals_optin: undefined,
      email_solo_optin: undefined,
      email_promo_optin: undefined,
      email_discovery_optin: undefined,
      email_local_optin: undefined,
      email_global_optout: 0,
    },
    checkIsDisabled: () => false,
  },
  // skip rendering if we on forbidden page (invalid credentials, email unsubscribe) - default for all malls
  eu: {
    checkIsDisabled: () => true,
  },
  ic: {
    checkIsDisabled: () => true,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
