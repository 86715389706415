import React from 'react';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import DataTable from 'core/modules/DataTable/DataTable';
import HybridProductOffer from './HybridProductOffer';

function HybridProductOffersList({ offers }) {
  const columns = [
    { Header: 'Store', accessor: 'Store', className: 'mn_merchName' },
    { Header: 'Price', accessor: 'Price', className: 'mn_buyPrice' },
    { Header: 'Reward', accessor: 'Reward', className: 'mn_rebTxt' },
    {
      Header: <span className="mn_sr-only">Shop Now Links</span>,
      accessor: 'Action Links',
      className: 'mn_actionLink',
    },
  ];

  return (
    <DataTable columns={columns} data={offers} usePagination={false}>
      { offers.map(offer => (
        <HybridProductOffer offer={offer} key={offer.id} />
      ))}
    </DataTable>
  );
}

export default (props) => <ComponentErrorBoundary><HybridProductOffersList {...props} /></ComponentErrorBoundary>;
