import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import dayjs from 'core/utils/dayjs';
import { addLeadingZero } from 'core/utils/apis/responseParsers/commonParsers/formatDateParser';

import { selectCountdownStartDateTime } from './selectors';

import './Countdown.scss';

function Countdown({
  currentDate,
  endDate,
  onComplete,
  donotUseLeadingZeroInDays,
}) {
  const updatedCurrentDate = useSelector(state => selectCountdownStartDateTime(state, currentDate));
  let interval = null;
  const currentDateRef = useRef(updatedCurrentDate);
  const endDateRef = useRef(endDate);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  /**
   * We need to be able to handle a timer with > 30 days difference so
   * we are using the asDays() method instead of days() to represent this
   * portion of the countdown
   * @param {object} duration
   * @return {number}
   */
  const getTimerDays = duration => (
    parseInt(duration.asDays(), 10)
  );

  const updateTimer = (duration) => {
    setDays(donotUseLeadingZeroInDays ? getTimerDays(duration) : addLeadingZero(getTimerDays(duration)));
    setHours(addLeadingZero(duration.hours()));
    setMinutes(addLeadingZero(duration.minutes()));
    setSeconds(addLeadingZero(duration.seconds()));
  };

  const initTimer = () => {
    let duration = dayjs.duration(endDateRef.current.diff(currentDateRef.current));
    updateTimer(duration);

    interval = setInterval(() => {
      if (duration.asSeconds() <= 0) {
        clearInterval(interval);
        onComplete();
        return;
      }
      updateTimer(duration);
      duration = duration.subtract(1, 'seconds');
    }, 1000);
  };

  useEffect(() => {
    currentDateRef.current = dayjs(currentDateRef.current);
    endDateRef.current = dayjs(endDateRef.current);

    if (currentDateRef.current.isValid() && endDateRef.current.isValid()) {
      initTimer();
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span className="mn_countdown" data-test="countdown">
      <span className="mn_days">{days}</span>
      <span className="mn_hours">{hours}</span>
      <span className="mn_minutes">{minutes}</span>
      <span className="mn_seconds">{seconds}</span>
    </span>
  );
}

Countdown.propTypes = {
  currentDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  onComplete: PropTypes.func,
  donotUseLeadingZeroInDays: PropTypes.bool,
};

Countdown.defaultProps = {
  currentDate: dayjs().format(),
  onComplete: () => {},
  donotUseLeadingZeroInDays: false,
};

export default Countdown;
