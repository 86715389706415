/* global dyHandler */
import { detectIncognitoMode } from 'core/utils/helpers/browser';

window.dyHandler = window.dyHandler || {};

dyHandler.isDesktopStickyFooterSuspended =
  typeof dyHandler.isDesktopStickyFooterSuspended === 'undefined' ? false : dyHandler.isDesktopStickyFooterSuspended;

dyHandler.isOnboardingSuspended =
  typeof dyHandler.isOnboardingSuspended === 'undefined' ? false : dyHandler.isOnboardingSuspended;

dyHandler.isEmailOptInPopupSuspended =
  typeof dyHandler.isEmailOptInPopupSuspended === 'undefined' ? false : dyHandler.isEmailOptInPopupSuspended;

dyHandler.shouldForceShowNewMembersView =
  typeof dyHandler.shouldForceShowNewMembersView === 'undefined' ? false : dyHandler.shouldForceShowNewMembersView;

dyHandler.shouldShowPersonalizedView =
  typeof dyHandler.shouldShowPersonalizedView === 'undefined' ? false : dyHandler.shouldShowPersonalizedView;

dyHandler.detectIncognitoMode = detectIncognitoMode;

export default window.dyHandler;
