import React from 'react';
import mergeModuleConfigs, { mergeConfigs } from 'core/utils/mergeModuleConfigs';
import { getId } from 'core/utils/analytics/sectionInfo';
import { topStores as orgConfig } from 'org/configs/modules.config';
import { CACHE_STORAGE } from 'core/utils/cache/base';
import { TOP_STORES_GET_SUCCESS, TOP_STORES_GET_FAILURE } from '../actions/types';

export const defaultConfig = {
  defaults: {
    api: {
      params: {
        content_type_id: 55,
        with_offers: 0,
        sort_by: 'random',
        fields: [
          'merchant.logoUrls',
          'merchant.showRebate',
          'merchant.rebate',
          'merchant.id',
          'merchant.name',
          'merchant.clickUrl',
        ].join(','),
      },
      loggedInParams: {},
      loggedOutParams: {},
      options: {
        success: TOP_STORES_GET_SUCCESS,
        failure: TOP_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true, type: CACHE_STORAGE },
        sectionId: getId('top-merchants'),
      },
    },
    secTitle: 'Top stores',
    secTitlePill: undefined,
    useAddToFavorite: true,
    getViewAllLinkText: () => (<>View all stores</>),
    viewAllLinkCTAText: 'See all offers',
    getUseSmallerIcon: (isMobileMQ) => !isMobileMQ,
    getShouldClientUseExpandableView: () => false,
    maxMerchants: 10,
    tagHelper: () => {},
    getViewAllLinkUrl: (siteUrl) => `${siteUrl}/sp____.htm`,
    checkShouldUseCarousel: () => true,
    shouldTrackInitialParams: false,
  },
  h: {
    getShouldClientUseExpandableView: (isUserIdentified, isMobileMQ) => (isUserIdentified && isMobileMQ),
    checkShouldUseCarousel: (activeMQ) => ['M', 'L', 'XL', 'XXL'].includes(activeMQ),
    minMerchantsExpanadbleView: 4,
    maxMerchantsExpanadbleView: 10,
    shouldTrackInitialParams: true,
    api: {
      params: {
        content_group_id: null,
      },
      loggedInParams: {
        offer_tag: 'top-stores',
      },
      options: {
        sectionId: getId('store-tiles'),
      },
    },
    carousel: {
      isInverted: true,
      isClipped: true,
      breakpoints: {
        m: { isClipped: false },
        l: { hasDots: false, hasArrows: true },
      },
    },
  },
};

export const mergedConfig = mergeConfigs(defaultConfig, orgConfig);

export default mergeModuleConfigs(defaultConfig, orgConfig);
