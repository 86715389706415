import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { searchModal as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    placeholderLanguage: 'Find a store or product',
    shouldUseHybridSearch: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
