import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import orgConfig from 'org/configs/org.config';

import { selectExtraRewardsPage, selectSiteUrl } from 'core/selectors/app';

import MerchantTile from 'core/modules/MerchantTile/MerchantTile';
import OfferOverlayLink from 'core/modules/OfferOverlayLink/OfferOverlayLink';
import { useGetPlacements } from './hooks';

import './NavSeasonalFlyout.scss';

import { selectNavSeasonalFlyoutConfig } from './selectors';
import { selectNavItems } from '../Nav/selectors';

function NavSeasonalFlyout() {
  const placements = useGetPlacements();
  const config = useSelector(selectNavSeasonalFlyoutConfig);
  const siteUrl = useSelector(selectSiteUrl);
  const extraRewardsPage = useSelector(selectExtraRewardsPage);
  const navigationItems = useSelector(selectNavItems);
  const { getTitleText, getLinkTitle } = config;
  const { sectionId } = config.api.options;

  const { earnType } = orgConfig;

  const getExtraRewardsPageTitle = () => {
    if (extraRewardsPage === '' || isEmpty(navigationItems)) {
      return '';
    }
    const extraRewardsNavItem = navigationItems.filter(({ url }) => url.includes(extraRewardsPage))[0];

    return extraRewardsNavItem ? extraRewardsNavItem.title.toLowerCase() : '';
  };

  const extraRewardsPageTitle = getExtraRewardsPageTitle();
  const titleText = getTitleText(earnType);
  const linkTitle = getLinkTitle(extraRewardsPageTitle);

  if (isEmpty(placements)) {
    return null;
  }

  return (
    <div className="mn_navSeasonalFlyoutSection">
      <h2>{titleText}</h2>
      <div className="mn_seasonalFlyout">
        <div className="mn_seasonalFlyoutContainer">
          {placements.map(({ id, merchant }) => (
            <OfferOverlayLink
              key={id}
              merchantId={merchant.id}
              merchantName={merchant.name}
              offerId={id}
              sectionId={sectionId}
            >
              <MerchantTile
                label={config.tileLabel}
                merchant={merchant}
                useSmallerIcon
              />
            </OfferOverlayLink>
          ))}
        </div>
      </div>
      <div className="mn_seasonalFlyoutCTAWrap">
        <a
          className="mn_link"
          href={`${siteUrl}${extraRewardsPage}?sectionId=${sectionId}&isource=${config.isource}`}
        >
          {linkTitle}
        </a>
      </div>
    </div>
  );
}

export default NavSeasonalFlyout;
