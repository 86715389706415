import React from 'react';
import PropTypes from 'prop-types';

import ClickLink from 'core/modules/ClickLink/ClickLink';
import MerchantShopNowLinkLabel from 'core/modules/MerchantShopNowLinkLabel/MerchantShopNowLinkLabel';

function MerchantClickUrlLink({
  merchant, offerClickUrl, children, className, defaultLabel, ...otherProps
}) {
  const { id, name, clickUrl } = merchant;

  const url = (offerClickUrl || clickUrl);

  return (
    <ClickLink
      className={`mn_merchantClickUrlLink ${className}`}
      url={url}
      title={`Opens a new window for ${name}`}
      data-merchant-id={id}
      data-merchant-name={name}
      target="_blank"
      {...otherProps}
    >
      {/*
        If the custom link label is passed as a component children then it will have a highest merge priority;
        if the custom link label is passed as a 'defaultLabel' prop then it will replace the defaut 'Shop now' label
        but it still could be overriden by the 'merchant.classification.ctaOverride' property value if set.
      */}
      {children || <MerchantShopNowLinkLabel merchant={merchant} defaultLabel={defaultLabel} />}
    </ClickLink>
  );
}

MerchantClickUrlLink.propTypes = {
  merchant: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  defaultLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

MerchantClickUrlLink.defaultProps = {
  className: '',
  defaultLabel: '',
  children: null,
};

export default MerchantClickUrlLink;
