import PropTypes from 'prop-types';
import get from 'lodash/get';

function MerchantShopNowLinkLabel({ merchant, defaultLabel }) {
  return get(merchant, 'classification.ctaOverride') || defaultLabel || 'Shop now';
}

MerchantShopNowLinkLabel.propTypes = {
  merchant: PropTypes.shape({}),
  defaultLabel: PropTypes.string,
};

MerchantShopNowLinkLabel.defaultProps = {
  merchant: {},
  defaultLabel: '',
};

export default MerchantShopNowLinkLabel;
