import {
  DELETED_MEMBER_GET_SUCCESS,
  DELETED_MEMBER_GET_FAILURE,
  DELETED_MEMBER_MODAL_CLEAR,
} from '../actions/types';

import { deletedMemberConfig as config } from '../configs';

const initialState = {
  config,
  memberDeleteInfo: null,
  isLoading: false,
  isLoaded: false,
};

const deletedMemberModal = (state = initialState, action) => {
  switch (action.type) {
    case DELETED_MEMBER_GET_SUCCESS: {
      const memberDeleteInfo = action.payload.response[0];

      return {
        ...state, memberDeleteInfo, isLoading: false, isLoaded: true,
      };
    }
    case DELETED_MEMBER_GET_FAILURE: {
      return { ...state, isLoading: false, isLoaded: true };
    }
    case DELETED_MEMBER_MODAL_CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};

export default deletedMemberModal;
