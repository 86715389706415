import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { dntMobileWarning as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    iconClassName: 'mn_dntMobileWarningIcon fa fa-exclamation',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
