import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl } from 'core/selectors/app';
import { encodeQueryString } from 'core/utils/helpers/url';
import { filterDataAttributeProps } from 'core/utils/helpers/dom';

function MerchantExperienceLink({
  merchant, shouldIncludeKeywords, className, title, target, children, urlHash, trackingParams = {}, ...others
}) {
  const siteUrl = useSelector(selectSiteUrl);
  const { id, globalMerchantId, name } = merchant;
  const dataAtributes = filterDataAttributeProps(others);

  const queryString = encodeQueryString({
    keywords: shouldIncludeKeywords ? name : undefined,
    gmid: id || globalMerchantId,
    ...trackingParams,
  });

  return (
    <a
      className={`mn_merchantExperienceLink ${className}`}
      href={`${siteUrl}/me____.htm?${queryString}${urlHash ? `#${urlHash}` : ''}`}
      title={title || (target === '_blank' ? `Opens a new window at ${name}` : name)}
      target={target}
      data-merchant-id={id}
      data-merchant-name={name}
      {...dataAtributes}
    >
      {children}
    </a>
  );
}

MerchantExperienceLink.defaultProps = {
  target: null,
  urlHash: '',
  className: '',
};

export default MerchantExperienceLink;
