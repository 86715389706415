import { createSelector } from 'reselect';

export const selectFavorites = state => (state.favorites || {});
export const selectHasFavorited = createSelector(selectFavorites, favorites => favorites.hasFavorited || false);
export const selectIsFirstFavorite = createSelector(selectFavorites, favorites => favorites.isFirstFavorite || false);
export const selectIsForceSkipFirstFavoritesPopup =
  createSelector(selectFavorites, favorites => favorites.forceSkipFirstFavoritesPopup);
export const selectFavoritesIsProcessed = createSelector(selectFavorites, favorites => favorites.isProcessed || false);
export const selectAddFavoritesOrderQueue =
  createSelector(selectFavorites, favorites => favorites.addFavoritesOrderQueue);
export const selectAllFavoritesOrderQueue =
  createSelector(selectFavorites, favorites => favorites.allFavoritesOrderQueue);
export const selectFavoriteMerchantIndex = createSelector(selectFavorites, favorites => favorites.merchantIndex || {});
export const selectFavoriteMerchants = createSelector(selectFavoriteMerchantIndex, (merchantIndex =>
  Object.keys(merchantIndex)
));
export const selectFavoriteMerchantsFavorited = createSelector(selectFavoriteMerchantIndex, (merchantIndex =>
  Object.keys(merchantIndex).filter(merchantId => !!merchantIndex[merchantId])
));
export const selectNumFavoriteMerchants = createSelector(selectFavoriteMerchants, merchants => merchants.length);

export const selectFavoriteByMerchantId = (state, merchantId) => {
  const merchantIndex = selectFavoriteMerchantIndex(state);
  return !!merchantIndex[merchantId];
};

/**
 * The reason we are exporting a createSelector "maker" is that, when creating a selector
 * based on dynamic props values, you need a selector per instance in order for them to
 * properly memoize.  So this approach improves caching performance
 * @return {Function}
 */
export const makeSelectFavoriteByMerchantId = () => (
  createSelector(selectFavoriteByMerchantId, isFavorited => isFavorited)
);
