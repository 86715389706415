import { useEffect } from 'react';
import keyCodes from 'core/utils/helpers/keyCodes';

export const useOnEscapeKeydown = (ref, handler) => {
  const handleKeyDown = (event) => {
    const pressedKey = event.keyCode;
    if (pressedKey === keyCodes.ESCAPE) {
      handler(event);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);
};
