import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { triggerBonusRollingModalIsViewed, triggerOnOverlayClickCloseGAEvent } from '../actions';

export const useTriggerBonusRollingModalGAEvents = (bonusRollingOfferToShow) => {
  const dispatch = useDispatch();

  const onOverlayClickCloseGAEvent = () => {
    dispatch(triggerOnOverlayClickCloseGAEvent());
  };

  useEffect(() => {
    dispatch(triggerBonusRollingModalIsViewed(bonusRollingOfferToShow));
  }, []);

  return {
    onOverlayClickCloseGAEvent,
  };
};
