import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'core/modules/Modal/ModalProvider';
import { selectIsFirstFavorite, selectIsForceSkipFirstFavoritesPopup } from 'core/selectors/favorites';

export const useHandleFirstFavoriteModal = () => {
  const { openModal } = useModal();
  const isFirstFavorite = useSelector(selectIsFirstFavorite);
  const isForceSkipFirstFavoritesPopup = useSelector(selectIsForceSkipFirstFavoritesPopup);

  const handleFirstFavoriteModal = () => {
    if (isFirstFavorite && !isForceSkipFirstFavoritesPopup) {
      openModal({ name: 'mt_firstFavorite' });
    }
  };

  useEffect(() => {
    handleFirstFavoriteModal();
  }, [isFirstFavorite]);

  return handleFirstFavoriteModal;
};
