import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { dyEvents } from 'core/utils/analytics/dyAnalytics';
import { trackInsiderKeywordSearchEvent } from 'core/utils/analytics/insiderAnalytics';
import keyCodes from 'core/utils/helpers/keyCodes';
import {
  selectSearchFormKeywords, selectSearchFormRedirectUrl, selectSearchFormRedirectType,
  selectSearchFormDestinationMerchants, selectSearchFormConfig,
} from 'core/modules/SearchForm/selectors';
import { selectHasNoRewardsEnabled } from 'core/selectors/app';
import { selectGspConfig } from 'core/selectors/services';
import { useHandleKeyboardNavigation } from 'core/hooks/useHandleKeyboardNavigation';
import QuickSearch from 'core/modules/QuickSearch/QuickSearch';
import RecentStores from 'core/modules/RecentStores/RecentStores';
import SimilarStores from 'core/modules/SimilarStores/SimilarStores';

import {
  selectQuickSearchMatches, selectQuickSearchIsLoaded, selectExactQuickSearchMatchSimilarStores,
  selectExactQuickSearchMatchName,
} from 'core/modules/QuickSearch/selectors';

import {
  searchSubmit, triggerSearchRedirect, triggerSearchActivation, triggerQuickSearchRedirect,
} from './actions';

import './SearchForm.scss';

function SearchForm({
  searchBoxFormType, placeholder, showQuickSearch,
  getFocusWasRemoved, isPopUpView, searchFormRef, hasFocusOnInit,
}) {
  const searchFormInputRef = useRef();
  const removeFocusTimeout = useRef();
  const quickSearchRef = useRef();
  const formRef = useRef();

  const keywords = useSelector(selectSearchFormKeywords);
  const gspConfig = useSelector(selectGspConfig);
  const { searchButtonClassName, shouldShowResultLinkOutside, tagHelper } = useSelector(selectSearchFormConfig);
  const quickSearchMatches = useSelector(
    state => selectQuickSearchMatches(state, keywords, { shouldUseGsp: gspConfig.isEnabled }),
  );
  const quickSearchIsLoaded = useSelector(state => selectQuickSearchIsLoaded(state, keywords));
  const quickSearchExactMatchSimilarStores = useSelector(state =>
    selectExactQuickSearchMatchSimilarStores(state, keywords));
  const quickSearchExactMatchName = useSelector(state => selectExactQuickSearchMatchName(state, keywords));
  const searchFormRedirectUrl = useSelector(selectSearchFormRedirectUrl);
  const searchFormRedirectType = useSelector(selectSearchFormRedirectType);
  const searchFormDestinationMerchants = useSelector(selectSearchFormDestinationMerchants);
  const hasNoRewardsEnabled = useSelector(selectHasNoRewardsEnabled);

  const dispatch = useDispatch();

  const [hasFocus, setHasFocus] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setFocus = () => setHasFocus(true);

  const handleClickOutside = (event) => {
    if (hasFocus && !formRef.current?.contains(event.target)) {
      removeFocusTimeout.current = setTimeout(() => {
        setHasFocus(false);
        getFocusWasRemoved && getFocusWasRemoved();
      }, 50);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hasFocus]);

  useHandleKeyboardNavigation(quickSearchRef, '.mn_quickSearchMatch');

  const handleQuickSearchMerchantClick = ({ merchant, merchantType, redirectUrl }) => {
    if (!redirectUrl) {
      return false;
    }

    // handle Chase tagging implementation
    tagHelper('pre-defined', merchant?.name);

    dispatch(triggerQuickSearchRedirect({
      quickSearchMatches,
      destinationMerchant: {
        name: merchant && merchant.name,
        type: merchantType,
      },
      searchBoxFormType,
      keywords,
    }));

    window.location.href = redirectUrl;
    return false;
  };

  const getTitle = (merchantName, isInstore) => (
    isInstore ? `Opens in-store offer details for ${merchantName}` : `Opens merchant detail page for ${merchantName}`
  );

  const sendDyEvent = (merchantName = null) => {
    if (merchantName) {
      dyEvents.sendEvent(dyEvents.sendKeyWordSearchEvent, { keywords: merchantName });
      return;
    }

    if (keywords) {
      dyEvents.sendEvent(dyEvents.sendKeyWordSearchEvent, { keywords });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // handle Chase tagging implementation
    tagHelper('organic', keywords);

    if (keywords.length > 0) {
      dispatch(searchSubmit({
        keywords,
      }));
      sendDyEvent();
      trackInsiderKeywordSearchEvent(keywords);
      setIsSubmitted(true);
    }
  };

  const onKeyDownHandler = (e) => {
    const pressedKey = e.keyCode;
    const quickSearch = quickSearchRef.current;
    if (pressedKey === keyCodes.ARW_DOWN && quickSearch && quickSearch.querySelector('.mn_onlineMatch')) {
      e.preventDefault();
      quickSearch.querySelector('.mn_onlineMatch').focus();
    }
  };

  useEffect(() => () => removeFocusTimeout.current && clearTimeout(removeFocusTimeout.current), []);

  useEffect(() => {
    if (hasFocus) {
      dispatch(triggerSearchActivation({
        quickSearchMatches,
        searchBoxFormType,
        keywords,
      }));
    }
  }, [hasFocus]);

  useEffect(() => {
    if (searchFormRedirectUrl && quickSearchIsLoaded && isSubmitted) {
      dispatch(triggerSearchRedirect({
        searchFormRedirectType,
        quickSearchMatches,
        searchFormDestinationMerchants,
        searchBoxFormType,
        keywords,
        searchFormRedirectUrl,
      }));
      setIsSubmitted(false); // iPhone real device fix (CLPL-18048).
      window.location.href = searchFormRedirectUrl;
    }
  }, [searchFormRedirectUrl, quickSearchIsLoaded, isSubmitted]);

  // Focus search input, when pop up search is opened
  useEffect(() => {
    if (hasFocusOnInit && searchFormInputRef.current) {
      setTimeout(() => { searchFormInputRef.current.getRenderedComponent().focus(); }, 100);
    }
  }, []);

  const renderSimilarOrRecentStores = () => {
    if (!hasFocus && !isPopUpView) {
      return null;
    }

    if (hasNoRewardsEnabled && quickSearchExactMatchSimilarStores) {
      return (
        <SimilarStores
          merchants={quickSearchExactMatchSimilarStores}
          merchantMatchName={quickSearchExactMatchName}
          isPopUpView={isPopUpView}
        />
      );
    }

    return <RecentStores isPopUpView={isPopUpView} tagHelper={tagHelper} />;
  };

  const renderPopUpView = () => (
    <div className="mn_searchForm" ref={searchFormRef}>
      <div className="mn_dropDownWrap">
        {renderSimilarOrRecentStores()}
        {keywords.length > 0 && showQuickSearch &&
        <QuickSearch
          ref={quickSearchRef}
          keywords={keywords}
          searchBoxFormType={searchBoxFormType}
          merchantClickHandler={handleQuickSearchMerchantClick}
          sendDyEvent={sendDyEvent}
          shouldShowResultLinkOutside={shouldShowResultLinkOutside}
          isPopUpView
          getTitle={getTitle}
        />}
      </div>
      <form
        ref={formRef}
        action="/sr____.htm"
        onFocus={setFocus}
        onSubmit={handleSubmit}
        data-test="search-form"
      >
        <label htmlFor="mn_searchInput">
          <span className="mn_sr-only">{placeholder}</span>
          <Field
            className="mn_searchInput"
            id="mn_searchInput"
            name="keywords"
            component="input"
            type="text"
            placeholder={placeholder}
            required
            autoComplete="off"
            forwardRef
            ref={searchFormInputRef}
            onKeyDown={onKeyDownHandler}
          />
          <button className="mn_searchSubmit" type="submit" aria-label="Search">
            <i className={searchButtonClassName} aria-hidden="true" />
          </button>
        </label>
      </form>
    </div>
  );

  const renderDropDownView = () => (
    <form
      ref={formRef}
      className="mn_searchForm"
      action="/sr____.htm"
      onFocus={setFocus}
      onSubmit={handleSubmit}
      data-test="search-form"
    >
      <label htmlFor="mn_searchInput">
        <span className="mn_sr-only">{placeholder}</span>
        <Field
          className="mn_searchInput"
          id="mn_searchInput"
          name="keywords"
          component="input"
          type="text"
          placeholder={placeholder}
          required
          autoComplete="off"
          forwardRef
          ref={searchFormInputRef}
          onKeyDown={onKeyDownHandler}
        />
      </label>
      <button className="mn_searchSubmit" type="submit" aria-label="Search">
        <i className={searchButtonClassName} aria-hidden="true" />
      </button>
      <div className="mn_dropDownWrap">
        {keywords.length > 0 && showQuickSearch && hasFocus &&
        <QuickSearch
          ref={quickSearchRef}
          keywords={keywords}
          searchBoxFormType={searchBoxFormType}
          merchantClickHandler={handleQuickSearchMerchantClick}
          sendDyEvent={sendDyEvent}
          shouldShowResultLinkOutside={shouldShowResultLinkOutside}
          getTitle={getTitle}
        />}
        {renderSimilarOrRecentStores()}
      </div>
    </form>
  );

  return isPopUpView ? renderPopUpView() : renderDropDownView();
}

SearchForm.defaultProps = {
  showQuickSearch: true,
  placeholder: 'Search',
  searchBoxFormType: 'Searchbox-Header',
};

export default reduxForm({
  form: 'searchForm',
})(SearchForm);
