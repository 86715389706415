import { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { useTimeoutIsOver } from 'core/hooks/useTimeoutIsOver';
import config from '../configs';
import { BONUS_ROLLING_MODAL_WAS_SHOWN } from '../utils/keys';

export const useCheckIsEligibleToShow = () => {
  const bonusWasShownInThisSessionRef = useRef(Cookies.get(BONUS_ROLLING_MODAL_WAS_SHOWN));

  const isTimeoutOver = useTimeoutIsOver(2000);
  const [isSingleModal, setIsSingleModal] = useState(false);
  const { isPageEligibleToShowModal, isClientEligibleToShowModal } = config;

  useEffect(() => {
    // Add some delay to make sure other modals are not rendered on the page load
    if (isTimeoutOver) {
      const onboardingModalElement = document.getElementById('mn_onboarding');
      const hasOnboardingCongratulations =
        !!(Cookies.get('showOnboardingCongratulations') || document.getElementById('mn_onboardingCongrats'));
      const hasModal = document.documentElement.classList.contains('mn_modalOpen');

      setIsSingleModal(!onboardingModalElement && !hasOnboardingCongratulations && !hasModal);
    }
  }, [isTimeoutOver]);

  return (
    isClientEligibleToShowModal &&
    isPageEligibleToShowModal &&
    !bonusWasShownInThisSessionRef.current &&
    isSingleModal
  );
};
