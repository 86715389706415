import React from 'react';
import Modal from 'core/modules/Modal/Modal';
import SectionTitle from 'core/modules/SectionTitle/SectionTitle';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import './TermsAndConditionsModal.scss';

function TermsAndConditionsModal({ modal }) {
  const { title = 'Terms and conditions', terms } = modal.params;

  return (
    <Modal className="mn_termsAndConditionsModal">
      <div id="mn_dialogTitle"><SectionTitle title={title} /></div>
      <div className="mn_termsAndConditionsPopupWrap">
        <div id="mn_dialogDescription" className="mn_termsText" dangerouslySetInnerHTML={{ __html: terms }} />
      </div>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><TermsAndConditionsModal {...props} /></ComponentErrorBoundary>;
