import React from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl, selectFormSecurityToken } from 'core/selectors/app';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { useModal } from 'core/modules/Modal/ModalProvider';
import config from './configs';

import './FavoritesAlertModal.scss';

function FavoritesAlertModal() {
  const customClass = 'mn_favoriteAlertModal';
  const siteUrl = useSelector(selectSiteUrl);
  const securityToken = useSelector(selectFormSecurityToken);
  const { modalOptions } = config;
  const { closeModal } = useModal();

  return (
    <Modal className={customClass} options={modalOptions}>
      <div className="mn_favoritesAlertInnerWrap" />
      <div className="mn_favoritesAlertOverlayInnerWrap">
        <h2 id="mn_dialogTitle">Are you sure you no longer want to receive favorites alerts?</h2>
        <p id="mn_dialogDescription">
          To stop receiving email alerts about a specific store, you can remove it from your favorites.
        </p>
        <form action={`${siteUrl}/auth.php`} method="post" autoComplete="off">
          <input type="hidden" name="form" value="UpdateFavoriteAlerts" />
          <input type="hidden" name="cbUrl" value={`${siteUrl}/ai____.htm?act=1`} />
          <input type="hidden" name="fcbUrl" value={`${siteUrl}/ai____.htm`} />
          <input type="hidden" name="fs" value={securityToken} />
          <input type="hidden" name="email_favorites_optin" value={0} />
          <button type="submit" className="mn_button mn_secondaryButton">Yes, turn off alerts</button>
          <a href={`${siteUrl}/favorites____.htm`} className="mn_button" onClick={closeModal}>
            No, manage favorites
          </a>
        </form>
      </div>
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><FavoritesAlertModal {...props} /></ComponentErrorBoundary>;
