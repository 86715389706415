import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { favoriteStores as orgConfig } from 'org/configs/modules.config';
import { FAVORITE_STORES_GET_SUCCESS, FAVORITE_STORES_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        fields: [
          'logoUrls',
          'type',
          'name',
          'id',
          'clickUrl',
          'showRebate',
          'rebate',
        ],
      },
      options: {
        success: FAVORITE_STORES_GET_SUCCESS,
        failure: FAVORITE_STORES_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    getSecTitle: (isMobileMQ) => (isMobileMQ ? 'Shop your favorites' : 'Shop your favorite stores'),
    tagHelper: () => {},
    carousel: {
      hasCounter: true,
      hasDots: false,
      isClipped: true,
      breakpoints: {
        m: { hasCounter: false, hasDots: true, isClipped: false },
        l: { hasDots: false, hasArrows: true },
      },
    },
    manageFavoritesLanguage: 'Manage your favorite stores',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
