import {
  HYBRID_STORES_OFFER_MODAL_CLEAR,
  INSTORE_OFFERS_GET_SUCCESS,
  INSTORE_OFFERS_GET_FAILURE,
} from '../actions/types';

import { hybridStoresOffer as config } from '../configs';

const initialState = {
  config,
  clickData: {},
  isLoading: false,
  isLoaded: false,
  offers: [],
};

const hybridStoresOfferModal = (state = initialState, action) => {
  switch (action.type) {
    case HYBRID_STORES_OFFER_MODAL_CLEAR: {
      return initialState;
    }
    case INSTORE_OFFERS_GET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: action.payload.response,
      };
    }
    case INSTORE_OFFERS_GET_FAILURE: {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        offers: [],
      };
    }
    default: {
      const { options = {} } = action;

      if (options.success === INSTORE_OFFERS_GET_SUCCESS) {
        return {
          ...state,
          isLoading: true,
          isLoaded: false,
        };
      }
    }
      return state;
  }
};

export default hybridStoresOfferModal;
