import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { becauseYouShopped as orgConfig } from 'org/configs/modules.config';
import { BECAUSE_YOU_SHOPPED_GET_SUCCESS, BECAUSE_YOU_SHOPPED_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 8,
        with_offers: 1,
      },
      options: {
        success: BECAUSE_YOU_SHOPPED_GET_SUCCESS,
        failure: BECAUSE_YOU_SHOPPED_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
      },
    },
    carousel: {
      hasDots: false,
      hasCounter: true,
      isClipped: true,
    },
    sectionsToRender: 1,
  },
  foryou: {
    sectionsToRender: 2,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
