import { useSelector } from 'react-redux';
import { selectMediaQueries } from '../selectors';
import {
  MQ_XS, MQ_S, MQ_M, MQ_L, MQ_XL, MQ_XXL,
} from '../utils/mediaQueriesValues';

// Check if at least one media property is passed
const checkHasAnyMediaProps = (props) => {
  const propertiesToCheck = ['mobile', 'tablet', 'desktop', MQ_XS, MQ_S, MQ_M, MQ_L, MQ_XL, MQ_XXL];
  return propertiesToCheck.some(prop => Object.prototype.hasOwnProperty.call(props, prop.toLowerCase()));
};

const checkIfMatchMQ = (props, mediaQueries) => {
  const { mobile, tablet, desktop } = props;

  const {
    activeMQ, isMobileMQ, isTabletMQ, isDesktopMQ,
  } = mediaQueries;

  const isMatchingDeviceMQ = mobile && isMobileMQ || tablet && isTabletMQ || desktop && isDesktopMQ;

  const isMatchingWidthMQ = [MQ_XS, MQ_S, MQ_M, MQ_L, MQ_XL, MQ_XXL].some((widthMQ) => (
    props[widthMQ.toLowerCase()] && activeMQ === widthMQ
  ));

  const hasAnyMediaProps = checkHasAnyMediaProps(props);

  return isMatchingDeviceMQ || isMatchingWidthMQ || !hasAnyMediaProps;
};

export const useCheckIfMatchMQ = (props) => {
  const mediaQueries = useSelector(selectMediaQueries);
  const isMatchingMQ = checkIfMatchMQ(props, mediaQueries);

  return {
    isMatchingMQ,
  };
};
