import mergeModuleConfigs, { mergeConfigs } from 'core/utils/mergeModuleConfigs';
import { topStoresPersonalized as orgConfig } from 'org/configs/modules.config';
import { mergedConfig as defaultMergedConfig } from './defaultConfig';

const topStoresPersonalizedConfig = {
  h: {
    carousel: {
      isInverted: false,
    },
  },
};

export default mergeModuleConfigs(
  mergeConfigs(defaultMergedConfig, topStoresPersonalizedConfig),
  orgConfig,
);
