import ocapi from 'core/sagas/ocapi';
import member from 'core/sagas/member';
import bonus from 'core/sagas/bonus';
import server from 'core/sagas/server';
import gsp from 'core/sagas/gsp';
import merchantExperienceSaga from 'core/modules/MerchantExperience/sagas';
import appSaga from 'core/modules/App/sagas';
import inquiryOrderSaga from 'core/modules/ContactUs/Dashboard/components/InquiryOrder/sagas';
import recentStoresFlyoutSaga from 'core/modules/RecentStoresFlyout/sagas';

const coreSagas = {
  ocapi,
  member,
  bonus,
  server,
  gsp,
  merchantExperienceSaga,
  appSaga,
  inquiryOrderSaga,
  recentStoresFlyoutSaga,
};

// Exporting an object instead of an actual saga allows org-level overrides of core sagas
export default coreSagas;
