import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import throttle from 'lodash/throttle';
import { isMobile, isTablet, isDesktop } from 'core/utils/helpers/browser';
import { triggerMediaQueriesUpdate } from './actions';
import { useCheckIfMatchMQ } from './hooks/useCheckIfMatchMQ';

const checkIfMatchDevice = ({ mobile, tablet, desktop }) => (
  mobile && isMobile || tablet && isTablet || desktop && isDesktop
);

export function MediaQueries({ children }) {
  const dispatch = useDispatch();

  const determineWindowWidth = () => {
    dispatch(triggerMediaQueriesUpdate(window?.innerWidth));
  };

  useEffect(() => {
    determineWindowWidth();
  }, []);

  useEffect(() => {
    const throttledDetermineWindowWidth = throttle(determineWindowWidth, 100);

    window.addEventListener('resize', throttledDetermineWindowWidth);

    return () => {
      window.removeEventListener('resize', throttledDetermineWindowWidth);
    };
  }, []);

  return children || null;
}

export function ShowOnMQ(props) {
  const { isMatchingMQ } = useCheckIfMatchMQ(props);

  return isMatchingMQ ? props.children : null;
}

export function HideOnMQ(props) {
  const { isMatchingMQ } = useCheckIfMatchMQ(props);

  return isMatchingMQ ? null : props.children;
}

export function ShowOnDevice(props) {
  const isMatchingDevice = checkIfMatchDevice(props);

  return isMatchingDevice ? props.children : null;
}

export function HideOnDevice(props) {
  const isMatchingDevice = checkIfMatchDevice(props);

  return isMatchingDevice ? null : props.children;
}
