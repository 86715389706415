import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';

function EmailOptInSubscribeForm({
  siteUrl,
  securityToken,
  emailOptions,
  callback,
  form,
  cbUrl,
  fcbUrl,
  useAjax,
  customOnUploadProgress,
  formAction,
}) {
  const formRef = useRef();

  useEffect(() => {
    if (useAjax) {
      const data = {
        form: form || 'UpdateMember',
        fs: securityToken,
        ...emailOptions,
      };

      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url: `${siteUrl}/${formAction}?isAjax=true`,
        onUploadProgress: customOnUploadProgress,
      };

      axios(options).then(() => callback());
    } else {
      formRef.current.submit();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (useAjax) {
    return (null);
  }

  return (
    <form
      action={`${siteUrl}/${formAction}`}
      method="post"
      autoComplete="off"
      ref={formRef}
    >
      <input type="hidden" name="form" value={form} />
      <input type="hidden" name="cbUrl" value={cbUrl} />
      <input type="hidden" name="fcbUrl" value={fcbUrl} />
      <input type="hidden" name="fs" value={securityToken} />

      {Object.entries(emailOptions).map(([key, value]) => (
        <input key={key} type="hidden" name={key} value={value} />
      ))}
    </form>
  );
}

export default EmailOptInSubscribeForm;
