import merge from 'lodash/merge';
import parseClickUrl from './commonParsers/clickUrlParser';
import parseRebate from './commonParsers/rebateParser';
import makeImageUrlSecure from './commonParsers/makeImageUrlSecure';

const parseOffer = (offer, state, action) =>
  merge({}, offer, {
    clickUrl: parseClickUrl(offer.clickUrl, state, action),
    imageUrl: makeImageUrlSecure(offer.imageUrl, state),
    merchant: offer.merchant && {
      clickUrl: parseClickUrl(offer.merchant.clickUrl, state, action),
      rebate: parseRebate(offer.merchant.rebate, offer.merchant.tiers, state),
    },
  });

export default (response, state, action) => {
  response.map((product) => {
    if (product.message && product.message.indexOf('not found') > -1) {
      return product;
    }

    return merge(product, {
      clickUrl: parseClickUrl(product.clickUrl, state, action),
      images: product.images.map(imageUrl => makeImageUrlSecure(imageUrl, state)),
      mainImage: makeImageUrlSecure(product.images[0], state),
      offers: product.offers.map(offer => parseOffer(offer, state, action)),
      shortDescription: product.description.length > 280 ? product.description.substring(0, 280) : null,
    });
  });
};
