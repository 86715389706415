import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { letterboxTOF as orgConfig } from 'org/configs/modules.config';
import { getId } from 'core/utils/analytics/sectionInfo';
import {
  LETTERBOX_TOF_MERCHANT_GET_SUCCESS,
  LETTERBOX_TOF_MERCHANT_GET_FAILURE,
  LETTERBOX_TOF_MARKETING_GET_SUCCESS,
  LETTERBOX_TOF_MARKETING_GET_FAILURE,
} from '../actions/types';

const defaultConfig = {
  defaults: {
    showRebateTierName: true,
    shouldClientUseExtraPillTileView: true,
    dateFormat: 'MMM DD, YYYY',
    expiresPrefix: 'Valid through ',
    labelText: '',
    getCouponCodeLabelText: () => 'Use code: ',
    tagHelper: () => {},
    rebateOptions: {
      showAdditionalElevationPrefix: false,
      showRebateInline: true,
      hideElevationCurrency: true,
    },
    carousel: {
      hasDots: false,
      hasCounter: true,
      isInverted: true,
      isClipped: true,
      align: 'center',
      loop: true,
      playOnInit: true,
      delay: 5000,
      breakpoints: {
        '(min-width: 610px)': {
          isClipped: false,
          isInverted: false,
          hasArrows: true,
          hasDots: true,
          hasCounter: false,
          hasEndingLoopGap: false,
        },
      },
    },
    marketingContent: {
      isEnabled: false,
      api: {
        params: {
          content_group_id: 15142,
          content_type_id: [89, 90].join(','),
          sort_by: 'random',
          fields: [
            'id',
            'dealId',
            'assets',
            'clickUrl',
            'couponCode',
            'merchant',
            'disclaimer',
            'rebate',
            'contentItemTypeId',
            'highlightedMerchantTierId',
          ],
        },
        options: {
          success: LETTERBOX_TOF_MARKETING_GET_SUCCESS,
          failure: LETTERBOX_TOF_MARKETING_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
    },
    merchantContent: {
      api: {
        params: {
          content_group_id: 6454,
          content_type_id: 80,
          limit: 15,
          sort_by: 'random',
          fields: [
            'id',
            'dealId',
            'assets',
            'clickUrl',
            'couponCode',
            'merchant',
            'disclaimer',
            'rebate',
            'isOngoing',
            'stopDatetime',
            'highlightedMerchantTierId',
          ],
        },
        options: {
          success: LETTERBOX_TOF_MERCHANT_GET_SUCCESS,
          failure: LETTERBOX_TOF_MERCHANT_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
          sectionId: getId('hero-carousel'),
        },
      },
    },
  },
  h: {
    marketingContent: {
      isEnabled: true,
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
