import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { bonusRollingModal as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    isClientEligibleToShowModal: false,
    isPageEligibleToShowModal: true,
    getClientName: () => null,
    dateFormat: 'MM/DD/YYYY',
    shortDateFormat: 'MM/DD',
  },
  foryou: {
    isPageEligibleToShowModal: false,
  },
  faq: {
    isPageEligibleToShowModal: false,
  },
  contact: {
    isPageEligibleToShowModal: false,
  },
  terms: {
    isPageEligibleToShowModal: false,
  },
  privacy: {
    isPageEligibleToShowModal: false,
  },
  ai: {
    isPageEligibleToShowModal: false,
  },
  eu: {
    isPageEligibleToShowModal: false,
  },
  me: {
    isPageEligibleToShowModal: false,
  },
  click: {
    isPageEligibleToShowModal: false,
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
