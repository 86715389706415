import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const buildParamsString = (params) => {
  if (!params) return '';

  return Object.entries(params).reduce((result, param) => (
    `${result}&${param[0]}=${param[1]}`
  ), '');
};

export const buildModalHash = (modal) => {
  try {
    const modalTypeString = `modalType=${modal.name}`;
    const modalParamsString = buildParamsString(modal.params);
    return `#${modalTypeString}${modalParamsString}`;
  } catch (error) {
    console.error(`Can't parse data: ${error}`);
    return '';
  }
};

export const useHandleUrlUpdates = (modal, shouldUpdateUrl, wasTriggeredFromUrl) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (shouldUpdateUrl && !wasTriggeredFromUrl) {
      const modalHash = buildModalHash(modal);
      navigate(`${location.pathname}${location.search}${modalHash}`, { replace: true });
    }

    return () => {
      if (shouldUpdateUrl) {
        navigate(`${location.pathname}${location.search}`, { replace: true });
      }
    };
  }, [modal]);
};
