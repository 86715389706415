import { MERCHANT_OFFER_MODAL_CLEAR, MERCHANT_OFFER_VIEW } from './types';

export function clearMerchantOfferData() {
  return {
    type: MERCHANT_OFFER_MODAL_CLEAR,
  };
}

export const triggerMerchantOfferOverlayIsViewed = (merchantName, userIsIdentified, pageName) => ({
  type: MERCHANT_OFFER_VIEW,
  merchantName,
  userIsIdentified,
  pageName,
});
