import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { hybridProducts as orgConfig } from 'org/configs/modules.config';

import { HYBRID_PRODUCTS_GET_SUCCESS, HYBRID_PRODUCTS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        limit: 4,
        fields: [
          'id',
          'clickUrl',
          'images',
          'name',
          'merchantName',
          'price',
          'merchantRebates',
          'rebate',
          'hasTiered',
          'merchantCount',
          'salePrice',
          'onSale',
        ],
      },
      options: {
        success: HYBRID_PRODUCTS_GET_SUCCESS,
        failure: HYBRID_PRODUCTS_GET_FAILURE,
        cache: { isEnabled: true },
      },
    },
    carousel: {
      align: 'center',
      hasDots: false,
      hasCounter: true,
      isInverted: true,
      loop: true,
      isClipped: true,
      breakpoints: {
        m: {
          loop: false,
          hasEndingLoopGap: false,
          align: 'start',
          hasCounter: false,
        },
      },
    },
    rebateOptions: {
      hideElevationCurrency: true,
    },
    extraLabel: '',
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
