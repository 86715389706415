import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { fetchSearchProductsById } from 'core/actions/ocapi';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { clearProductDetailsData, triggerProductDetailsOverlayIsViewed } from './actions';

import ProductDetailsModalContent from './components/ProductDetailsModalContent';

import {
  selectProductDetailsModalConfig, selectProductDetailsModalProduct,
} from './selectors';

import './ProductDetailsModal.scss';

function ProductDetailsModal({ modal }) {
  const dispatch = useDispatch();

  const customClass = 'mn_productDetailsModal';

  const config = useSelector(selectProductDetailsModalConfig);
  const product = useSelector(selectProductDetailsModalProduct);

  const { productId } = modal.params;

  const { api: { params, options }, modalOptions } = config;

  useEffect(() => {
    if (!isEmpty(product)) {
      dispatch(triggerProductDetailsOverlayIsViewed(product.name));
    }
  }, [product]);

  useEffect(() => {
    dispatch(fetchSearchProductsById(productId, params, options));

    return () => {
      dispatch(clearProductDetailsData());
    };
  }, [productId]);

  if (isEmpty(product)) {
    return null;
  }

  return (
    <Modal productId={productId} className={customClass} options={modalOptions}>
      <ProductDetailsModalContent product={product} />
    </Modal>
  );
}

export default (props) => <ComponentErrorBoundary><ProductDetailsModal {...props} /></ComponentErrorBoundary>;
