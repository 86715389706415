/* eslint-disable quote-props */
const sectionInfo = {
  ' left-rail1-authenticated': { id: 10159 }, // ocapi tag table has this leading space on the left as well.
  'all-offers': { id: 10160 },
  'all-stores': { id: 10161 },
  'bonus-featured': { id: 10162 },
  'bonus-letterbox': { id: 10163 },
  'Browse-Top-Stores': { id: 10164 },
  'category-banner': { id: 10165 },
  'category-letterbox': { id: 10166 },
  'dotw': { id: 10167 },
  'free-shipping': { id: 10168 },
  'HIW-popular-questions': { id: 10170 },
  'Home-120x90': { id: 10171 },
  'home-featured': { id: 10172 },
  'home-hero': { id: 10173 },
  'home-letterbox': { id: 10174 },
  'home-logged-in-hero': { id: 10175 },
  'home-logged-out-primary': { id: 10176 },
  'home-logged-out-secondary': { id: 10177 },
  'Home-products': { id: 10178 },
  'home-top-stores': { id: 10179 },
  'info-bar': { id: 10180 },
  'left-rail1': { id: 10181 },
  'left-rail1-unauthenticated': { id: 10182 },
  'left-rail2': { id: 10183 },
  'merchant-logos': { id: 10482 },
  'marketing-footer-banner': { id: 10184 },
  'marketing-tiles': { id: 10185 },
  'new-stores': { id: 10186 },
  'right-rail': { id: 10187 },
  'splash-featured': { id: 10190 },
  'splash-hero': { id: 10191 },
  'splash-hero-authenticated': { id: 10192 },
  'splash-hero-unauthenticated': { id: 10193 },
  'spotlight-letterbox': { id: 10194 },
  'spotlight-products': { id: 10195 },
  'store-details-letterbox': { id: 10196 },
  'store-details-products': { id: 10197 },
  'sweeps-featured': { id: 10198 },
  'sweeps-sponsored-logo': { id: 10423 },
  'sweeps-cross-promo-banner': { id: 10424 },
  'sweeps-secondary-logo': { id: 10425 },
  'sweeps-hero-enter': { id: 10199 },
  'sweeps-hero-entered': { id: 10200 },
  'sweeps-hero-expired': { id: 10201 },
  'sweeps-hero-upcoming': { id: 10202 },
  'top-merchants': { id: 10203 },
  'trending-offers': { id: 10204 },
  'WiFi-featured': { id: 10205 },
  'WiFi-heo': { id: 10206 }, // this looks like a typo in ocapi tag name as well: it should probably be WiFi-hero
  'special-offers-list': { id: 10207 },
  'offer-overlay-details': { id: 10208 },
  'recommended': { id: 10210 },
  'favorites': { id: 10211 },
  'merchant-experience': { id: 10213 },
  'price-compare': { id: 10214 },
  'price-compare-highest-rebate': { id: 10215 },
  'price-compare-lowest-price': { id: 10216 },
  'category-merchant-list': { id: 10217 },
  'featured-120x90': { id: 10218 },
  'dynamic-simple-banner': { id: 10221 },
  'dynamic-sponsored-merchant': { id: 10222 },
  'dynamic-featured-offers-150x150': { id: 10223 },
  'dynamic-featured-offers-502x272': { id: 10224 },
  'dynamic-merchants': { id: 10225 },
  'category-nav': { id: 10232 },
  'nav': { id: 10233 },
  'store-related-merchants': { id: 10235 },
  'offers-table-placements': { id: 10236 },
  'product-details': { id: 10237 },
  'search-no-results-top-stores': { id: 10238 },
  'dynamic-featured-text-offers': { id: 10426 },
  'dynamic-featured-offers-120x90': { id: 9753 },
  'dynamic-offer-table': { id: 10207 },
  'dynamic-marketing-text': { id: 10428 },
  'category-featured': { id: 10431 },
  'category-nav-v2': { id: 10432 },
  'info-bar-v2': { id: 10433 },
  'nav-v2': { id: 10434 },
  'login-bar': { id: 10435 },
  'merchant-overlay-v2': { id: 10436 },
  'hiw-v2': { id: 10438 },
  'store-tiles': { id: 10440 },
  'store-tiles-bonus-miles': { id: 10442 },
  'dotw-v2': { id: 10441 },
  'seasonal-and-trending': { id: 10443 },
  'hero-carousel': { id: 10445 },
  'value-prop-video': { id: 10446 },
  'sponsored-stores': { id: 10449 },
  'bundles': { id: 10450 },
  'favorites-recommended': { id: 10447 },
  'featured-extra-rewards-deals': { id: 10456 },
  'featured-extra-rewards-stores': { id: 10458 },
  'sticky-footer': { id: 10455 },
  'trending-offers-v2': { id: 10452 },
  'seasonal-flyout': { id: 10454 },
  'featured-favorites': { id: 10460 },
  'card-message': { id: 10481 },
  'popular-offers-300x250': { 'id': 10480 },
  'featured-gift-guide': { id: 10503 },
};

export const getId = sectionName => sectionInfo[sectionName] && sectionInfo[sectionName].id;

export const getNameById = (id) => {
  let sectionName;

  Object.keys(sectionInfo).some((key) => {
    if (typeof sectionInfo[key] === 'object' && sectionInfo[key].id == id) { // eslint-disable-line eqeqeq
      sectionName = key;
      return true;
    }
    return false;
  });

  return sectionName;
};
