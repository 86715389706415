import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { OffersSection as orgConfig } from 'org/configs/modules.config';
import {
  INSTORE_LOCATION_OFFERS_GET_FAILURE,
  INSTORE_LOCATION_OFFERS_GET_SUCCESS,
} from '../actions/types';

/** @type {object} */
const defaultConfig = {
  defaults: {
    api: {
      options: {
        success: INSTORE_LOCATION_OFFERS_GET_SUCCESS,
        failure: INSTORE_LOCATION_OFFERS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: false },
      },
      params: {
        active: 1,
      },
    },
    carousel: {
      hasDots: false,
      hasArrows: true,
    },
    slick: {
      slidesToShow: 1,
      centerMode: false,
      arrows: true,
      dots: false,
      className: 'mn_instoreMapSlide',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.5,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 413,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 374,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
