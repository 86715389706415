import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { sweepsMerchantLogos as orgConfig } from 'org/configs/modules.config';
import { SWEEPS_MERCHANT_LOGOS_GET_SUCCESS, SWEEPS_MERCHANT_LOGOS_GET_FAILURE } from '../actions/types';

const defaultConfig = {
  defaults: {
    api: {
      params: {
        offer_tag: 'sweeps-merchant-module-logo',
        content_type_id: 58,
        limit: 12,
        fields: [
          'assets._120x60',
          'merchant.name',
        ],
      },
      options: {
        success: SWEEPS_MERCHANT_LOGOS_GET_SUCCESS,
        failure: SWEEPS_MERCHANT_LOGOS_GET_FAILURE,
        cache: {
          isEnabled: true,
          isPublishedData: true,
        },
      },
    },
    useCarouselOnMediaQueriesOnly: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    carousel: {
      loop: 'true',
      isClipped: true,
      align: () => 45,
      slidesToScroll: 1,
      breakpoints: {
        m: {
          hasDots: false,
          isClipped: false,
          align: 'start',
        },
      },
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
