import { selectUrlPrefix } from 'core/selectors/env';
import { selectClickUrlPrefix, selectPageId, selectPageName } from 'core/selectors/app';
import replaceQueryParam from 'core/utils/apis/responseParsers/commonParsers/replaceQueryParam';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import { isClickLink } from 'core/utils/helpers/url';
import orgConfig from 'org/configs/org.config';

const MERCHANT_EXPERIENCE_PAGE_NAME = 'me';

// Return the same url if there is no org-level url customization
const { updateClickUrlString = (string) => string } = orgConfig;

const clickUrlParser = (clickUrl, state, action) => {
  const envUrlPrefix = selectUrlPrefix(state);
  const clickUrlPrefix = selectClickUrlPrefix(state);
  const pageId = selectPageId(state);
  const pageName = selectPageName(state);

  const addClickUrlPageIdParam = url => {
    if (isClickLink(url)) {
      return replaceQueryParam(url, 'p', pageId);
    }
    return url;
  };

  const addClickUrlSectionIdParam = url => {
    if (isClickLink(url)) {
      const sectionId = action?.options?.sectionId;
      return sectionId ? replaceQueryParam(url, 's', sectionId) : url;
    }

    return url;
  };

  const addInitialClicklUrlParamsOnMePage = (url) => {
    if (pageName === MERCHANT_EXPERIENCE_PAGE_NAME && action?.options?.trackInitialParams) {
      const { pageId, sectionId } = getParamsFromSearch();
      /* eslint-disable no-param-reassign */
      url = pageId ? replaceQueryParam(url, 'p', pageId) : url;
      url = sectionId ? replaceQueryParam(url, 's', sectionId) : url;
      /* eslint-enable no-param-reassign */
    }

    return url;
  };

  const generateFullClickUrl = (url) => {
    /* eslint-disable no-param-reassign */
    url = addClickUrlPageIdParam(url);
    url = addClickUrlSectionIdParam(url);
    url = addInitialClicklUrlParamsOnMePage(url);
    url = updateClickUrlString(url, state);
    return url;
    /* eslint-enable no-param-reassign */
  };

  const fullClickUrl = clickUrl && generateFullClickUrl(clickUrl.trim());

  if (
    !(
      fullClickUrl &&
      fullClickUrl.length > 0 &&
      clickUrlPrefix &&
      clickUrlPrefix.length > 0 &&
      fullClickUrl.indexOf(clickUrlPrefix) !== -1
    )
  ) {
    return fullClickUrl;
  }

  const clickUrlWithoutPrefix = fullClickUrl.slice(
    fullClickUrl.indexOf(clickUrlPrefix) + clickUrlPrefix.length,
    fullClickUrl.length,
  );
  return `${window.location.protocol}//${envUrlPrefix}${clickUrlWithoutPrefix}`;
};

export default clickUrlParser;
