import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSiteUrl, selectFormSecurityToken } from 'core/selectors/app';
import { dyEvents } from 'core/utils/analytics/dyAnalytics';
import { trackInsiderEmailOptinEvent } from 'core/utils/analytics/insiderAnalytics';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import Modal from 'core/modules/Modal/Modal';
import { useModal } from 'core/modules/Modal/ModalProvider';
import {
  selectSubscriptions, selectEmailNewsOptin, selectEmailOdotwOptin,
  selectEmailSoloOptin, selectEmailGlobalOptout,
} from 'core/selectors/subscriptions';
import config from './configs';

import './EditEmailPreferencesModal.scss';

function EditEmailPreferencesModal() {
  const siteUrl = useSelector(selectSiteUrl);
  const securityToken = useSelector(selectFormSecurityToken);
  const emailNewsOptin = useSelector(selectEmailNewsOptin);
  const emailOdotwOptin = useSelector(selectEmailOdotwOptin);
  const emailSoloOptin = useSelector(selectEmailSoloOptin);
  const emailGlobalOptout = useSelector(selectEmailGlobalOptout);
  const subscriptions = useSelector(selectSubscriptions);
  const { closeModal } = useModal();
  const { modalOptions } = config;

  const [emailNewsOptinIsChecked, setEmailNewsOptinIsChecked] = useState(!!emailNewsOptin);
  const [emailOdotwOptinIsChecked, setEmailOdotwOptinIsChecked] = useState(!!emailOdotwOptin);
  const [emailSoloOptinIsChecked, setEmailSoloOptinIsChecked] = useState(!!emailSoloOptin);
  const [emailGlobalOptoutIsChecked, setEmailGlobalOptout] = useState(!!emailGlobalOptout);

  const customClass = 'mn_editEmailPreferencesModal';

  const EMAIL_UNSUBSCRIBE = 'unsubscribe';
  const EMAIL_OPTIN_SOLO = 'optinsolo';
  const EMAIL_OPTIN_NEWS = 'optinnews';
  const EMAIL_OPTIN_DOTW = 'optindotw';

  const handleCheckBox = (type) => {
    switch (type) {
      case EMAIL_UNSUBSCRIBE: {
        setEmailGlobalOptout(!emailGlobalOptoutIsChecked);
        setEmailNewsOptinIsChecked(false);
        setEmailOdotwOptinIsChecked(false);
        setEmailSoloOptinIsChecked(false);
        break;
      }
      case EMAIL_OPTIN_SOLO: {
        setEmailSoloOptinIsChecked(!emailSoloOptinIsChecked);
        setEmailGlobalOptout(false);
        break;
      }
      case EMAIL_OPTIN_NEWS: {
        setEmailNewsOptinIsChecked(!emailNewsOptinIsChecked);
        setEmailGlobalOptout(false);
        break;
      }
      case EMAIL_OPTIN_DOTW: {
        setEmailOdotwOptinIsChecked(!emailOdotwOptinIsChecked);
        setEmailGlobalOptout(false);
        break;
      }
      default: break;
    }
  };

  const sendEmailOptOutDyEvent = () => {
    const emailPopupCookie = {
      lastSeen: 'na',
      seenCount: 'na',
      emailGlobalOptOut: emailGlobalOptoutIsChecked ? '1' : '0',
    };

    dyEvents.sendEvent(dyEvents.sendEmailOptinEvent, { emailPopupCookie });
    trackInsiderEmailOptinEvent(emailPopupCookie);
  };

  return (
    <Modal className={customClass} options={modalOptions}>
      <div className="mn_editProfileInnerWrap" />
      <div className="mn_editProfileForm">
        <div className="mn_sectCont">
          <form action={`${siteUrl}/auth.php`} method="post" autoComplete="off" id="mn_reg" className="mn_form">
            <input type="hidden" name="form" value="UpdateMember" />
            <input type="hidden" name="cbUrl" value={`${siteUrl}/ai____.htm?act=1`} />
            <input type="hidden" name="fcbUrl" value={`${siteUrl}/ai____.htm`} />
            <input type="hidden" name="fs" value={securityToken} />

            {subscriptions && (
              <div className="editPreferencesSection">
                <div className="mn_edit">Edit</div>
                <h3 id="mn_dialogTitle">Email preferences</h3>
                <p id="mn_dialogDescription">
                  Never miss a mile. Opt into MileagePlus
                  Shopping<sup>SM</sup> emails to stay up-to-date on the latest offers and earnings opportunities.
                </p>
                <fieldset>
                  <ul>
                    <li>
                      <strong>Deal of the week</strong>
                      <div className="mn_checkbox">
                        <input
                          onChange={() => { handleCheckBox(EMAIL_OPTIN_DOTW); }}
                          type="checkbox"
                          name="email_odotw_optin"
                          value={1}
                          id="mn_optInDOTW"
                          checked={emailOdotwOptinIsChecked}
                        />
                        <label htmlFor="mn_optInDOTW">
                          <span className="mn_emailOptInText">
                            <p>
                              Weekly update with special deals from your favorite stores
                              and limited time bonus mileage earning opportunities.
                            </p>
                          </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <strong>Member news</strong>
                      <div className="mn_checkbox">
                        <input
                          onChange={() => { handleCheckBox(EMAIL_OPTIN_NEWS); }}
                          type="checkbox"
                          name="email_news_optin"
                          value={1}
                          id="mn_optInNews"
                          checked={emailNewsOptinIsChecked}
                        />
                        <label htmlFor="mn_optInNews">
                          <span className="mn_emailOptInText">
                            <p>
                              Weekly email featuring more ways to earn extra miles
                              from our most popular stores plus new arrivals.
                            </p>
                          </span>
                        </label>
                      </div>
                    </li>
                    <li>
                      <strong>Special offers</strong>
                      <div className="mn_checkbox">
                        <input
                          onChange={() => { handleCheckBox(EMAIL_OPTIN_SOLO); }}
                          type="checkbox"
                          name="email_solo_optin"
                          value={1}
                          id="mn_optInSolo"
                          checked={emailSoloOptinIsChecked}
                        />
                        <label htmlFor="mn_optInSolo">
                          <span className="mn_emailOptInText">
                            <p>
                              Occasional updates on extraordinary deals
                              and miles opportunities and/or tips to maximize your mileage earning.
                            </p>
                          </span>
                        </label>
                      </div>
                    </li>
                    <li className="mn_unsubscribeListItem">
                      <p>
                        <strong>
                          Do you want to stop receiving all marketing email from MileagePlus Shopping?
                        </strong>
                      </p>
                      <strong>Unsubscribe from all marketing email</strong>
                      <div className="mn_checkbox">
                        <input
                          onChange={() => { handleCheckBox(EMAIL_UNSUBSCRIBE); }}
                          type="checkbox"
                          name="email_global_optout"
                          value={1}
                          id="mn_optOut"
                          checked={emailGlobalOptoutIsChecked}
                        />
                        <label htmlFor="mn_optOut">
                          <span className="mn_emailOptInText">
                            <p>
                              This does not include email confirming miles you have earned,
                              other account related information, or alerts.
                            </p>
                          </span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </fieldset>
              </div>
            )}
            <div className="mn_formButtonsWrap">
              <input
                id="mn_emailPreferencesSave"
                type="submit"
                value="Save"
                className="mn_button"
                aria-label="Save email preferences"
                onClick={sendEmailOptOutDyEvent}
              />
              <button
                type="button"
                id="mn_emailPreferencesCancel"
                className="mn_button mn_secondaryButton"
                aria-label="Close dialog and do not save changes"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default (props) =>
  <ComponentErrorBoundary><EditEmailPreferencesModal {...props} /></ComponentErrorBoundary>;
