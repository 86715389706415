import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { productDetails as orgConfig } from 'org/configs/modules.config';
import { getId } from 'core/utils/analytics/sectionInfo';

import { PRODUCT_DETAILS_GET_SUCCESS, PRODUCT_DETAILS_GET_FAILURE } from '../actions/types';

export const defaultConfig = {
  defaults: {
    api: {
      params: {
        calculate_earnings: 1,
      },
      options: {
        success: PRODUCT_DETAILS_GET_SUCCESS,
        failure: PRODUCT_DETAILS_GET_FAILURE,
        cache: { isEnabled: true, isPublishedData: true },
        sectionId: getId('product-details'),
      },
    },
    carousel: {
      hasDots: false,
    },
    rebateOptions: {
      showRebateInline: true,
      hideRebatePrefix: true,
      hideElevationCurrency: true,
    },
    sortParams: {
      sortBy: 'earnRate',
      sortOrder: 'desc',
    },
    goBackText: 'Back to search results',
  },
};

export const productDetailsConfig = mergeModuleConfigs(defaultConfig, orgConfig);
