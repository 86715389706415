import { postUIComponentMetadata } from 'core/actions/member';
import { EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED } from '../constants';
import {
  EMAIL_OPT_IN_MODAL_SUBSCRIBE_VIEW,
} from 'core/modules/Modals/EmailOptInSubscribeModal/actions/types';

export const triggerEmailOptInModalSubscribeIsViewed = () => ({
  type: EMAIL_OPT_IN_MODAL_SUBSCRIBE_VIEW,
});

export const postEmailOptInUserChoice = (userOptInChoice = EMAIL_OPT_IN_MODAL_USER_CHOICE_UNDECIDED) => {
  const metadata = [{
    name: 'userChoice',
    value: userOptInChoice,
  }];

  return postUIComponentMetadata('email_popup', metadata);
};
