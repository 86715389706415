import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { favoritesLanding as orgConfig } from 'org/configs/modules.config';
import clientConfig from 'org/configs/org.config';

import {
  FAVORITES_LANDING_MERCHANTS_ADD_GET_SUCCESS,
  FAVORITES_LANDING_MERCHANTS_ADD_GET_FAILURE,
} from '../actions/types';

const { earnType } = clientConfig;

const defaultConfig = {
  defaults: {
    favoritedMerchants: {
      api: {
        params: {},
        options: {
          success: FAVORITES_LANDING_MERCHANTS_ADD_GET_SUCCESS,
          failure: FAVORITES_LANDING_MERCHANTS_ADD_GET_FAILURE,
          cache: { isEnabled: true, isPublishedData: true },
        },
      },
      rebateOptions: {
        hideRebatePrefix: false,
        hideElevationCurrency: true,
        additionalRebatePrefix: 'Shop & ',
        prefix: 'earn',
        tiered: {
          hideElevationCurrency: true,
          additionalRebatePrefix: 'Shop & ',
        },
      },
      iconClassName: 'fa fa-heart',
    },
    favoritesRecommendation: {
      maxSeedMerchants: 10,
      carousel: {
        breakpoints: {
          l: {
            hasDots: false,
            hasArrows: true,
          },
        },
      },
    },
    favoritesHiw: {
      hiwItems: [
        {
          customClassName: 'mn_hiwItemFirst',
          title: 'Favorite a single store',
          getContent: ({ storeCount }) =>
            `Explore over ${storeCount} online stores and click the heart to add your favorites.`,
        },
        {
          customClassName: 'mn_hiwItemSecond',
          title: 'Favorite a bunch of stores',
          getContent: () => 'Add bundles of stores to your favorites with just one click.',
        },
        {
          customClassName: 'mn_hiwItemThird',
          title: 'Get notifications',
          getContent: () => `We'll let you know when your favorite stores are offering extra ${earnType}.`,
        },
      ],
    },
    favoritesStoreBundles: {
      getTitle: (isMobileMQ) => (isMobileMQ ? 'Pick your favorite stores' : 'Favorite bundles'),
      getSubtitle: (isMobileMQ) => (isMobileMQ ? '' : 'Choose your favorite stores by category'),
    },
    favoritesSearch: {
      placeholder: 'Search stores',
      buttonText: 'Add',
      iconClassName: 'fa fa-heart',
    },
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
