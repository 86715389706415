import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { selectUserIsIdentified } from 'core/selectors/user';
import { fetchInstoreOffers } from 'core/actions/ocapi';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';

import { triggerHybridStoresOfferOverlayIsViewed } from './actions';
import Modal from 'core/modules/Modal/Modal';
import HybridStoresOffer from './components/HybridStoresOffer';

import {
  selectHybridStoresOfferModalConfig, selectHybridStoresOfferModalIsLoading,
  selectHybridStoresOfferModalIsLoaded, selectHybridStoresOfferModalPlacements,
} from './selectors';

function HybridStoresOfferModal({ modal }) {
  const customClass = 'mn_hybridStoresOfferModal';

  const config = useSelector(selectHybridStoresOfferModalConfig);
  const isLoading = useSelector(selectHybridStoresOfferModalIsLoading);
  const isLoaded = useSelector(selectHybridStoresOfferModalIsLoaded);
  const inStoreOffers = useSelector(selectHybridStoresOfferModalPlacements);
  const userIsIdentified = useSelector(selectUserIsIdentified);
  const dispatch = useDispatch();

  const { merchant } = modal.params;

  useEffect(() => {
    if (!isLoaded || !isLoading) {
      const { api: { options, params } } = config;
      const { instoreData } = window;

      if (userIsIdentified && instoreData) {
        const { cardholderId } = instoreData;

        if (cardholderId) {
          params.applicable_cardholder_id = cardholderId;
        }
      }

      dispatch(fetchInstoreOffers(params, options));
    }
  }, []);

  useEffect(() => {
    if (merchant) {
      dispatch(triggerHybridStoresOfferOverlayIsViewed(merchant.name));
    }
  }, [merchant]);

  if (isEmpty(merchant)) return null;

  if (merchant) {
    return (
      <Modal className={customClass}>
        <HybridStoresOffer merchant={merchant} config={config} inStoreOffers={inStoreOffers} />
      </Modal>
    );
  }
}

export default (props) => <ComponentErrorBoundary><HybridStoresOfferModal {...props} /></ComponentErrorBoundary>;
