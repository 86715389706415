import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useModal } from 'core/modules/Modal/ModalProvider';
import { useGetBonusOffers } from 'core/modules/BonusOffers/hooks';
import { useCheckIsEligibleToShow } from './useCheckIsEligibleToShow';

export const useHandleBonusRollingModal = () => {
  const { openModal } = useModal();
  const isEligibleToShowModal = useCheckIsEligibleToShow();
  const { bonusRollingOfferToShow } = useGetBonusOffers();

  const handleBonusRollingModal = () => {
    const shouldShowOverlay = !isEmpty(bonusRollingOfferToShow) && isEligibleToShowModal;
    if (shouldShowOverlay) {
      openModal({ name: 'mt_bonusRolling' });
    }
  };

  useEffect(() => {
    handleBonusRollingModal();
  }, [bonusRollingOfferToShow, isEligibleToShowModal]);

  return handleBonusRollingModal;
};
