import { FIRST_FAVORITE_MODAL_VIEW } from '../actions/types';

export const firstFavoriteModalGaActionEvents = {
  [FIRST_FAVORITE_MODAL_VIEW]: () => (
    {
      category: 'First Favorite Default',
      action: 'Display',
      label: null,
      value: 1,
    }
  ),
};

export const firstFavoriteModalClickAndHoverEvents = [
  {
    selector: '.mn_firstFavoriteModal .mn_okButton',
    click: {
      category: 'First Favorite Default',
      action: 'Popup close',
    },
    label: (element) => `${element.innerText} click` || '',
    value: 1,
  },
  {
    selector: '.mn_firstFavoriteModal .mn_closeModal',
    click: {
      category: 'First Favorite Default',
      action: 'Popup close',
    },
    label: 'X click',
    value: 1,
  },
  {
    selector: '.mn_firstFavoriteModal .mn_myAccountLink',
    click: {
      category: 'First Favorite Default',
      action: 'Account click',
    },
    label: ' ',
    value: 1,
  },
];
